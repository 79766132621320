import styled from "styled-components";
import ProgressiveImage from "react-progressive-image";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Lazy } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "../styles/Swiper.css";

import { HighlightOffRounded } from "@material-ui/icons";
import Modal from "react-modal";
import { useState } from "react";

const Img = styled.img``;

const ModalContent = styled.div`
    width: 90vw;
    height: 80vh;
`;

const CloseBtn = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    z-index: 10;
    background: white;
    border-radius: 50%;
    text-align: center;
    transition: 0.3s;
    border: 1px solid white};
    & svg {
        fill: ${(props) => props.theme.lightblue};
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    &:hover {
        border: 1px solid ${(props) => props.theme.lightblue};
        transition: 0.3s;
    }
`;

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
        border: "2px solid #a2def9",
        padding: "2rem",
    },
};

SwiperCore.use([Pagination, Navigation, Lazy]);

export default function PhotoGallery({ photos }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);

    // MODAL & Contact form options & functions
    const openModal = (index) => {
        console.log(index);
        setSlideIndex(index);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    Modal.setAppElement("#root"); // Need to set this for screen readers to avoid errors in development

    return (
        <>
            {photos && (
                <Swiper
                    lazy={true}
                    initialSlide={1}
                    pagination={{
                        type: "fraction",
                    }}
                    navigation={true}
                    loop={true}
                    className="mySwiper"
                >
                    {photos.map((photo, i) => (
                        <SwiperSlide key={i} onClick={() => openModal(i)} className="swiper-property">
                            <ProgressiveImage src={photo} placeholder={"/images/loading-img-placeholder.jpg"}>
                                {(src, loading) => (
                                    <Img
                                        src={src}
                                        style={{
                                            filter: loading ? "blur(4px)" : "blur(0)",
                                            transition: ".3s",
                                        }}
                                    />
                                )}
                            </ProgressiveImage>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={modalStyles} closeTimeoutMS={300}>
                <ModalContent>
                    <CloseBtn onClick={closeModal}>
                        <HighlightOffRounded />
                    </CloseBtn>
                    {photos && (
                        <Swiper
                            lazy={true}
                            initialSlide={slideIndex}
                            pagination={{
                                type: "fraction",
                            }}
                            navigation={true}
                            loop={true}
                            className="swiper-property-modal"
                        >
                            {photos.map((photo, i) => (
                                <SwiperSlide key={i}>
                                    <ProgressiveImage
                                        src={photo}
                                        placeholder={"/images/loading-img-placeholder.jpg"}
                                    >
                                        {(src, loading) => (
                                            <Img
                                                src={src}
                                                style={{
                                                    filter: loading ? "blur(4px)" : "blur(0)",
                                                    transition: ".3s",
                                                }}
                                            />
                                        )}
                                    </ProgressiveImage>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
