import { LaunchRounded, PictureAsPdf, AddCircle, Create, AccountCircle } from "@material-ui/icons";

export const PublicNav = [
	{
		title: "Services",
		link: "/",
		submenu: [
			{
				title: "Lettings",
				link: "/brochures/Property-People-Lettings-Brochure.pdf",
				icon: <PictureAsPdf />,
				target: "_blank",
			},
			{
				title: "Sales",
				link: "/brochures/Property-People-Sales-Brochure.pdf",
				icon: <PictureAsPdf />,
				target: "_blank",
			},
			{
				title: "Offers",
				link: "/offers/PropertyPeople_Flyer_V7.pdf",
				icon: <PictureAsPdf />,
				target: "_blank",
			},
			{
				title: "Refurbishment",
				link: "https://property-people.net",
				icon: <LaunchRounded />,
				target: "_blank",
				external: true,
			},
			{
				title: "Cleaning",
				link: "https://swcarpetcleaning.co.uk",
				icon: <LaunchRounded />,
				target: "_blank",
				external: true,
			},
		],
	},
	{ title: "Price List", link: "/pricelist" },
	{ title: "About Us", link: "/about-us" },
	{ title: "Meet The Team", link: "/meet-the-team" },
	{ title: "Blog", link: "/blog" },
	{ title: "Testimonials", link: "/testimonials" },
	{ title: "Contact", link: "/contact" },
];

export const FooterNav = [
	{
		title: "Services",
		link: "/",
		target: "_self",
		submenu: [
			{
				title: "Lettings",
				link: "/brochures/Property-People-Lettings-Brochure.pdf",
				icon: <PictureAsPdf />,
				target: "_blank",
			},
			{
				title: "Sales",
				link: "/brochures/Property-People-Sales-Brochure.pdf",
				icon: <PictureAsPdf />,
				target: "_blank",
			},
			{
				title: "Offers",
				link: "/offers/PropertyPeople_Flyer_V7.pdf",
				icon: <PictureAsPdf />,
				target: "_blank",
			},
			{
				title: "Refurbishment",
				link: "https://property-people.net",
				icon: <LaunchRounded />,
				target: "_blank",
				external: true,
			},
			{
				title: "Cleaning",
				link: "https://swcarpetcleaning.co.uk",
				icon: <LaunchRounded />,
				target: "_blank",
				external: true,
			},
			{
				title: "Price List",
				link: "/pricelist",
				target: "_self",
			},
		],
	},
	{
		title: "About Us",
		link: "/about-us",
		target: "_self",
		submenu: [
			{ title: "Blog", link: "/blog", target: "_self" },
			{ title: "Testimonials", link: "/testimonials", target: "_self" },
			{ title: "Meet the team", link: "/meet-the-team", target: "_self" },
			{ title: "Contact", link: "/contact", target: "_self" },
			{ title: "Privacy Policy", link: "/privacy-policy", target: "_self" },
			{
				title: "Complaints",
				link: "https://property-people.co.uk/legal/TPO_In_House_Complaints_Procedure.pdf",
				target: "_blank",
			},
			{
				title: "Client Money Protection Certificate",
				link: "https://property-people.co.uk/legal/Propertymark_CMP_Main_Scheme_Certificate.pdf",
				target: "_blank",
			},
			{
				title: "PropertyMark Membership Rules and Conduct",
				link: "https://www.propertymark.co.uk/professional-standards/rules.html",
				target: "_blank",
			},
		],
	},
];

export const AdminNav = [
	{ title: "Dashboard", link: "/dashboard" },
	{ title: "New Property", link: "/dashboard/create-property", icon: <AddCircle /> },
	{ title: "New Blog Article", link: "/dashboard/create-post", icon: <Create /> },
	{ title: "New Team Member", link: "/dashboard/create-team-member", icon: <AccountCircle /> },
];
