import { Link } from "react-router-dom";
import styled from "styled-components";

import { EditRounded, VisibilityOff, Visibility, Pageview, DeleteForever } from "@material-ui/icons";

const ControlsWrap = styled.div`
    position: relative;
`;

const EditRoundedBtn = styled(EditRounded)`
    color: ${(props) => props.theme.darkblue};
    border-radius: 5px;
    background: #ebf3ff;
    padding: 0.25rem;
    transition: 0.3s;
    border: 1px solid #ffffff00;
    &:hover {
        border: 1px solid ${(props) => props.theme.darkblue};
        transition: 0.3s;
    }
`;

const VisibilityBtn = styled(Visibility)`
    color: ${(props) => props.theme.green};
    border-radius: 5px;
    background: #deffda;
    padding: 0.25rem;
    transition: 0.3s;
    border: 1px solid #ffffff00;
    &:hover {
        border: 1px solid ${(props) => props.theme.green};
        transition: 0.3s;
    }
`;

const VisibilityOffBtn = styled(VisibilityOff)`
    color: ${(props) => props.theme.orange};
    border-radius: 5px;
    background: #fdefdb;
    padding: 0.25rem;
    transition: 0.3s;
    border: 1px solid #ffffff00;
    &:hover {
        border: 1px solid ${(props) => props.theme.orange};
        transition: 0.3s;
    }
`;

const PageviewBtn = styled(Pageview)`
    color: ${(props) => props.theme.darkblue};
    border-radius: 5px;
    background: #ebf3ff;
    padding: 0.25rem;
    transition: 0.3s;
    border: 1px solid #ffffff00;
    &:hover {
        border: 1px solid ${(props) => props.theme.darkblue};
        transition: 0.3s;
    }
`;

const DeleteBtn = styled(DeleteForever)`
    color: #c9c9c9;
    border-radius: 5px;
    background: #f8f8f8;
    padding: 0.25rem;
    transition: 0.3s;
    border: 1px solid #ffffff00;
    &:hover {
        border: 1px solid #c9c9c9;
        transition: 0.3s;
    }
`;

const VisibilityLink = styled.a`
    &:hover {
        &:after {
            content: "${(props) => (props.isPublished ? "Unpublish" : "Publish")}";
            position: absolute;
            margin: 0.65rem 0 0 0.5rem;
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: 500;
        }
    }
`;

const EditLink = styled(Link)`
    &:hover {
        &:after {
            content: "Edit";
            position: absolute;
            margin: 0.65rem 0 0 0.5rem;
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: 500;
        }
    }
`;

const ViewLink = styled(Link)`
    &:hover {
        &:after {
            content: "View";
            position: absolute;
            margin: 0.65rem 0 0 0.5rem;
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: 500;
        }
    }
`;

const DeleteLink = styled.a`
    &:hover {
        &:after {
            content: "delete";
            position: absolute;
            margin: 0.65rem 0 0 0.5rem;
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: 500;
        }
    }
`;

const editLinkStyle = {
    position: "absolute",
    right: ".5rem",
    cursor: "pointer",
    marginTop: "3.5rem",
};

const visibilityStyle = {
    position: "absolute",
    right: ".5rem",
    cursor: "pointer",
    marginTop: ".9rem",
};

const pageviewStyle = {
    position: "absolute",
    right: ".5rem",
    cursor: "pointer",
    marginTop: "6rem",
};

const deleteStyle = {
    position: "absolute",
    right: ".5rem",
    cursor: "pointer",
    marginTop: "8.5rem",
};

export default function Controls({ editLink, viewLink, handleVisibility, handleDelete, isPublished }) {
    return (
        <ControlsWrap>
            <EditLink to={editLink} style={editLinkStyle}>
                <EditRoundedBtn />
            </EditLink>
            <VisibilityLink style={visibilityStyle} onClick={handleVisibility} isPublished={isPublished}>
                {isPublished ? <VisibilityBtn /> : <VisibilityOffBtn />}
            </VisibilityLink>
            <ViewLink to={viewLink} style={pageviewStyle}>
                <PageviewBtn />
            </ViewLink>
            <DeleteLink style={deleteStyle} onClick={handleDelete} type="button">
                <DeleteBtn />
            </DeleteLink>
        </ControlsWrap>
    );
}
