import styled from "styled-components";
import { device } from "./Breakpoints";

// Desktop Menu
export const MenuList = styled.ul`
    display: flex;
    justify-content: flex-end;
    margin: 0;
    list-style: none;
    align-items: center;
    & a {
        color: white;
    }
    @media print {
        display: none;
    }
`;

export const MenuItem = styled.li`
    padding: 1rem 0.75rem;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    &:hover {
        background: ${(props) => (props.admin ? "#404040" : "#0f2544")};
    }
    & a {
        padding: 1rem 0;
        white-space: nowrap;
    }
    & svg {
        font-size: 1rem !important;
        margin-bottom: -0.3rem;
        margin-right: 0.25rem;
    }
`;

export const SubmenuList = styled.ul`
    position: absolute;
    margin: 1rem 0 0 -0.75rem;
    background: ${(props) => props.theme.lightblue};
    transition: 0.3s;
    z-index: 100;
    list-style: none;
    padding: 0;
`;

export const SubmenuItem = styled.li`
    display: flex;
    padding: 0;
    cursor: pointer;
    transition: 0.3s;
    border-left: 3px solid #ffffff00;
    &:hover {
        background: ${(props) => props.theme.darkblue};
        color: white;
        transition: 0.3s;
    }
    & a {
        padding: 0.6rem 0 0.6rem 0.6rem;
        min-width: 9rem;
        color: ${(props) => props.theme.darkblue}!important;
        transition: 0.3s;
    }
    & a:hover {
        color: white !important;
        transform: translate(4%, 0px);
        transition: 0.3s;
    }
    & svg {
        width: 0.8rem;
        position: absolute;
        margin: -0.3rem 0 0 0.4rem;
        opacity: 0.9;
    }
`;

// Burger Menu
export const BurgerNavWrap = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${(props) => props.theme.darkblue};
    transform: ${({ burgerOpen }) => (burgerOpen ? "translateX(0)" : "translateX(100%)")};
    height: 100vh;
    text-align: left;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 40;

    @media ${device.sm} {
        width: 100%;
    }

    a {
        font-size: 2rem;
        text-transform: titlecase;
        padding: 0.6rem 0;
        font-weight: 400;
        letter-spacing: 0.05rem;
        color: white;
        text-decoration: none;
        transition: color 0.3s linear;
        display: block;

        @media ${device.sm} {
            font-size: 1.5rem;
            text-align: center;
            text-align: right;
            padding-right: 4rem;
        }

        &:hover {
            color: #343078;
        }
        & svg {
            width: 1.1rem;
            margin-left: 0.35rem;
            margin-bottom: -0.25rem;
        }
    }
`;

export const BurgerBtn = styled.button`
    position: absolute;
    top: 0.7rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 100;

    &:focus {
        outline: none;
    }

    div {
        width: 1.5rem;
        height: 0.12rem;
        background: ${({ burgerOpen }) => (burgerOpen ? "white" : "#EFFFFA")};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${({ burgerOpen }) => (burgerOpen ? "rotate(45deg)" : "rotate(0)")};
        }

        :nth-child(2) {
            opacity: ${({ burgerOpen }) => (burgerOpen ? "0" : "1")};
            transform: ${({ burgerOpen }) => (burgerOpen ? "translateX(20px)" : "translateX(0)")};
        }

        :nth-child(3) {
            transform: ${({ burgerOpen }) => (burgerOpen ? "rotate(-45deg)" : "rotate(0)")};
        }
    }
`;
