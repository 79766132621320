import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import axios from "axios";
import Modal from "react-modal";
import { useDropzone } from "react-dropzone";
import Loader from "react-loader-spinner";

// Styles
import {
    SectionWrap,
    SectionTitle,
    FieldTitle,
    Summary,
    Input,
    Span,
    SubmitWrap,
} from "../styles/Form";
import { Grid } from "../styles/Layout";
import { Button } from "../styles/Button";

const PostForm = styled.form``;

const ModalMessage = styled.h2`
    text-align: center;
`;

const ModalContentsWrap = styled.div`
    padding-bottom: 2rem;
`;

const AdjInput = styled(Input)`
    font-weight: 300;
    font-size: 0.9rem;
`;

const ImgContainer = styled.div`
    position: relative;
`;

const ImgWrapper = styled.div`
    display: grid;
    height: 100%;
`;

const Img = styled.img`
    width: 100%;
    height: 13rem;
    object-fit: cover;
`;

const Droparea = styled.div`
    background: #f6f6f6;
    padding: 0.5rem;
    border: 1px dashed #c2c2c2;
    height: 1rem;
    font-size: 0.75rem;
    cursor: pointer;
`;

const LoaderSpinner = styled(Loader)`
    text-align: right;
    margin-top: -1.2rem;
`;

// Modal
const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
        border: "2px solid #a2def9",
        padding: "2rem",
    },
};

export default function CreateTeamMemberForm() {
    const [name, setName] = useState("");
    const [position, setPosition] = useState("");
    const [quote, setQuote] = useState("");
    const [info, setInfo] = useState("");
    const [photo, setPhoto] = useState("");

    // Upload images states
    const [loading, setLoading] = useState(false);

    // Modal states
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalError, setModalError] = useState(false);

    // Direct user to a link from modal
    const history = useHistory();

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // Image upload handling
    const uploadToS3 = async (passedFile) => {
        setLoading(true);

        // Get the presigned url from S3
        let presignedURL = "";

        try {
            const res = await axiosInstance.get("/s3url");
            presignedURL = res.data.uploadURL;
        } catch (error) {
            console.log(error);
        }

        // Upload the image to S3
        try {
            const res = await axios.put(presignedURL, passedFile);
            res && setLoading(false);
        } catch (error) {
            console.log(error);
        }

        // Send the image url to the server
        const imgUrl = presignedURL.split("?")[0];
        setPhoto(imgUrl);
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            // Do something with the uploaded files
            acceptedFiles.forEach((file) => {
                uploadToS3(file);
            });
            console.log(acceptedFiles[0]);
        },
        [uploadToS3]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    // Modal handling
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleModalButton = () => {
        modalError ? closeModal() : history.push("/dashboard");
    };

    Modal.setAppElement("#root"); // Need to set this for screen readers to avoid errors in development

    const handleSubmit = async (e) => {
        e.preventDefault();

        const TeamMember = {
            name,
            position,
            quote,
            info,
            image: photo,
        };

        try {
            await axiosInstance.post("/team", TeamMember);
            setModalError(false);
            setModalIsOpen(true);
            setModalMessage("New member was added");
        } catch (error) {
            setModalError(false);
            setModalIsOpen(true);
            setModalMessage("There was an error creating this member.");
            setModalError(true);
            console.log(error);
        }
    };

    return (
        <PostForm onSubmit={handleSubmit}>
            <Grid custom="3fr 1fr" gap="1rem">
                <SectionWrap>
                    <Grid lg={2} gap={"1rem"} style={{ alignItems: "end", marginTop: "-1rem" }}>
                        <div>
                            <SectionTitle>Staff's Info</SectionTitle>
                            <FieldTitle>
                                Name<Span>Must be unique</Span>
                            </FieldTitle>
                            <AdjInput big value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div>
                            <FieldTitle>Position</FieldTitle>
                            <AdjInput
                                big
                                value={position}
                                onChange={(e) => setPosition(e.target.value)}
                            />
                        </div>
                    </Grid>
                    <FieldTitle>Quote</FieldTitle>
                    <AdjInput value={quote} onChange={(e) => setQuote(e.target.value)} />
                    <FieldTitle>Description</FieldTitle>
                    <Summary value={info} onChange={(e) => setInfo(e.target.value)} />
                </SectionWrap>
                <SectionWrap>
                    <SectionTitle>Photo</SectionTitle>

                    <ImgWrapper>
                        <ImgContainer>
                            {photo && <Img src={photo} alt="img alt text" />}
                        </ImgContainer>
                        <div {...getRootProps()} style={{ alignSelf: "end" }}>
                            <input {...getInputProps()} />
                            <Droparea>
                                {isDragActive ? (
                                    <>Drop the photo here ...</>
                                ) : (
                                    <>Add or change photo</>
                                )}
                                {loading && (
                                    <LoaderSpinner
                                        type="Puff"
                                        color="#c2c2c2"
                                        height={30}
                                        width={30}
                                    />
                                )}
                            </Droparea>
                        </div>
                    </ImgWrapper>
                </SectionWrap>
            </Grid>
            <SubmitWrap>
                <Grid lg={2} gap="1rem">
                    <Button bright type="submit" onClick={(e) => e.preventDefault}>
                        Publish
                    </Button>
                    <Button discard type="button" onClick={(e) => history.push("/dashboard")}>
                        Discard
                    </Button>
                </Grid>
            </SubmitWrap>
            {/* Modal after form submission or discard */}
            <Modal isOpen={modalIsOpen} style={modalStyles} closeTimeoutMS={300}>
                <ModalContentsWrap>
                    <ModalMessage>{modalMessage}</ModalMessage>
                    <Button primary onClick={handleModalButton} style={{ margin: "0 auto" }}>
                        {modalError ? "Close" : "Back to dashboard"}
                    </Button>
                </ModalContentsWrap>
            </Modal>
        </PostForm>
    );
}
