export const mapStyles = [
    {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#444444",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry.fill",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.province",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.locality",
        elementType: "labels",
        stylers: [
            {
                hue: "#ffe500",
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [
            {
                color: "#f2f2f2",
            },
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural",
        elementType: "all",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural.landcover",
        elementType: "all",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "all",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "geometry",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "geometry.fill",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "geometry.stroke",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "labels",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "labels.text.fill",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "labels.text.stroke",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural.terrain",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi.attraction",
        elementType: "all",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi.business",
        elementType: "all",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi.place_of_worship",
        elementType: "all",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi.school",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [
            {
                saturation: -100,
            },
            {
                lightness: 45,
            },
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit.station",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "transit.station.airport",
        elementType: "all",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [
            {
                color: "#A2DEF9",
            },
            {
                visibility: "on",
            },
        ],
    },
];
