import React from "react";
import ReactDOM from "react-dom";
// import "./reset.min.css";
import "./index.css";
import App from "./App";
import { ContextProvider } from "./context/Context";
import { ThemeProvider } from "styled-components";

const main = {
    darkblue: "#14315B",
    lightblue: "#A2DEF9",
    black: "#121212",
    green: "#6CC760",
    star: "#FABC10",
    grey: "#808080",
    lightgrey: "#e8e8e8",
    violet: "#53145b",
    orange: "#dfa03e",
    red: "#df563e",
    darkgradient: "linear-gradient(180deg, rgba(20,49,91,1) 0%, rgba(12,32,59,1) 100%)",
};

ReactDOM.render(
    <ContextProvider>
        <ThemeProvider theme={main}>
            <App />
        </ThemeProvider>
    </ContextProvider>,
    document.getElementById("root")
);
