import { useState, useEffect, useContext } from "react";
import { Context } from "../../context/Context";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import { size } from "../styles/Breakpoints";
import Controls from "./Controls";

import { ReactComponent as NationalRail } from "../../assets/img/transport-rail.svg";
import { ReactComponent as Underground } from "../../assets/img/transport-underground.svg";
import { ReactComponent as Overground } from "../../assets/img/transport-overground.svg";
import { ReactComponent as Tramlink } from "../../assets/img/transport-tramlink.svg";
import { ReactComponent as DLR } from "../../assets/img/transport-dlr.svg";
import { ReactComponent as Bus } from "../../assets/img/transport-bus.svg";
import { ReactComponent as River } from "../../assets/img/transport-river.svg";
import NoPhoto from "../../assets/img/no-images.jpg";

const PropertyWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    padding: 1rem 1rem 1rem 0;
    width: ${(props) => props.admin && "95%"};
    &:hover {
        transition: 0.3s;
    }
    @media screen and (max-width: ${size.sm}px) {
        display: block;
        padding: 0 0 1rem 0;
        margin: 1rem;
        box-shadow: 0px 0px 4px 3px #e6e5e5;
    }
`;

const Img = styled.div`
    height: 100%;
    width: 100%;
    background: url(${(props) => props.bg});
    background-size: cover;
    background-position: center;
    height: 11rem;
`;

const Status = styled.div`
    background: grey;
    background: ${(props) => props.status === "Available" && props.theme.green};
    background: ${(props) => props.status === "Under Offer" && props.theme.darkblue};
    background: ${(props) => props.status === "Sold" && "grey"};
    padding: 0.25rem 1rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: -1.6rem;
`;

const ImgWrap = styled.div``;

const InfoWrap = styled.div`
    padding: 0 0 0 2rem;
`;

const Title = styled.h3`
    margin: 0;
    text-transform: capitalize;
    @media screen and (max-width: ${size.sm}px) {
        margin-top: 1rem;
    }
`;

const Bedrooms = styled.div`
    padding: 0.5rem 0;
    font-size: 1.17rem;
`;

const Summary = styled.p`
    font-size: 0.8rem;
    margin: 0 0 0.5rem 0;
`;

const Price = styled.span`
    font-weight: 500;
    float: right;
    @media screen and (max-width: ${size.sm}px) {
        display: block;
        float: none;
        color: ${(props) => props.theme.darkblue};
        margin-top: 0.5rem;
    }
`;

const Seperator = styled.hr`
    border: 1px solid #f5f5f5;
    @media screen and (max-width: ${size.sm}px) {
        display: none;
    }
`;

const Feature = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.65rem;
    padding: 0.25rem 0.5rem 0.25rem 0rem;
    margin: 0.25rem 0.25rem 0.25rem 0rem;
    font-weight: 400;
    & svg {
        width: 1rem;
    }
`;

const FeatureText = styled.div`
    margin-left: 0.5rem;
`;

const FeatureWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Timestamp = styled.div`
    display: inline-block;
    margin-right: 0.5rem;
    font-size: 0.8rem;
    font-weight: 400;
    color: ${(props) => props.theme.darkblue};
`;

// Configure axios proxy
const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Format display address for admin view
const formatAddress = (address) => {
    let formattedAddress = address.replace(", UK", "").replace("London", "");
    return formattedAddress;
};

export default function ListProperty({ property, published, admin }) {
    const { user } = useContext(Context);
    const [propertyPrice, setPropertyPrice] = useState("");
    const [isPublished, setIsPublished] = useState(property.published);
    const [deleted, setDeleted] = useState(false);

    useEffect(() => {
        setPropertyPrice(property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }, [property.price]);

    const handlePropertyPublish = async () => {
        try {
            await axiosInstance.put(`/properties/${property._id}`, {
                username: user.username,
                published: !isPublished,
            });
            setIsPublished(!isPublished);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePropertyDelete = async () => {
        try {
            await axiosInstance.delete(`/properties/${property._id}`, {
                username: user.username,
            });
            setDeleted(true);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {/* Public property list in homepage */}
            {published && (
                <>
                    <PropertyWrapper>
                        <ImgWrap>
                            <Link to={`/property/${property._id}`}>
                                {property.photos[0] ? (
                                    <Img bg={property.photos[0]} />
                                ) : (
                                    <Img bg={NoPhoto} />
                                )}
                                <Status status={property.status}>{property.status}</Status>
                            </Link>
                        </ImgWrap>
                        <InfoWrap>
                            <Link to={`/property/${property._id}`}>
                                <Title>
                                    <span>
                                        {property.displayAddress}, {property.location}
                                    </span>
                                    <Price>
                                        £{propertyPrice}
                                        {property.category === "Lettings" && property.priceType}
                                    </Price>
                                </Title>
                            </Link>

                            <Bedrooms>
                                {property.bedrooms} Bedroom {property.type}
                            </Bedrooms>
                            <Summary>{property.summary}</Summary>
                            <FeatureWrap>
                                {property.features.map((feature, i) => (
                                    <div key={i}>
                                        {feature.icon && (
                                            <Feature>
                                                {feature.icon === "Overground" && <Overground />}
                                                {feature.icon === "Underground" && <Underground />}
                                                {feature.icon === "NationalRail" && (
                                                    <NationalRail />
                                                )}
                                                {feature.icon === "Bus" && <Bus />}
                                                {feature.icon === "Tramlink" && <Tramlink />}
                                                {feature.icon === "River" && <River />}
                                                {feature.icon === "DLR" && <DLR />}
                                                <FeatureText>{feature.text}</FeatureText>
                                            </Feature>
                                        )}
                                    </div>
                                ))}
                            </FeatureWrap>
                        </InfoWrap>
                    </PropertyWrapper>
                    <Seperator />
                </>
            )}

            {/* All properties list in admin dashboard */}
            {admin && !deleted && (
                <>
                    <Controls
                        handleVisibility={handlePropertyPublish}
                        handleDelete={handlePropertyDelete}
                        isPublished={isPublished}
                        editLink={`/dashboard/edit-property/${property._id}`}
                        viewLink={`/property/${property._id}`}
                    />
                    <PropertyWrapper admin>
                        <ImgWrap>
                            {property.photos[0] ? (
                                <Img bg={property.photos[0]} />
                            ) : (
                                <Img bg={NoPhoto} />
                            )}
                            <Status status={property.status}>{property.status}</Status>
                        </ImgWrap>
                        <InfoWrap>
                            <Title>
                                {formatAddress(property.address)}
                                <Price>
                                    £{propertyPrice}
                                    {property.category === "Lettings" && property.priceType}
                                </Price>
                            </Title>
                            <Bedrooms>
                                {property.bedrooms} Bedroom {property.type}
                            </Bedrooms>
                            <Summary>{property.summary}</Summary>
                            <FeatureWrap>
                                {property.features.map((feature, i) => (
                                    <div key={i}>
                                        {feature.icon && (
                                            <Feature>
                                                {feature.icon === "Overground" && <Overground />}
                                                {feature.icon === "Underground" && <Underground />}
                                                {feature.icon === "NationalRail" && (
                                                    <NationalRail />
                                                )}
                                                {feature.icon === "Bus" && <Bus />}
                                                {feature.icon === "Tramlink" && <Tramlink />}
                                                {feature.icon === "River" && <River />}
                                                {feature.icon === "DLR" && <DLR />}
                                                <FeatureText>{feature.text}</FeatureText>
                                            </Feature>
                                        )}
                                    </div>
                                ))}
                            </FeatureWrap>
                            <Timestamp>
                                Created at:
                                {new Date(property.createdAt).toUTCString().slice(0, -7)}
                            </Timestamp>
                            <Timestamp>
                                Last updated:
                                {new Date(property.updatedAt).toUTCString().slice(0, -7)}
                            </Timestamp>
                            <p>{property._id}</p>
                        </InfoWrap>
                    </PropertyWrapper>
                    <Seperator />
                </>
            )}
        </>
    );
}
