import { useLayoutEffect } from "react";
import styled from "styled-components";
import Footer from "../../components/fragments/Footer";
import NavBar from "../../components/fragments/NavBar";
import HeroBanner from "../../components/fragments/HeroBanner";
import { Container } from "../../components/styles/Layout";

import { CheckCircle, GetApp } from "@material-ui/icons";

const Wrapper = styled.section`
	margin-bottom: 4rem;
	margin-top: 2rem;
`;
const BigTitle = styled.h2`
	position: absolute;
	margin-bottom: 1rem;
	margin-top: 0.2rem;
	z-index: 1;
`;

const Table = styled.div`
	display: grid;
	grid-template-columns: ${(props) => props.sales && "1.5fr 1fr 1fr 1fr"};
	grid-template-columns: ${(props) => props.lettings && "1.5fr 1fr 1fr 1fr 1fr"};
	font-size: 0.8rem;
	font-weight: 400;
`;

const TableTitle = styled.div`
	background: ${(props) => props.grey && "#e8e8e8"};
	padding: 0.5rem 0.75rem;
	border: 1px solid white;
	position: relative;
`;
const TableDesc = styled.div`
	/* background: ${(props) => props.green && props.theme.green}; */
	background: ${(props) => props.dark && props.theme.darkblue};
	background: ${(props) => props.light && "#38476F"};
	background: ${(props) => props.grey && "#e8e8e8"};
	color: ${(props) => props.theme.black};
	color: ${(props) => (props.dark || props.light) && "white"};
	text-align: center;
	padding: 0.5rem 0.75rem;
	border: 1px solid white;
`;

const Check = styled(CheckCircle)`
	color: ${(props) => props.theme.green};
	padding: 0;
	margin: -17px auto -9px auto;
`;

const Info = styled.div`
	font-size: 0.8rem;
	margin: 1rem 0.9rem;
	font-style: italic;
`;

const DownloadPDF = styled.div`
	margin: 1rem 0 3rem 0;
	& a {
		font-weight: 500;
		text-transform: uppercase;
		font-size: 0.9rem;
		color: ${(props) => props.theme.darkblue};
		transition: 0.3s;
	}
	& a:hover {
		color: ${(props) => props.theme.green};
		transition: 0.3s;
	}
`;

const DownloadPDFIcon = styled(GetApp)`
	margin: 0 0.5rem -0.4rem 0;
`;

export default function Pricelist() {
	useLayoutEffect(() => {
		document.getElementById("root").scrollTo(0, 0);
	}, []);

	return (
		<>
			<NavBar location="public" pageTitle="Price List" />
			<HeroBanner title="Price List" background="/images/pp-outside-dark.jpg" showRating={false} />
			<Wrapper>
				<Container>
					<Table lettings>
						<TableTitle>
							<BigTitle>Lettings Fees</BigTitle>
						</TableTitle>
						<TableDesc dark>LET ONLY</TableDesc>
						<TableDesc light>LET & RENT COLLECTION</TableDesc>
						<TableDesc dark>LETTING & MANAGEMENT</TableDesc>
						<TableDesc light>360 Management</TableDesc>
						<TableTitle />
						<TableDesc dark>8.4%</TableDesc>
						<TableDesc light>10.8%</TableDesc>
						<TableDesc dark>13.2%</TableDesc>
						<TableDesc light>18%</TableDesc>

						<TableTitle grey>Online Marketing</TableTitle>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>

						<TableTitle>Erect To Let board</TableTitle>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>

						<TableTitle grey>Professional Photos</TableTitle>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>

						<TableTitle>Floor Plan</TableTitle>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>

						<TableTitle grey>Accompanied Viewings</TableTitle>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>

						<TableTitle>Monthly/Annual Statements</TableTitle>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>

						<TableTitle grey>Transfer Utilities</TableTitle>
						<TableDesc grey>£78</TableDesc>
						<TableDesc grey>£78</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>

						<TableTitle>Tenant Referencing & Checks</TableTitle>
						<TableDesc>£60/Tenant</TableDesc>
						<TableDesc>£60/Tenant</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>

						<TableTitle grey>Tenancy Agreement & E-Signatures</TableTitle>
						<TableDesc grey>£180</TableDesc>
						<TableDesc grey>£180</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>

						<TableTitle>Check Smoke Alarms</TableTitle>
						<TableDesc>£78</TableDesc>
						<TableDesc>£78</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>

						<TableTitle grey>Bi-Annual Inspections</TableTitle>
						<TableDesc grey>£94.80</TableDesc>
						<TableDesc grey>£94.80</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>

						<TableTitle>Energy Performance Certificate</TableTitle>
						<TableDesc>£94.80</TableDesc>
						<TableDesc>£94.80</TableDesc>
						<TableDesc>£94.80</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>

						<TableTitle grey>Annual Gas Safety Check</TableTitle>
						<TableDesc grey>£94.80</TableDesc>
						<TableDesc grey>£94.80</TableDesc>
						<TableDesc grey>£94.80</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>

						<TableTitle>Annual Boiler Service</TableTitle>
						<TableDesc>£94.80</TableDesc>
						<TableDesc>£94.80</TableDesc>
						<TableDesc>£94.80</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>

						<TableTitle grey>Inventory & Check-In</TableTitle>
						<TableDesc grey>from £234</TableDesc>
						<TableDesc grey>from £234</TableDesc>
						<TableDesc grey>from £234</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>

						<TableTitle>End of Tenancy Checkout</TableTitle>
						<TableDesc>£150</TableDesc>
						<TableDesc>£150</TableDesc>
						<TableDesc>£150</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>

						<TableTitle grey>Deposit Registration (DPS)</TableTitle>
						<TableDesc grey>£78</TableDesc>
						<TableDesc grey>£60</TableDesc>
						<TableDesc grey>£30</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>

						<TableTitle>Deposit Dispute Management</TableTitle>
						<TableDesc>£420</TableDesc>
						<TableDesc>£300</TableDesc>
						<TableDesc>£180</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>

						<TableTitle grey>Additional Visits</TableTitle>
						<TableDesc grey>£78</TableDesc>
						<TableDesc grey>£78</TableDesc>
						<TableDesc grey>£36</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>

						<TableTitle>Serving Section 21 Notices</TableTitle>
						<TableDesc>£78</TableDesc>
						<TableDesc>£78</TableDesc>
						<TableDesc>£78</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
					</Table>

					<Info>Fees displayed include 20% VAT applicable</Info>

					<DownloadPDF>
						<a href="/brochures/Property-People-Lettings-Brochure.pdf" target="_blank">
							<DownloadPDFIcon />
							Download our Lettings brochure
						</a>
					</DownloadPDF>

					<DownloadPDF>
						<a href="/legal/Tenant_fees_Assured Shorthold_Tenancies_V3.4.pdf" target="_blank">
							<DownloadPDFIcon />
							Tenant Fees
						</a>
					</DownloadPDF>

					<Table sales>
						<TableTitle>
							<BigTitle>Sales Fees</BigTitle>
						</TableTitle>
						<TableDesc dark>FLAT FEE SALE SERVICE</TableDesc>
						<TableDesc light>STANDARD SALE SERVICE</TableDesc>
						<TableDesc dark>360 SALE SERVICE</TableDesc>
						<TableTitle />
						<TableDesc dark>£1200 one off Payment</TableDesc>
						<TableDesc light>1.2% of final selling value</TableDesc>
						<TableDesc dark>2.4% of final selling value</TableDesc>

						<TableTitle grey>No Obligation Free Property Valuation</TableTitle>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableTitle>Online Marketing Rightmove, Zoopla, Onthemarket</TableTitle>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableTitle grey>Erect For Sale board</TableTitle>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableTitle>Professional Photography</TableTitle>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableTitle grey>2D Floor Plan</TableTitle>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableTitle>Registering Buyer enquiries</TableTitle>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableTitle grey>Arranging Viewing Appointments</TableTitle>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableTitle>Standard Property Brochure</TableTitle>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableTitle grey>Confirming offers and Negotiating</TableTitle>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableTitle>Sales Progression</TableTitle>
						<TableDesc>£600</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableTitle grey>Accompanied Viewings Monday to Saturday</TableTitle>
						<TableDesc grey>from £30</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableTitle>Accompanied Viewings Sundays & Bank Holidays</TableTitle>
						<TableDesc>from £60</TableDesc>
						<TableDesc>from £60</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableTitle grey>Energy Performance Certificate</TableTitle>
						<TableDesc grey>from £94.80</TableDesc>
						<TableDesc grey>from £94.80</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableTitle>Premium Listing Upgrade</TableTitle>
						<TableDesc>from £118.80</TableDesc>
						<TableDesc>from £118.80</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableTitle grey>Featured Listings Upgrade</TableTitle>
						<TableDesc grey>from £234</TableDesc>
						<TableDesc grey>from £234</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableTitle>Premium Professional Property Borchure</TableTitle>
						<TableDesc>from £354</TableDesc>
						<TableDesc>from £354</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableTitle grey>360 Virtual Walkthrough</TableTitle>
						<TableDesc grey>from £354</TableDesc>
						<TableDesc grey>from £354</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableTitle>3D Floor Plan</TableTitle>
						<TableDesc>from £94.80</TableDesc>
						<TableDesc>from £94.80</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableTitle grey>
							Virtual Staging (Virtual furniture) for Unfurnished Properties
						</TableTitle>
						<TableDesc grey>from £94.80</TableDesc>
						<TableDesc grey>from £94.80</TableDesc>
						<TableDesc grey>
							<Check />
						</TableDesc>
						<TableTitle>Google and Facebook advertising</TableTitle>
						<TableDesc>from £354</TableDesc>
						<TableDesc>from £354</TableDesc>
						<TableDesc>
							<Check />
						</TableDesc>
						<TableTitle grey>Fees Payable</TableTitle>
						<TableDesc grey>Upfront - one off payment</TableDesc>
						<TableDesc grey>On Completion - No Sale No Fee</TableDesc>
						<TableDesc grey>On Completion - No Sale No Fee</TableDesc>
					</Table>

					<Info>Fees displayed include 20% VAT applicable</Info>

					<DownloadPDF>
						<a href="/brochures/Property-People-Sales-Brochure.pdf" target="_blank">
							<DownloadPDFIcon />
							Download our Sales brochure
						</a>
					</DownloadPDF>
				</Container>
			</Wrapper>
			<Footer />
		</>
	);
}
