import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import Reducer from "./Reducer";

const INITIAL_STATE = {
    user: JSON.parse(localStorage.getItem("user")),
    isFetching: false,
    error: false,
};

export const Context = createContext(INITIAL_STATE);

export const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(state.user));

        //     axios
        //         .get(
        //             `https://property-people-reviews.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJS2kSTVAPdkgRBBr4UVxT16c&key=AIzaSyApGhg2iSG11Ra01IokZvM6-7UBPFofZ3I`
        //         )
        //         .then((res) => {
        //             console.log(res.data);
        //         });
    }, [state.user]);

    return (
        <Context.Provider
            value={{
                user: state.user,
                isFetching: state.isFetching,
                error: state.error,
                // reviews: "Rebyuu?",
                dispatch,
            }}
        >
            {children}
        </Context.Provider>
    );
};
