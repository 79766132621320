import { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import Loader from "react-loader-spinner";
import { LoaderWrapper } from "../../components/styles/Loader";

import NavBar from "../../components/fragments/NavBar";
import PropertyImageUpload from "../../components/fragments/PropertyImageUpload";
import CreatePropertyForm from "../../components/fragments/CreatePropertyForm";

import { Container } from "../../components/styles/Layout";

const PanelWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 1rem;
    box-sizing: inherit;
`;

const Title = styled.h1`
    text-align: center;
    margin: 2rem;
`;

export default function EditProperty() {
    // pass photos from Image upload component to the Form component
    const [photoArray, setPhotoArray] = useState([]);
    const [propertyToEdit, setPropertyToEdit] = useState(null);
    const [floorPlanImg, setFloorPlanImg] = useState("");

    const url = useLocation();
    const path = url.pathname.split("/")[3]; // Get the id of the property to edit

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    useEffect(() => {
        const getProperty = async () => {
            try {
                const res = await axiosInstance.get(`/properties/${path}`);
                setPropertyToEdit(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        getProperty();
    }, [path]);

    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar location="admin" />
            <Container>
                <Title>Edit Property</Title>
                {propertyToEdit ? (
                    <PanelWrapper>
                        <CreatePropertyForm
                            photoArray={photoArray}
                            propertyToEdit={propertyToEdit}
                            floorPlanImg={floorPlanImg}
                        />
                        <PropertyImageUpload
                            setPhotoArray={setPhotoArray}
                            propertyToEdit={propertyToEdit}
                            setFloorPlanImg={setFloorPlanImg}
                        />
                    </PanelWrapper>
                ) : (
                    <LoaderWrapper>
                        <Loader type="Puff" color="#A2DEF9" height={60} width={60} />
                    </LoaderWrapper>
                )}
            </Container>
        </>
    );
}
