export const Locations = [
    "Any Location",
    "Balham",
    "Barnes",
    "Battersea",
    "Brixton",
    "Chelsea",
    "Clapham",
    "Clapham Common",
    "Clapham Junction",
    "Clapham North",
    "Clapham South",
    "Colliers Wood",
    "Earlsfield",
    "East Putney",
    "Feltham",
    "Fulham",
    "Isleworth",
    "Kennington",
    "Kensington",
    "Kingston",
    "Mitcham",
    "Mitcham / Tooting",
    "Morden",
    "New Malden",
    "Norbury",
    "Notting Hill",
    "Parsons Green",
    "Putney",
    // "Putney, London",
    "Raynes Park",
    "Richmond",
    "Richmond Ham",
    "Roehampton",
    "Roehampton/ Barnes",
    "Shad Thames",
    "Shephards Bush",
    "South Wimbledon",
    "Southfields",
    "Streatham",
    "Streatham Hill",
    "Surbiton",
    "Thornton Heath",
    "Tooting",
    "Tooting Broadway",
    "Vauxhall",
    "Wandsworth",
    "Wandsworth Common",
    "Wandsworth Town",
    "West Kensington",
    "Whitton",
    "Wimbledon",
    "Wimbledon Park",
    "Wimbledon Village",
    "Worcester Park",
    "Yiewsley",
    "Abbey Wood",
    "Acton",
    "Addington",
    "Addiscombe",
    "Albany Park",
    "Aldborough Hatch",
    "Aldgate",
    "Aldwych",
    "Alperton",
    "Anerley",
    "Angel",
    "Aperfield",
    "Archway",
    "Ardleigh Green",
    "Arkley",
    "Arnos Grove",
    "Bankside",
    "Barbican",
    "Barking",
    "Barkingside",
    "Barnehurst",
    "Barnes Cray",
    "Barnet Gate",
    "Barnet",
    "Barnsbury",
    "Bayswater",
    "Beckenham",
    "Beckton",
    "Becontree",
    "Becontree Heath",
    "Beddington",
    "Bedford Park",
    "Belgravia",
    "Bellingham",
    "Belmont",
    "Belsize Park",
    "Belvedere",
    "Bermondsey",
    "Berrylands",
    "Bethnal Green",
    "Bexley",
    "Bexleyheath",
    "Bickley",
    "Biggin Hill",
    "Blackfen",
    "Blackfriars",
    "Blackheath",
    "Blackheath Royal Standard",
    "Blackwall",
    "Blendon",
    "Bloomsbury",
    "Botany Bay",
    "Bounds Green",
    "Bow",
    "Bowes Park",
    "Brentford",
    "Brent Cross",
    "Brent Park",
    "Brimsdown",
    "Brockley",
    "Bromley",
    "Bromley-by-Bow",
    "Bromley Common",
    "Brompton",
    "Brondesbury",
    "Brunswick Park",
    "Bulls Cross",
    "Burnt Oak",
    "Burroughs",
    "Camberwell",
    "Cambridge Heath",
    "Camden Town",
    "Canary Wharf",
    "Cann Hall",
    "Canning Town",
    "Canonbury",
    "Carshalton",
    "Castelnau",
    "Castle Green",
    "Catford",
    "Chadwell Heath",
    "Chalk Farm",
    "Charing Cross",
    "Charlton",
    "Chase Cross",
    "Cheam",
    "Chelsfield",
    "Chessington",
    "Childs Hill",
    "Chinatown",
    "Chinbrook",
    "Chingford",
    "Chislehurst",
    "Chiswick",
    "Church End",
    "Clerkenwell",
    "Cockfosters",
    "Colindale",
    "Collier Row",
    "Colney Hatch",
    "Colyers",
    "Coney Hall",
    "Coombe",
    "Coulsdon",
    "Covent Garden",
    "Cowley",
    "Cranford",
    "Cranham",
    "Crayford",
    "Creekmouth",
    "Crews Hill",
    "Cricklewood",
    "Crofton Park",
    "Crook Log",
    "Crossness",
    "Crouch End",
    "Croydon",
    "Crystal Palace",
    "Cubitt Town",
    "Cudham",
    "Custom House",
    "Dagenham",
    "Dalston",
    "Dartford",
    "De Beauvoir Town",
    "Denmark Hill",
    "Deptford",
    "Derry Downs",
    "Dollis Hill",
    "Downe",
    "Downham",
    "Dulwich",
    "Ealingh",
    "Earls Court",
    "East Barnet",
    "East Bedfont",
    "East Dulwichs",
    "East Finchley",
    "East Ham",
    "East Sheen",
    "East Wickham",
    "Eastcote",
    "Eden Park",
    "Edgware",
    "Edmonton",
    "Eel Pie Island",
    "Elephant and Castle",
    "Elm Park",
    "Elmers End",
    "Elmstead",
    "Eltham",
    "Emerson Park",
    "Enfield Highway",
    "Enfield Lock",
    "Enfield Town",
    "Enfield Wash",
    "Erith",
    "Falconwood",
    "Farringdon",
    "Finchley",
    "Finsbury",
    "Finsbury Park",
    "Fitzrovia",
    "Foots Cray",
    "Forest Gate",
    "Forest Hill",
    "Forestdale",
    "Fortis Green",
    "Freezywater",
    "Friern Barnet",
    "Frognal",
    "Fulwell",
    "Gallows Corner",
    "Gants Hill",
    "Gidea Park",
    "Gipsy Hill",
    "Goddington",
    "Golders Green",
    "Goodmayes",
    "Gospel Oak",
    "Grahame Park",
    "Grange Park",
    "Greenford",
    "Greenwich",
    "Grove Park",
    "Gunnersbury",
    "Hackney",
    "Hackney Central",
    "Hackney Marshes",
    "Hackney Wick",
    "Hadley Wood",
    "Haggerston",
    "Hainault",
    "The Hale",
    "Ham",
    "Hammersmith",
    "Hampstead",
    "Hampstead Garden Suburb",
    "Hampton",
    "Hampton Hill",
    "Hampton Wick",
    "Hanwell",
    "Hanworth",
    "Harefield",
    "Harlesden",
    "Harlington",
    "Harmondsworth",
    "Harold Hill",
    "Harold Park",
    "Harold Wood",
    "Harringay",
    "Harrow",
    "Harrow on the Hill",
    "Harrow Weald",
    "Hatch End",
    "Hatton",
    "Havering-atte-Bower",
    "Hayes",
    "Hayes",
    "Hazelwood",
    "Hendon",
    "Herne Hill",
    "Heston",
    "Highams Park",
    "Highbury",
    "Highgate",
    "Hillingdon",
    "Hither Green",
    "Holborn",
    "Holland Park",
    "Holloway",
    "Homerton",
    "Honor Oak",
    "Hook",
    "Hornchurch",
    "Horn Park",
    "Hornsey",
    "Hounslow",
    "Hoxton",
    "The Hyde",
    "Ickenham",
    "Ilford",
    "Isle of Dogs",
    "Islington",
    "Kenley",
    "Kensal Green",
    "Kentish Town",
    "Kenton",
    "Keston",
    "Kew",
    "Kidbrooke",
    "Kilburn",
    "King's Cross",
    "Kingsbury",
    "Kingston Vale",
    "Kingston upon Thames",
    "Knightsbridge",
    "Ladywell",
    "Lambeth",
    "Lamorbey",
    "Lampton",
    "Lea Bridge",
    "Leamouth",
    "Leaves Green",
    "Lee",
    "Lessness Heath",
    "Lewisham",
    "Leyton",
    "Leytonstone",
    "Limehouse",
    "Lisson Grove",
    "Little Ilford",
    "Little Venice",
    "Locksbottom",
    "Longford",
    "Longlands",
    "Lower Clapton",
    "Lower Morden",
    "Loxford",
    "Maida Vale",
    "Malden Rushett",
    "Manor House",
    "Manor Park",
    "Marks Gate",
    "Maryland",
    "Marylebone",
    "Mayfair",
    "Maze Hill",
    "Merton Park",
    "Middle Park",
    "Mile End",
    "Mill Hill",
    "Millbank",
    "Millwall",
    "Monken Hadley",
    "Morden Park",
    "Mortlake",
    "Motspur Park",
    "Mottingham",
    "Muswell Hill",
    "Nag's Head",
    "Neasden",
    "New Addington",
    "New Barnet",
    "New Cross",
    "New Eltham",
    "New Southgate",
    "Newbury Park",
    "Newington",
    "Nine Elms",
    "Noak Hill",
    "Norbiton",
    "North Cray",
    "North End",
    "North Finchley",
    "North Harrow",
    "North Kensington",
    "North Ockendon",
    "North Sheen",
    "North Woolwich",
    "Northolt",
    "Northumberland Heath",
    "Northwood",
    "Norwood Green",
    "Nunhead",
    "Oakleigh Park",
    "Old Coulsdon",
    "Old Ford",
    "Old Malden",
    "Old Oak Common",
    "Orpington",
    "Osidge",
    "Osterley",
    "Oval",
    "Paddington",
    "Palmers Green",
    "Park Royal",
    "Peckham",
    "Penge",
    "Pentonville",
    "Perivale",
    "Petersham",
    "Petts Wood",
    "Pimlico",
    "Pinner",
    "Plaistow",
    "Plaistow",
    "Plumstead",
    "Ponders End",
    "Poplar",
    "Pratt's Bottom",
    "Preston",
    "Primrose Hill",
    "Purley",
    "Queen's Park",
    "Queensbury",
    "Rainham",
    "Ratcliff",
    "Rayners Lane",
    "Redbridge",
    "Riddlesdown",
    "Romford",
    "Rotherhithe",
    "Ruislip",
    "Rush Green",
    "Ruxley",
    "Sanderstead",
    "Sands End",
    "Selhurst",
    "Selsdon",
    "Seven Kings",
    "Seven Sisters",
    "Shacklewell",
    "Shadwell",
    "Shirley",
    "Shooter's Hill",
    "Shoreditch",
    "Sidcup",
    "Silvertown",
    "Sipson",
    "Slade Green",
    "Snaresbrook",
    "Soho",
    "Somerstown",
    "South Croydon",
    "South Hackney",
    "South Harrow",
    "South Hornchurch",
    "South Kensington",
    "South Norwood",
    "South Ruislip",
    "South Woodford",
    "South Tottenham",
    "Southend",
    "Southall",
    "Southborough",
    "Southgate",
    "Spitalfields",
    "St Helier",
    "St James's",
    "St Margarets",
    "St Giles",
    "St Johns",
    "St John's Wood",
    "St Luke's",
    "St Mary Cray",
    "St Pancras",
    "St Paul's Cray",
    "Stamford Hill",
    "Stanmore",
    "Stepney",
    "Stockwell",
    "Stoke Newington",
    "Stonebridge",
    "Stratford",
    "Strawberry Hill",
    "Stroud Green",
    "Sudbury",
    "Sundridge",
    "Surrey Quays",
    "Sutton",
    "Swiss Cottage",
    "Sydenham",
    "Sydenham Hill",
    "Teddington",
    "Temple",
    "Temple Fortune",
    "Thamesmead",
    "Tokyngton",
    "Tolworth",
    "Tooting Bec",
    "Tottenham",
    "Tottenham Green",
    "Tottenham Hale",
    "Totteridge",
    "Tower Hill",
    "Tufnell Park",
    "Tulse Hill",
    "Turnpike Lane",
    "Twickenham",
    "Upminster",
    "Upminster Bridge",
    "Upper Clapton",
    "Upper Holloway",
    "Upper Norwood",
    "Upper Ruxley",
    "Upper Walthamstow",
    "Upton",
    "Upton Park",
    "Uxbridge",
    "Waddon",
    "Wallington",
    "Walthamstow",
    "Walthamstow Village",
    "Walworth",
    "Wanstead",
    "Wapping",
    "Wealdstone",
    "Well Hall",
    "Welling",
    "Wembley",
    "Wembley Park",
    "Wennington",
    "West Brompton",
    "West Drayton",
    "West Ealing",
    "West Green",
    "West Hackney",
    "West Ham",
    "West Hampstead",
    "West Harrow",
    "West Heath",
    "West Hendon",
    "West Norwood",
    "West Wickham",
    "Westcombe Park",
    "Westminster",
    "Whetstone",
    "White City",
    "Whitechapel",
    "Widmore",
    "Willesden",
    "Winchmore Hill",
    "Wood Green",
    "Woodford",
    "Woodford Green",
    "Woodlands",
    "Woodside",
    "Woodside Park",
    "Woolwich",
    "Wormwood Scrubs",
    "Yeading",
];
