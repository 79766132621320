import styled from "styled-components";

export const RadioTransportWrap = styled.div`
    display: flex;
    position: relative;
`;

export const RadioTransportLabel = styled.label`
    display: grid;
    align-content: center;
    text-align: center;
    color: ${(props) => (props.rent ? "white" : props.theme.darkblue)};
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.075;
`;

export const RadioTransportInput = styled.input`
    &:checked + ${RadioTransportLabel} {
        color: white;
        opacity: 1;
    }
`;
