import styled from "styled-components";

export const SectionWrap = styled.section`
    border: 1px solid #d3d3d3;
    padding: 1rem;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
`;

export const SectionTitle = styled.h4`
    margin-top: -2.45rem;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.9rem;
    color: grey;
`;

export const FieldTitle = styled.div`
    font-size: 0.7rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`;

export const Input = styled.input`
    padding: 0.56rem 0.5rem;
    border-radius: 5px;
    font-size: ${(props) => (props.big ? "1rem" : "0.8rem")};
    border: 1px solid #dbdbdb;
    -webkit-appearance: none;
    box-sizing: border-box;
    width: 100%;
    &:hover {
        border-color: #b5b5b5;
    }
    &:focus {
        border-color: ${(props) => props.theme.lightblue};
        outline: ${(props) => props.theme.lightblue};
    }
`;

export const Summary = styled.textarea`
    width: 100%;
    font-size: 0.9rem;
    padding: 0.56rem 0.5rem;
    border-radius: 5px;
    resize: none;
    border: 1px solid #dbdbdb;
    -webkit-appearance: none;
    font-weight: 300;
    min-height: 5.5rem;
    &:hover {
        border-color: #b5b5b5;
    }
    &:focus {
        border-color: ${(props) => props.theme.lightblue};
        outline: ${(props) => props.theme.lightblue};
    }
`;

export const AdminMemo = styled.textarea`
    width: 100%;
    font-size: 0.8rem;
    padding: 0.56rem 0.5rem;
    border-radius: 5px;
    resize: none;
    border: none;
    background: #fffeeb;
    -webkit-appearance: none;
    font-weight: 400;
    min-height: 5.5rem;
    &:hover {
        border-color: #b5b5b5;
    }
    &:focus {
        border-color: ${(props) => props.theme.lightblue};
        outline: ${(props) => props.theme.lightblue};
    }
`;

export const Span = styled.span`
    font-weight: 200;
    margin-left: 0.5rem;
`;

export const SubmitWrap = styled.div`
    margin-top: 1rem;
    margin-bottom: 10rem;
    background: #f6f6f6;
    padding: 1.5rem;
`;
