import { useLayoutEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.nav`
    display: block;
    text-align: center;
    margin: 2rem auto;
    padding-bottom: 2rem;
`;
const List = styled.ul`
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
`;
const Page = styled.li`
    margin: 0.25rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid ${(props) => props.theme.lightblue};
    cursor: pointer;
    background: ${(props) => (props.current ? props.theme.lightblue : "none")};
`;

export default function PaginationDB({ pages, currentPage, setPageNumber }) {
    // useLayoutEffect(() => {
    //     document.getElementById("root").scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // }, [currentPage]);

    return (
        <Wrapper>
            <List>
                {pages.map((pageIndex) => (
                    <Page
                        key={pageIndex}
                        onClick={setPageNumber}
                        value={pageIndex}
                        current={pageIndex === currentPage}
                    >
                        {pageIndex + 1}
                    </Page>
                ))}
            </List>
        </Wrapper>
    );
}
