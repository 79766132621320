// Dependencies
import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import axios from "axios";
import PacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Dropdown } from "semantic-ui-react";
import Modal from "react-modal";
import { v4 as uuidv4 } from "uuid";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs"; //I'll need this in edit page

// Icons
import { ReactComponent as NoTransport } from "../../assets/img/transport-none.svg";
import { ReactComponent as NationalRail } from "../../assets/img/transport-rail.svg";
import { ReactComponent as Underground } from "../../assets/img/transport-underground.svg";
import { ReactComponent as Overground } from "../../assets/img/transport-overground.svg";
import { ReactComponent as Tramlink } from "../../assets/img/transport-tramlink.svg";
import { ReactComponent as DLR } from "../../assets/img/transport-dlr.svg";
import { ReactComponent as Bus } from "../../assets/img/transport-bus.svg";
import { ReactComponent as River } from "../../assets/img/transport-river.svg";

// Context
import { Context } from "../../context/Context";

// Styles
import { Grid } from "../styles/Layout";
import { RadioWrap, RadioLabel, RadioInput } from "../styles/RadioButtonB";
import {
    RadioTransportWrap,
    RadioTransportLabel,
    RadioTransportInput,
} from "../styles/RadioTransportButton";
import { DropdownSelect, DropdownWrap, ArrowDown, Option } from "../styles/Dropdown";
import { mapStyles } from "../../assets/other/mapStyles";
import "../styles/RichText.css";
import "../styles/semantic.min.css";
import { Button } from "../styles/Button";
import {
    SectionWrap,
    SectionTitle,
    FieldTitle,
    Summary,
    Input,
    SubmitWrap,
    AdminMemo,
    Span,
} from "../styles/Form";
import Loader from "react-loader-spinner";

import { Locations } from "../../data/Locations";

const PropertyForm = styled.form``;

const Row = styled.div`
    display: flex;
`;

const StyledDropdown = styled(Dropdown)`
    font-size: 0.8rem !important;
    font-weight: 400;
    width: 100%;
    & * {
        font-size: 0.8rem;
        font-weight: 400;
    }
`;

const GoogleMapWrapper = styled.div`
    margin-top: 1rem;
`;

const AutoCompleteWrap = styled.div`
    font-size: 0.8rem;
`;

const ModalMessage = styled.h2`
    text-align: center;
`;

const ModalContentsWrap = styled.div`
    padding-bottom: 2rem;
`;

const iconStyle = {
    padding: ".25rem",
    width: "1.5rem",
};

// Map styles
const mapContainerStyle = {
    width: "100%",
    height: "20rem",
};

// Modal
const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
        border: "2px solid #a2def9",
        padding: "2rem",
    },
};

// Dropdown search
const areaOptions = Locations.map((area, index) => ({
    key: index,
    text: area,
    value: area,
}));

export default function CreatePropertyForm({ photoArray, propertyToEdit, floorPlanImg }) {
    // Get current user
    const { user } = useContext(Context);

    // Form states
    const [category, setCategory] = useState("Lettings");
    const [status, setStatus] = useState("Available");
    const [type, setType] = useState("Flat");
    const [bedrooms, setBedrooms] = useState(2);
    const [bathrooms, setBathrooms] = useState(1);
    const [garden, setGarden] = useState("false");
    const [price, setPrice] = useState(0);
    const [priceType, setPriceType] = useState("");
    const [displayAddress, setDisplayAddress] = useState("");
    const [location, setLocation] = useState("");

    const [published, setPublished] = useState(true);
    const [summary, setSummary] = useState("");

    const [isEditMode, setIsEditMode] = useState(false);

    const [feature1icon, setFeature1Icon] = useState("noIcon");
    const [feature2icon, setFeature2Icon] = useState("noIcon");
    const [feature3icon, setFeature3Icon] = useState("noIcon");
    const [feature4icon, setFeature4Icon] = useState("noIcon");
    const [feature5icon, setFeature5Icon] = useState("noIcon");
    const [feature6icon, setFeature6Icon] = useState("noIcon");
    const [feature7icon, setFeature7Icon] = useState("noIcon");
    const [feature8icon, setFeature8Icon] = useState("noIcon");

    const [feature1text, setFeature1Text] = useState("");
    const [feature2text, setFeature2Text] = useState("");
    const [feature3text, setFeature3Text] = useState("");
    const [feature4text, setFeature4Text] = useState("");
    const [feature5text, setFeature5Text] = useState("");
    const [feature6text, setFeature6Text] = useState("");
    const [feature7text, setFeature7Text] = useState("");
    const [feature8text, setFeature8Text] = useState("");

    const [virtualTourLink, setVirtualTourLink] = useState("");
    const [adminMemo, setAdminMemo] = useState("");

    // +Autocomplete states
    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

    // Map states
    const [currentMapCenter, setCurrentMapCenter] = useState({ lat: 51.458697, lng: -0.2024652 });
    const [currentZoom, setCurrentZoom] = useState(12);

    // Modal state
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalError, setModalError] = useState(false);

    // Rich text editor state
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    // Connecting to database loading spinner
    const [isConnecting, setIsConnecting] = useState(false);

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // Direct user to a link from modal
    const history = useHistory();

    // Edit mode handling
    useEffect(() => {
        if (propertyToEdit) {
            // Set prefilled values
            setIsEditMode(true);
            setCategory(propertyToEdit.category);
            setStatus(propertyToEdit.status);
            setType(propertyToEdit.type);
            setBedrooms(propertyToEdit.bedrooms);
            setBathrooms(propertyToEdit.bathrooms);
            setGarden(propertyToEdit.garden);
            setPrice(propertyToEdit.price);
            setPriceType(propertyToEdit.priceType);
            setDisplayAddress(propertyToEdit.displayAddress);
            setLocation(propertyToEdit.location);
            setAddress(propertyToEdit.address);
            setCoordinates(propertyToEdit.coordinates);
            setSummary(propertyToEdit.summary);

            // Convert html prefill draft-js content
            const blocksFromHtml = htmlToDraft(propertyToEdit.description);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);

            // Features array
            setFeature1Icon(propertyToEdit.features[0].icon);
            setFeature2Icon(propertyToEdit.features[1].icon);
            setFeature3Icon(propertyToEdit.features[2].icon);
            setFeature4Icon(propertyToEdit.features[3].icon);
            setFeature5Icon(propertyToEdit.features[4].icon);
            setFeature6Icon(propertyToEdit.features[5].icon);
            setFeature7Icon(propertyToEdit.features[6].icon);
            setFeature8Icon(propertyToEdit.features[7].icon);

            setFeature1Text(propertyToEdit.features[0].text);
            setFeature2Text(propertyToEdit.features[1].text);
            setFeature3Text(propertyToEdit.features[2].text);
            setFeature4Text(propertyToEdit.features[3].text);
            setFeature5Text(propertyToEdit.features[4].text);
            setFeature6Text(propertyToEdit.features[5].text);
            setFeature7Text(propertyToEdit.features[6].text);
            setFeature8Text(propertyToEdit.features[7].text);

            setVirtualTourLink(propertyToEdit.virtualTourLink);
            setAdminMemo(propertyToEdit.adminMemo);
        }
    }, []);

    // Map options
    const options = {
        styles: mapStyles,
        disableDefaultUI: true,
        zoomControl: true,
        scrollwheel: false,
    };

    // Autocomplete options
    const searchOptions = {
        location: new window.google.maps.LatLng(51.458697, -0.2024652),
        radius: 10000, // in meters
    };

    const spinnerStyle = {
        display: "flex",
        justifyContent: "center",
    };

    // Autocomplete handling
    const handleSelectAddress = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setCoordinates(latLng);
        setAddress(value);
        setCurrentMapCenter(latLng);
        setCurrentZoom(15);
    };

    // Rich text editor handling
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    // Dropdown search handling
    const handleDropdownSelect = (event, { value }) => {
        setLocation(value);
    };

    const { value } = location;

    // Modal handling
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleModalButton = () => {
        modalError ? closeModal() : history.push("/dashboard");
    };

    Modal.setAppElement("#root"); // Need to set this for screen readers to avoid errors in development

    // Form handling
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsConnecting(true); // Disable Publish/Update buttons while connecting to db

        const keyValue = uuidv4(); // Generate unique key value

        const Property = {
            photos: photoArray, // value passed from PropertyImageUpload component
            floorplanImage: floorPlanImg, // value passed from PropertyImageUpload component
            username: user.username,
            name: displayAddress + " " + keyValue, // Used as key value which needs to be unuique. Not public.
            category,
            status,
            type,
            bedrooms,
            bathrooms,
            garden,
            price,
            priceType,
            displayAddress, // Public name of the property
            location,
            address,
            coordinates,
            summary,
            description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            features: [
                // Send to database empty string if there is noIcon
                { text: feature1text, icon: feature1icon.includes("noIcon") ? "" : feature1icon },
                { text: feature2text, icon: feature2icon.includes("noIcon") ? "" : feature2icon },
                { text: feature3text, icon: feature3icon.includes("noIcon") ? "" : feature3icon },
                { text: feature4text, icon: feature4icon.includes("noIcon") ? "" : feature4icon },
                { text: feature5text, icon: feature5icon.includes("noIcon") ? "" : feature5icon },
                { text: feature6text, icon: feature6icon.includes("noIcon") ? "" : feature6icon },
                { text: feature7text, icon: feature7icon.includes("noIcon") ? "" : feature7icon },
                { text: feature8text, icon: feature8icon.includes("noIcon") ? "" : feature8icon },
            ],
            virtualTourLink,
            adminMemo,
            published,
        };

        console.log(Property);

        if (!isEditMode) {
            // Post the new property
            try {
                await axiosInstance.post("/properties", Property);
                setModalError(false);
                setModalIsOpen(true);
                setIsConnecting(false);
                published
                    ? setModalMessage("Property was successfully published")
                    : setModalMessage("Property draft was created");
            } catch (error) {
                setModalError(false);
                setModalIsOpen(true);
                setIsConnecting(false);
                setModalMessage("There was an error publishing this property.");
                setModalError(true);
                console.log(error);
            }
        } else {
            // Update existing property
            try {
                await axiosInstance.put(`/properties/${propertyToEdit._id}`, Property);
                setModalIsOpen(true);
                setModalMessage("Property is successfully updated");
                setIsConnecting(false);
            } catch (error) {
                setModalIsOpen(true);
                setModalMessage("There was an error publishing this property.");
                setModalError(true);
                setIsConnecting(false);
                console.log(error);
            }
        }
    };

    const transportRow = [
        {
            name: "noIcon",
            icon: <NoTransport style={iconStyle} />,
        },
        {
            name: "NationalRail",
            icon: <NationalRail style={iconStyle} />,
        },
        {
            name: "Underground",
            icon: <Underground style={iconStyle} />,
        },
        {
            name: "Overground",
            icon: <Overground style={iconStyle} />,
        },
        {
            name: "DLR",
            icon: <DLR style={iconStyle} />,
        },
        {
            name: "Bus",
            icon: <Bus style={iconStyle} />,
        },
        {
            name: "Tramlink",
            icon: <Tramlink style={iconStyle} />,
        },
        {
            name: "River",
            icon: <River style={iconStyle} />,
        },
    ];

    return (
        <PropertyForm onSubmit={handleSubmit}>
            <SectionWrap>
                <SectionTitle>Property Type</SectionTitle>
                <Row>
                    <RadioWrap style={{ marginRight: "1rem" }}>
                        <RadioInput
                            type="radio"
                            id="letting"
                            name="category"
                            value="Lettings"
                            checked={category === "Lettings"}
                            onChange={(e) => setCategory(e.target.value)}
                            hidden
                        />
                        <RadioLabel htmlFor="letting">LETTING</RadioLabel>
                        <RadioInput
                            type="radio"
                            id="sale"
                            name="category"
                            value="Sales"
                            checked={category === "Sales"}
                            onChange={(e) => setCategory(e.target.value)}
                            hidden
                        />
                        <RadioLabel htmlFor="sale">SALE</RadioLabel>
                    </RadioWrap>

                    <RadioWrap>
                        <RadioInput
                            available
                            type="radio"
                            id="available"
                            name="availability"
                            value="Available"
                            checked={status === "Available"}
                            onChange={(e) => setStatus(e.target.value)}
                            hidden
                        />
                        <RadioLabel htmlFor="available">available</RadioLabel>
                        <>
                            <RadioInput
                                let
                                type="radio"
                                id="let"
                                name="availability"
                                value="Let"
                                checked={status === "Let"}
                                onChange={(e) => setStatus(e.target.value)}
                                hidden
                            />
                            <RadioLabel htmlFor="let">let</RadioLabel>
                        </>

                        <>
                            <RadioInput
                                underOffer
                                type="radio"
                                id="underOffer"
                                name="availability"
                                value="Under Offer"
                                checked={status === "Under Offer"}
                                onChange={(e) => setStatus(e.target.value)}
                                hidden
                            />
                            <RadioLabel htmlFor="underOffer">Under Offer</RadioLabel>
                            <RadioInput
                                sold
                                type="radio"
                                id="sold"
                                name="availability"
                                value="Sold"
                                checked={status === "Sold"}
                                onChange={(e) => setStatus(e.target.value)}
                                hidden
                            />
                            <RadioLabel htmlFor="sold">sold</RadioLabel>
                        </>
                    </RadioWrap>
                </Row>
                <Row>
                    <Grid custom="2fr 1fr 1fr 1fr" gap="1rem">
                        <DropdownWrap>
                            <FieldTitle>Type</FieldTitle>
                            <ArrowDown small />
                            <DropdownSelect
                                small
                                onChange={(e) => setType(e.target.value)}
                                value={type}
                            >
                                <Option value="Studio Flat">Studio Flat</Option>
                                <Option value="Flat">Flat</Option>
                                <Option value="Apartment">Apartment</Option>
                                <Option value="Garden Flat">Garden Flat</Option>
                                <Option value="House">House</Option>
                                <Option value="Terraced House">Terraced House</Option>
                                <Option value="End of Terrace">End of Terrace</Option>
                                <Option value="Maisonette">Maisonette</Option>
                                <Option value="Detached">Detached</Option>
                                <Option value="Semi-Detached">Semi-Detached</Option>
                                <Option value="Bungalow">Bungalow</Option>
                                <Option value="Town House">Town House</Option>
                                <Option value="Bedsit">Bedsit</Option>
                            </DropdownSelect>
                        </DropdownWrap>
                        <DropdownWrap>
                            <FieldTitle>Bedrooms</FieldTitle>
                            <ArrowDown small />
                            <DropdownSelect
                                small
                                onChange={(e) => setBedrooms(e.target.value)}
                                value={bedrooms}
                            >
                                <Option value={0}>Studio</Option>
                                <Option value={1}>1</Option>
                                <Option value={2}>2</Option>
                                <Option value={3}>3</Option>
                                <Option value={4}>4</Option>
                                <Option value={5}>5</Option>
                                <Option value={6}>6</Option>
                                <Option value={7}>7</Option>
                                <Option value={8}>8</Option>
                                <Option value={9}>9</Option>
                                <Option value={10}>10</Option>
                            </DropdownSelect>
                        </DropdownWrap>
                        <DropdownWrap>
                            <FieldTitle>Bathrooms</FieldTitle>
                            <ArrowDown small />
                            <DropdownSelect
                                small
                                onChange={(e) => setBathrooms(e.target.value)}
                                value={bathrooms}
                            >
                                <Option value={1}>1</Option>
                                <Option value={2}>2</Option>
                                <Option value={3}>3</Option>
                                <Option value={4}>4</Option>
                                <Option value={5}>5</Option>
                                <Option value={6}>6</Option>
                                <Option value={7}>7</Option>
                                <Option value={8}>8</Option>
                                <Option value={9}>9</Option>
                                <Option value={10}>10</Option>
                            </DropdownSelect>
                        </DropdownWrap>
                        <div>
                            <FieldTitle>Garden</FieldTitle>
                            <RadioWrap>
                                <RadioInput
                                    type="radio"
                                    id="garden_yes"
                                    name="garden"
                                    value="true"
                                    onChange={(e) => setGarden(e.target.value)}
                                    hidden
                                    checked={garden === "true" || garden === true}
                                />
                                {console.log(garden)}
                                <RadioLabel htmlFor="garden_yes">YES</RadioLabel>
                                <RadioInput
                                    type="radio"
                                    id="garden_no"
                                    name="garden"
                                    value="false"
                                    onChange={(e) => setGarden(e.target.value)}
                                    hidden
                                    checked={
                                        garden === "false" || garden === false || garden === null
                                    }
                                />
                                <RadioLabel htmlFor="garden_no">NO</RadioLabel>
                            </RadioWrap>
                        </div>
                    </Grid>
                </Row>
                <FieldTitle>£ Price</FieldTitle>
                <Row>
                    <Input
                        type="number"
                        name="price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        style={{
                            width: "8rem",
                            borderTopRightRadius: "0",
                            borderBottomRightRadius: "0",
                        }}
                    />

                    {category === "Lettings" && (
                        <RadioWrap>
                            <RadioInput
                                type="radio"
                                id="pw"
                                name="priceType"
                                value="PW"
                                checked={priceType === "PW"}
                                onChange={(e) => setPriceType(e.target.value)}
                                hidden
                            />
                            <RadioLabel htmlFor="pw">PW</RadioLabel>
                            <RadioInput
                                type="radio"
                                id="pcm"
                                name="priceType"
                                value="PCM"
                                checked={priceType === "PCM"}
                                onChange={(e) => setPriceType(e.target.value)}
                                hidden
                            />
                            <RadioLabel htmlFor="pcm">PCM</RadioLabel>
                        </RadioWrap>
                    )}
                </Row>
            </SectionWrap>
            <SectionWrap style={{ marginTop: "3rem" }}>
                <SectionTitle>Property Location</SectionTitle>
                <Grid custom="1.5fr 1fr" gap="1rem">
                    <div>
                        <FieldTitle>Display Address / Property Title</FieldTitle>
                        <Input
                            type="text"
                            value={displayAddress}
                            onChange={(e) => setDisplayAddress(e.target.value)}
                        ></Input>
                    </div>
                    <div style={{ boxSizing: "inherit" }}>
                        <FieldTitle>Display Area</FieldTitle>

                        {!isEditMode && (
                            <StyledDropdown
                                onChange={handleDropdownSelect}
                                options={areaOptions}
                                value={value}
                                defaultValue={location}
                                search
                                selection
                            />
                        )}
                        {/* The Dropdown component doesn't seem to update the defaultValue straight away after load, so need to render the dropdown only when the location is loaded. Need to revisit this in the future to optimise better. */}
                        {isEditMode && location && (
                            <StyledDropdown
                                onChange={handleDropdownSelect}
                                options={areaOptions}
                                value={value}
                                defaultValue={location}
                                search
                                selection
                            />
                        )}
                    </div>
                </Grid>
                <Grid>
                    <FieldTitle>Full Address</FieldTitle>
                    <PacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelectAddress}
                        searchOptions={searchOptions}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <Input {...getInputProps()} />
                                <AutoCompleteWrap>
                                    {loading && "loading..."}
                                    {suggestions.map((suggestion, i) => {
                                        const style = {
                                            backgroundColor: suggestion.active
                                                ? "#A2DEF9"
                                                : "white",
                                            cursor: "pointer",
                                            padding: ".25rem",
                                            fontSize: ".8rem",
                                        };
                                        return (
                                            <div
                                                key={i}
                                                {...getSuggestionItemProps(suggestion, { style })}
                                            >
                                                {suggestion.description}
                                                {console.log(suggestion)}
                                            </div>
                                        );
                                    })}
                                </AutoCompleteWrap>
                            </div>
                        )}
                    </PacesAutocomplete>
                    <GoogleMapWrapper>
                        <GoogleMap
                            center={currentMapCenter}
                            mapContainerStyle={mapContainerStyle}
                            zoom={currentZoom}
                            options={options}
                        >
                            <Marker position={coordinates}></Marker>
                        </GoogleMap>
                    </GoogleMapWrapper>
                </Grid>
            </SectionWrap>
            <SectionWrap style={{ marginTop: "3rem" }}>
                <SectionTitle>Property Description</SectionTitle>
                <FieldTitle>Summary</FieldTitle>
                <Summary
                    rows="5"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                ></Summary>
                <FieldTitle>Description</FieldTitle>
                <div>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="rich-text-wrapper"
                        editorClassName="rich-text-editor"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                            options: ["inline", "list"],
                            inline: {
                                options: ["bold", "italic", "underline", "strikethrough"],
                                className: "editor-toolbar",
                            },
                            list: { options: ["unordered", "ordered"] },
                        }}
                    />
                </div>
                <FieldTitle>Features / Transport</FieldTitle>
                <Grid custom="1.5fr 1fr" gap="1rem" style={{ marginBottom: "1rem" }}>
                    <Input
                        type="text"
                        value={feature1text}
                        onChange={(e) => setFeature1Text(e.target.value)}
                    />
                    <RadioTransportWrap>
                        {transportRow.map((feature, i) => (
                            <div key={i}>
                                <RadioTransportInput
                                    type="radio"
                                    id={feature.name}
                                    name="feature1icon"
                                    value={feature.name}
                                    checked={feature1icon === feature.name}
                                    onChange={(e) => setFeature1Icon(e.target.value)}
                                    hidden
                                />
                                <RadioTransportLabel htmlFor={feature.name}>
                                    {feature.icon}
                                </RadioTransportLabel>
                            </div>
                        ))}
                    </RadioTransportWrap>
                </Grid>
                <Grid custom="1.5fr 1fr" gap="1rem" style={{ marginBottom: "1rem" }}>
                    <Input
                        type="text"
                        value={feature2text}
                        onChange={(e) => setFeature2Text(e.target.value)}
                    />
                    <RadioTransportWrap>
                        {transportRow.map((feature, i) => (
                            <div key={i}>
                                <RadioTransportInput
                                    type="radio"
                                    id={feature.name + "2"}
                                    name="feature2icon"
                                    value={feature.name}
                                    checked={feature2icon === feature.name}
                                    onChange={(e) => setFeature2Icon(e.target.value)}
                                    hidden
                                />
                                <RadioTransportLabel htmlFor={feature.name + "2"}>
                                    {feature.icon}
                                </RadioTransportLabel>
                            </div>
                        ))}
                    </RadioTransportWrap>
                </Grid>
                <Grid custom="1.5fr 1fr" gap="1rem" style={{ marginBottom: "1rem" }}>
                    <Input
                        type="text"
                        value={feature3text}
                        onChange={(e) => setFeature3Text(e.target.value)}
                    />
                    <RadioTransportWrap>
                        {transportRow.map((feature, i) => (
                            <div key={i}>
                                <RadioTransportInput
                                    type="radio"
                                    id={feature.name + "3"}
                                    name="feature3icon"
                                    value={feature.name}
                                    checked={feature3icon === feature.name}
                                    onChange={(e) => setFeature3Icon(e.target.value)}
                                    hidden
                                />
                                <RadioTransportLabel htmlFor={feature.name + "3"}>
                                    {feature.icon}
                                </RadioTransportLabel>
                            </div>
                        ))}
                    </RadioTransportWrap>
                </Grid>
                <Grid custom="1.5fr 1fr" gap="1rem" style={{ marginBottom: "1rem" }}>
                    <Input
                        type="text"
                        value={feature4text}
                        onChange={(e) => setFeature4Text(e.target.value)}
                    />
                    <RadioTransportWrap>
                        {transportRow.map((feature, i) => (
                            <div key={i}>
                                <RadioTransportInput
                                    type="radio"
                                    id={feature.name + "4"}
                                    name="feature4icon"
                                    value={feature.name}
                                    checked={feature4icon === feature.name}
                                    onChange={(e) => setFeature4Icon(e.target.value)}
                                    hidden
                                />
                                <RadioTransportLabel htmlFor={feature.name + "4"}>
                                    {feature.icon}
                                </RadioTransportLabel>
                            </div>
                        ))}
                    </RadioTransportWrap>
                </Grid>
                <Grid custom="1.5fr 1fr" gap="1rem" style={{ marginBottom: "1rem" }}>
                    <Input
                        type="text"
                        value={feature5text}
                        onChange={(e) => setFeature5Text(e.target.value)}
                    />
                    <RadioTransportWrap>
                        {transportRow.map((feature, i) => (
                            <div key={i}>
                                <RadioTransportInput
                                    type="radio"
                                    id={feature.name + "5"}
                                    name="feature5icon"
                                    value={feature.name}
                                    checked={feature5icon === feature.name}
                                    onChange={(e) => setFeature5Icon(e.target.value)}
                                    hidden
                                />
                                <RadioTransportLabel htmlFor={feature.name + "5"}>
                                    {feature.icon}
                                </RadioTransportLabel>
                            </div>
                        ))}
                    </RadioTransportWrap>
                </Grid>
                <Grid custom="1.5fr 1fr" gap="1rem" style={{ marginBottom: "1rem" }}>
                    <Input
                        type="text"
                        value={feature6text}
                        onChange={(e) => setFeature6Text(e.target.value)}
                    />
                    <RadioTransportWrap>
                        {transportRow.map((feature, i) => (
                            <div key={i}>
                                <RadioTransportInput
                                    type="radio"
                                    id={feature.name + "6"}
                                    name="feature6icon"
                                    value={feature.name}
                                    checked={feature6icon === feature.name}
                                    onChange={(e) => setFeature6Icon(e.target.value)}
                                    hidden
                                />
                                <RadioTransportLabel htmlFor={feature.name + "6"}>
                                    {feature.icon}
                                </RadioTransportLabel>
                            </div>
                        ))}
                    </RadioTransportWrap>
                </Grid>
                <Grid custom="1.5fr 1fr" gap="1rem" style={{ marginBottom: "1rem" }}>
                    <Input
                        type="text"
                        value={feature7text}
                        onChange={(e) => setFeature7Text(e.target.value)}
                    />
                    <RadioTransportWrap>
                        {transportRow.map((feature, i) => (
                            <div key={i}>
                                <RadioTransportInput
                                    type="radio"
                                    id={feature.name + "7"}
                                    name="feature7icon"
                                    value={feature.name}
                                    checked={feature7icon === feature.name}
                                    onChange={(e) => setFeature7Icon(e.target.value)}
                                    hidden
                                />
                                <RadioTransportLabel htmlFor={feature.name + "7"}>
                                    {feature.icon}
                                </RadioTransportLabel>
                            </div>
                        ))}
                    </RadioTransportWrap>
                </Grid>
                <Grid custom="1.5fr 1fr" gap="1rem" style={{ marginBottom: "1rem" }}>
                    <Input
                        type="text"
                        value={feature8text}
                        onChange={(e) => setFeature8Text(e.target.value)}
                    />
                    <RadioTransportWrap>
                        {transportRow.map((feature, i) => (
                            <div key={i}>
                                <RadioTransportInput
                                    type="radio"
                                    id={feature.name + "8"}
                                    name="feature8icon"
                                    value={feature.name}
                                    checked={feature8icon === feature.name}
                                    onChange={(e) => setFeature8Icon(e.target.value)}
                                    hidden
                                />
                                <RadioTransportLabel htmlFor={feature.name + "8"}>
                                    {feature.icon}
                                </RadioTransportLabel>
                            </div>
                        ))}
                    </RadioTransportWrap>
                </Grid>
                <FieldTitle>Virtual Tour Link</FieldTitle>
                <Input
                    type="text"
                    value={virtualTourLink}
                    onChange={(e) => setVirtualTourLink(e.target.value)}
                ></Input>
                <FieldTitle>Admin memo</FieldTitle>
                <AdminMemo
                    rows="5"
                    value={adminMemo}
                    onChange={(e) => setAdminMemo(e.target.value)}
                ></AdminMemo>
            </SectionWrap>
            <SubmitWrap>
                <Grid lg={3} gap="1rem">
                    {isConnecting && published ? ( // Render a spinner and disable submit button while connecting
                        <Button bright type="button">
                            <Loader
                                type="Puff"
                                color="white"
                                height={15}
                                width={15}
                                style={spinnerStyle}
                            />
                        </Button>
                    ) : (
                        <Button bright type="submit" onClick={(e) => setPublished(true)}>
                            {isEditMode ? "Update & Publish" : "Publish"}
                        </Button>
                    )}

                    {isConnecting && !published ? ( // Render a spinner and disable submit button while connecting
                        <Button primary type="button">
                            <Loader
                                type="Puff"
                                color="white"
                                height={15}
                                width={15}
                                style={spinnerStyle}
                            />
                        </Button>
                    ) : (
                        <Button primary type="submit" onClick={(e) => setPublished(false)}>
                            {isEditMode ? "Update & Save Draft" : "Save Draft"}
                        </Button>
                    )}

                    <Button discard type="button" onClick={(e) => history.push("/dashboard")}>
                        {isEditMode ? "Cancel editing" : "Discard"}
                    </Button>
                </Grid>
            </SubmitWrap>

            {/* Modal after form submission or discard */}
            <Modal isOpen={modalIsOpen} style={modalStyles} closeTimeoutMS={300}>
                <ModalContentsWrap>
                    <ModalMessage>{modalMessage}</ModalMessage>
                    <Button primary onClick={handleModalButton} style={{ margin: "0 auto" }}>
                        {modalError ? "Close" : "Back to dashboard"}
                    </Button>
                </ModalContentsWrap>
            </Modal>
        </PropertyForm>
    );
}
