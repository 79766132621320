import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import { MenuList, MenuItem, SubmenuList, SubmenuItem } from "../styles/Navigation";
import styled from "styled-components";

// Icons & Images
import { Dashboard } from "@material-ui/icons";

const DashboardIcon = styled(Dashboard)`
    opacity: 0.2;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        opacity: 1;
        transition: 0.3s;
    }
`;

const DashLink = styled(Link)`
    position: absolute;
    right: 1%;
`;

export default function DesktopMenu({ nav, currentPage }) {
    const [isOpen, setIsOpen] = useState(false);

    const { user } = useContext(Context);

    const close = () => setIsOpen(false);
    const toggle = () => setIsOpen(!isOpen);

    const show = {
        opacity: 1,
        pointerEvents: "all",
    };

    const hide = {
        opacity: 0,
        pointerEvents: "none",
    };

    return (
        <MenuList>
            {nav.map((item, index) => (
                <MenuItem key={index} style={{ background: item.title === currentPage && "#0f2544" }}>
                    <Link
                        to={item.link}
                        onMouseOver={item.submenu ? toggle : null}
                        onMouseLeave={item.submenu ? close : null}
                    >
                        {item.title}
                    </Link>
                    {item.submenu && (
                        <SubmenuList style={isOpen ? show : hide} onMouseLeave={toggle}>
                            {item.submenu.map((subitem, index) => (
                                <SubmenuItem key={index}>
                                    {/* Check if it's an external or internal link first */}
                                    {subitem.external ? (
                                        <a href={subitem.link} target={subitem.target} rel="noreferrer">
                                            {subitem.title}
                                            {subitem.icon}
                                        </a>
                                    ) : (
                                        // Use Link component to render the link if it's internal
                                        <Link to={subitem.link} target={subitem.target} rel="noreferrer">
                                            {subitem.title}
                                            {subitem.icon}
                                        </Link>
                                    )}
                                </SubmenuItem>
                            ))}
                        </SubmenuList>
                    )}
                </MenuItem>
            ))}
            {user && (
                <DashLink to="/dashboard">
                    <DashboardIcon />
                </DashLink>
            )}
        </MenuList>
    );
}
