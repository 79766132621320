export const priceRangeSales = [
    50000, 60000, 70000, 80000, 90000, 100000, 110000, 120000, 130000, 140000, 150000, 160000, 170000, 180000,
    190000, 200000, 210000, 220000, 230000, 240000, 250000, 260000, 270000, 280000, 290000, 300000, 310000,
    320000, 330000, 340000, 350000, 360000, 370000, 380000, 390000, 400000, 410000, 420000, 430000, 440000,
    450000, 460000, 470000, 480000, 490000, 500000, 510000, 520000, 530000, 540000, 550000, 560000, 570000,
    580000, 590000, 600000, 610000, 620000, 630000, 640000, 650000, 660000, 670000, 680000, 690000, 700000,
    710000, 720000, 730000, 740000, 750000, 760000, 770000, 780000, 790000, 800000, 810000, 820000, 830000,
    840000, 850000, 860000, 870000, 880000, 890000, 900000, 910000, 920000, 930000, 940000, 950000, 960000,
    970000, 980000, 990000, 1000000, 1010000, 1020000, 1030000, 1040000, 1050000, 1060000, 1070000, 1080000,
    1090000, 1100000, 1110000, 1120000, 1130000, 1140000, 1150000, 1160000, 1170000, 1180000, 1190000,
    1200000, 1210000, 1220000, 1230000, 1240000, 1250000, 1260000, 1270000, 1280000, 1290000, 1300000,
    1310000, 1320000, 1330000, 1340000, 1350000, 1360000, 1370000, 1380000, 1390000, 1400000, 1410000,
    1420000, 1430000, 1440000, 1450000, 1460000, 1470000, 1480000, 1490000, 1500000, 1510000, 1520000,
    1530000, 1540000, 1550000, 1560000, 1570000, 1580000, 1590000, 1600000, 1610000, 1620000, 1630000,
    1640000, 1650000, 1660000, 1670000, 1680000, 1690000, 1700000, 1710000, 1720000, 1730000, 1740000,
    1750000, 1760000, 1770000, 1780000, 1790000, 1800000, 1810000, 1820000, 1830000, 1840000, 1850000,
    1860000, 1870000, 1880000, 1890000, 1900000, 1910000, 1920000, 1930000, 1940000, 1950000, 1960000,
    1970000, 1980000, 1990000, 2000000, 2010000, 2020000, 2030000, 2040000, 2050000, 2060000, 2070000,
    2080000, 2090000, 2100000, 2110000, 2120000, 2130000, 2140000, 2150000, 2160000, 2170000, 2180000,
    2190000, 2200000, 2210000, 2220000, 2230000, 2240000, 2250000, 2260000, 2270000, 2280000, 2290000,
    2300000, 2310000, 2320000, 2330000, 2340000, 2350000, 2360000, 2370000, 2380000, 2390000, 2400000,
    2410000, 2420000, 2430000, 2440000, 2450000, 2460000, 2470000, 2480000, 2490000, 2500000, 2510000,
    2520000, 2530000, 2540000, 2550000, 2560000, 2570000, 2580000, 2590000, 2600000, 2610000, 2620000,
    2630000, 2640000, 2650000, 2660000, 2670000, 2680000, 2690000, 2700000, 2710000, 2720000, 2730000,
    2740000, 2750000, 2760000, 2770000, 2780000, 2790000, 2800000, 2810000, 2820000, 2830000, 2840000,
    2850000, 2860000, 2870000, 2880000, 2890000, 2900000, 2910000, 2920000, 2930000, 2940000, 2950000,
    2960000, 2970000, 2980000, 2990000, 3000000,
];

export const priceRangeLettings = [
    100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900,
    2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000,
];
