import { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import Loader from "react-loader-spinner";
import { LoaderWrapper } from "../../components/styles/Loader";

import NavBar from "../../components/fragments/NavBar";
import CreatePostForm from "../../components/fragments/CreatePostForm";
import { Container } from "../../components/styles/Layout";

const Title = styled.h1`
    text-align: center;
    margin: 2rem;
`;

export default function EditPost() {
    const [postToEdit, setPostToEdit] = useState(null);

    const url = useLocation();
    const path = url.pathname.split("/")[3]; // Get the id of the property to edit

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    useEffect(() => {
        const getPost = async () => {
            try {
                const res = await axiosInstance.get(`/posts/${path}`);
                setPostToEdit(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        getPost();
    }, [path]);

    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar location="admin" />
            <Container>
                <Title>Edit Post</Title>
                {postToEdit ? (
                    <CreatePostForm postToEdit={postToEdit} />
                ) : (
                    <LoaderWrapper>
                        <Loader type="Puff" color="#A2DEF9" height={60} width={60} />
                    </LoaderWrapper>
                )}
            </Container>
        </>
    );
}
