import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDropzone } from "react-dropzone";
import Loader from "react-loader-spinner";

const SectionWrap = styled.section`
    padding: 0rem;
`;

const SectionTitle = styled.h4`
    margin: -1.45rem 0 0 0;
    padding-bottom: 0.4rem;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.9rem;
    color: grey;
`;

const DropArea = styled.div`
    background: #f6f6f6;
    padding: 2rem;
    border: 1px dashed #c2c2c2;
    height: 6rem;
`;

const Img = styled.img`
    object-fit: cover;
    width: 7rem;
`;
const ImgWrap = styled.div`
    height: 4rem;
    display: flex;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 0.25rem;
    margin-bottom: 0.25rem;
`;

const PhotoContainer = styled.div``;

const DeleteButton = styled.button`
    & svg {
        fill: grey;
        pointer-events: none;
    }
`;

const DropzoneInfo = styled.div`
    font-size: 0.7rem;
    font-weight: 300;
    margin-bottom: 1rem;
`;

const SpinnerWrap = styled.div`
    position: absolute;
    margin-left: 16%;
    margin-top: -5rem;
`;

const FloorPlanBtn = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
    background: ${(props) => (props.selected ? props.theme.darkblue : "white")};
    margin-left: 0.5rem;
    border-radius: 3px;
    font-weight: 500;
    color: ${(props) => (props.selected ? "white" : "grey")};
    cursor: pointer;
    font-size: 0.7rem;
    text-transform: uppercase;
    &:hover {
        color: ${(props) => !props.selected && props.theme.darkblue};
    }
`;

export default function PropertyImageUpload({ setPhotoArray, propertyToEdit, setFloorPlanImg }) {
    // Upload images states
    const [uploadProgress, setUploadProgress] = useState("");
    const [photos, setPhotos] = useState([]);

    // Image re-arrangement state
    const [imagePosition, updateImagePosition] = useState(photos);

    // Get the url of floorplan image
    const [floorPlanImgUrl, setFloorPlanImgUrl] = useState("");

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // Check if photos are already provided i.e. is this edit page?
    useEffect(() => {
        if (propertyToEdit) {
            updateImagePosition(propertyToEdit.photos);
            setPhotoArray(propertyToEdit.photos);
            setFloorPlanImgUrl(propertyToEdit.floorplanImage);
            setFloorPlanImg(propertyToEdit.floorplanImage);
        }
    }, []);

    // Image re-arrangement handling
    const handleOnDragEnd = (result) => {
        if (!result.destination) return; // If dragged out of bounds, return
        const images = Array.from(imagePosition);
        const [reorderedItem] = images.splice(result.source.index, 1);
        images.splice(result.destination.index, 0, reorderedItem);
        updateImagePosition(images); // Update front-end state
        setPhotos(images); // Update newly arranged photo store
        setPhotoArray(images); // Send state to parent component
        setFloorPlanImg(floorPlanImgUrl); // Send floorplan image url to parent component
    };

    // Image upload handling
    const uploadToS3 = async (passedFile) => {
        setUploadProgress(<Loader type="Puff" color="#c2c2c2" height={30} width={30} />);

        // Get the presigned url from S3
        let presignedURL = "";

        try {
            const res = await axiosInstance.get("/s3url");
            presignedURL = res.data.uploadURL;
        } catch (error) {
            console.log(error);
        }

        // Upload the image to S3
        try {
            const res = await axios.put(presignedURL, passedFile);
            res && setUploadProgress("");
        } catch (error) {
            console.log(error);
        }

        // Send the image url to the server
        const imgUrl = presignedURL.split("?")[0];
        const newPhoto = imagePosition;
        newPhoto.push(imgUrl);

        updateImagePosition(newPhoto); // Reset sorting
        setPhotos([]); // Need to clear the array for DOM to rerender
        setPhotos(newPhoto);
        setPhotoArray(newPhoto); // Send state to parent component

        setFloorPlanImg(floorPlanImgUrl); // Send floorplan image url to parent component
    };

    // Image delete handling
    const handleDelete = (e) => {
        const images = Array.from(imagePosition);

        // Find the item index in the array and delete it
        for (let i = 0; i < images.length; i++) {
            if (images[i] === e.target.value) {
                images.splice(i, 1);
                break;
            }
        }

        updateImagePosition(images);
        setPhotos(images);
        setPhotoArray(images); // Send state to parent component

        // Check if the deleted image was floorplan image
        const isFloorPlanThere = images.find((img) => img === floorPlanImgUrl);
        isFloorPlanThere ? setFloorPlanImg(floorPlanImgUrl) : setFloorPlanImg(""); // Send state to parent component
    };

    // Image Drag'n'drop handling
    const onDrop = useCallback(
        (acceptedFiles) => {
            // Do something with the uploaded files
            acceptedFiles.forEach((file) => {
                uploadToS3(file);
            });
        },
        [uploadToS3]
    );

    // Handle floorplan image flag
    const handleFloorPlanImg = (url) => {
        if (url !== floorPlanImgUrl) {
            setFloorPlanImgUrl(url);
            setFloorPlanImg(url);
        } else {
            // Reset floorplan image url
            setFloorPlanImgUrl("");
            setFloorPlanImg("");
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <SectionWrap>
            <SectionTitle>PHOTO GALLERY</SectionTitle>
            <DropArea {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop the files here ...</p>
                ) : (
                    <p>Drag 'n' drop photos here, or click to select files</p>
                )}
            </DropArea>
            <DropzoneInfo>Accepted files: jpg/jpeg</DropzoneInfo>
            <SpinnerWrap>{uploadProgress}</SpinnerWrap>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="photos">
                    {(provided) => (
                        <PhotoContainer {...provided.droppableProps} ref={provided.innerRef}>
                            {imagePosition.map((url, i) => (
                                <Draggable key={url} draggableId={url} index={i}>
                                    {(provided) => (
                                        <ImgWrap
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <Img src={url} />
                                            <DeleteButton onClick={handleDelete} value={url}>
                                                <DeleteIcon />
                                            </DeleteButton>
                                            <FloorPlanBtn
                                                onClick={(e) => handleFloorPlanImg(url)}
                                                selected={floorPlanImgUrl === url}
                                            >
                                                {floorPlanImgUrl === url
                                                    ? "Floorplan"
                                                    : "Set as Floorplan"}
                                            </FloorPlanBtn>
                                        </ImgWrap>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </PhotoContainer>
                    )}
                </Droppable>
            </DragDropContext>
        </SectionWrap>
    );
}
