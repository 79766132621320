import axios from "axios";
import styled from "styled-components";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Loader from "react-loader-spinner";
import { Grid } from "../styles/Layout";
import { Summary, Input } from "../styles/Form";

import { Save, DeleteForever } from "@material-ui/icons";

const MemberWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 5fr 0.25fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
    cursor: ns-resize;
    border-bottom: 1px solid #f5f5f5;
    padding: 1rem;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #fafafa;
        transition: all 0.3s ease-in-out;
    }
`;

const ImgContainer = styled.div`
    position: relative;
`;

const ImgWrapper = styled.div``;

const Img = styled.img`
    width: 100%;
    height: 8rem;
    object-fit: cover;
`;

const Droparea = styled.div`
    background: #f6f6f6;
    padding: 0.68rem;
    border: 1px dashed #c2c2c2;
    height: 1rem;
    font-size: 0.75rem;
    cursor: pointer;
    /* margin-top: 0.2rem; */
`;

const InfoContainer = styled.div`
    /* & * {
        margin-bottom: 0.25rem;
    } */
    /* & input,
    textarea {
        background: none;
        box-shadow: none;
        border: 1px solid #ffffff00;
        font-size: 0.8rem;
        padding: 0.25rem;
    } */
    & input:hover,
    textarea:hover {
        background: white;
        /* border: 1px solid black; */
    }
    position: relative;
`;

const LoaderSpinner = styled(Loader)`
    margin-top: -1.2rem;
`;

const Name = styled(Input)`
    margin-top: 0;
    font-weight: 500;
    display: inline-block;
`;
const Position = styled(Input)`
    display: block;
`;
const Quote = styled(Summary)`
    display: block;
    width: 100%;
    font-style: italic;
    resize: none;
    min-height: 3rem;
`;
const Info = styled(Summary)`
    display: block;
    width: 100%;
    min-height: 5.5rem;
    resize: none;
`;
const ButtonWrapper = styled.div`
    margin-top: 1rem;
    position: relative;
`;

const FieldName = styled.div`
    font-size: 0.8rem;
    color: grey;
`;

const DeleteBtn = styled(DeleteForever)`
    color: #c9c9c9;
    border-radius: 5px;
    background: #f8f8f8;
    padding: 0.25rem;
    transition: 0.3s;
    border: 1px solid #ffffff00;
    &:hover {
        border: 1px solid #c9c9c9;
        transition: 0.3s;
        cursor: pointer;
    }
`;

const SaveBtn = styled(Save)`
    color: ${(props) => props.theme.darkblue};
    border-radius: 5px;
    background: #ebf3ff;
    padding: 0.25rem;
    transition: 0.3s;
    border: 1px solid #ffffff00;
    margin-right: 0.5rem;
    &:hover {
        border: 1px solid ${(props) => props.theme.darkblue};
        transition: 0.3s;
        cursor: pointer;
    }
`;

const SaveLink = styled.a`
    &:hover {
        &:after {
            content: "update";
            position: absolute;
            /* margin: 0.65rem 0 0 0; */
            padding: 0.65rem;
            /* margin: 2.3rem 0 0 -4rem; */
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: 500;
            background: #f8f8f8;
        }
    }
`;

const DeleteLink = styled.a`
    &:hover {
        &:after {
            content: "delete";
            position: absolute;
            /* margin: 0.65rem 0 0 0.5rem; */
            padding: 0.65rem;
            /* margin: 2.3rem 0 0 -2.25rem; */
            text-transform: uppercase;
            font-size: 0.7rem;
            font-weight: 500;
            background: #f8f8f8;
        }
    }
`;

const saveStyle = {
    position: "absolute",
    // right: ".5rem",
    cursor: "pointer",
    marginTop: "0rem",
    zIndex: "2",
};

const deleteStyle = {
    position: "absolute",
    // right: ".5rem",
    cursor: "pointer",
    marginTop: "2.5rem",
    zIndex: "2",
};

export default function EditTeamMember({ teamMember }) {
    // Upload images states
    const [loading, setLoading] = useState(false);
    const [photo, setPhoto] = useState(teamMember.image);

    // Form states
    const [name, setName] = useState(teamMember.name);
    const [position, setPosition] = useState(teamMember.position);
    const [quote, setQuote] = useState(teamMember.quote);
    const [info, setInfo] = useState(teamMember.info);
    const [oder, setOrder] = useState(teamMember.order);

    // Delete & Update states
    const [isDeleted, setIsDeleted] = useState(false);

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // Image upload handling
    const uploadToS3 = async (passedFile) => {
        setLoading(true);

        // Get the presigned url from S3
        let presignedURL = "";

        try {
            const res = await axiosInstance.get("/s3url");
            presignedURL = res.data.uploadURL;
        } catch (error) {
            console.log(error);
        }

        // Upload the image to S3
        try {
            const res = await axios.put(presignedURL, passedFile);
            res && setLoading(false);
        } catch (error) {
            console.log(error);
        }

        // Send the image url to the server
        const imgUrl = presignedURL.split("?")[0];
        setPhoto(imgUrl);
        console.log(imgUrl);

        // Update the team members picture in db
        try {
            await axiosInstance.put(`/team/${teamMember._id}`, { image: imgUrl });
        } catch (error) {
            console.log(error);
        }
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            // Do something with the uploaded files
            acceptedFiles.forEach((file) => {
                uploadToS3(file);
            });
            console.log(acceptedFiles[0]);
        },
        [uploadToS3]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`/team/${teamMember._id}`);
            setIsDeleted(true);
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdate = async () => {
        try {
            await axiosInstance.put(`/team/${teamMember._id}`, {
                name,
                position,
                quote,
                info,
                photo,
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {!isDeleted && (
                <MemberWrapper>
                    {teamMember ? (
                        <>
                            <ImgWrapper>
                                <ImgContainer>
                                    {photo && <Img src={photo} alt="img alt text" />}
                                </ImgContainer>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Droparea>
                                        {isDragActive ? (
                                            <>Drop the photo here ...</>
                                        ) : (
                                            <>Add or change photo</>
                                        )}
                                        {loading && (
                                            <LoaderSpinner
                                                type="Puff"
                                                color="#c2c2c2"
                                                height={30}
                                                width={30}
                                            />
                                        )}
                                    </Droparea>
                                </div>
                            </ImgWrapper>
                            <InfoContainer>
                                <Grid custom={"1fr 1fr 3fr"} gap={"1rem"}>
                                    <div>
                                        <FieldName>Name</FieldName>
                                        <Name
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <FieldName>Position</FieldName>
                                        <Position
                                            value={position}
                                            onChange={(e) => setPosition(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <FieldName>Quote</FieldName>
                                        <Quote
                                            value={quote}
                                            onChange={(e) => setQuote(e.target.value)}
                                        />
                                    </div>
                                </Grid>

                                <FieldName>Text</FieldName>
                                <Info value={info} onChange={(e) => setInfo(e.target.value)} />
                            </InfoContainer>
                            <ButtonWrapper>
                                {/* <button onClick={handleUpdate}>Update & Save</button>
                                    <button onClick={handleDelete}>Delete</button> */}

                                <SaveLink style={saveStyle} onClick={handleUpdate} type="button">
                                    <SaveBtn />
                                </SaveLink>

                                <DeleteLink
                                    style={deleteStyle}
                                    onClick={handleDelete}
                                    type="button"
                                >
                                    <DeleteBtn />
                                </DeleteLink>
                            </ButtonWrapper>
                        </>
                    ) : (
                        <>"Loading"</>
                    )}
                </MemberWrapper>
            )}
        </>
    );
}
