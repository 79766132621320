import styled from "styled-components";

import { ReactComponent as Quote } from "../../assets/img/quote-left-solid.svg";

const TestimonialWrapper = styled.div`
    padding: 1rem;
    background: #e8f8ff;
    border-radius: 10px;
    margin: 2rem 1rem;
    display: grid;
    grid-template-columns: 1fr 10fr;
    & p {
        color: ${(props) => props.theme.darkblue};
    }
`;
const QuoteIcon = styled(Quote)`
    width: 1.5rem;
    color: ${(props) => props.theme.darkblue};
    margin-top: 1rem;
`;

const Body = styled.p``;

const Author = styled.p`
    font-size: 0.8rem;
    font-weight: 500;
`;

export default function Testimonial({ body, author }) {
    return (
        <TestimonialWrapper>
            <QuoteIcon />
            <div>
                <Body>{body}</Body>
                <Author>{author}</Author>
            </div>
        </TestimonialWrapper>
    );
}
