// Dependencies
import { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Styles & Components
import { PublicNav, AdminNav } from "../../data/Nav";
import { Container } from "../styles/Layout";

// Icons & Images
import { ReactComponent as Logo } from "../../assets/img/pp-logo-light.svg";

// Navigation
import DesktopMenu from "./DesktopMenu";
import AdminMenu from "./AdminMenu";
import BurgerMenu from "./BurgerMenu";
import { size } from "../styles/Breakpoints";

const NavWrapper = styled.div`
    background: ${(props) => (props.location === "admin" ? "#221f1f" : (props) => props.theme.darkblue)};
    position: sticky;
    position: relative;
    @media print {
        display: none;
    }
`;

const NavRow = styled.div`
    display: flex;
    justify-content: space-between;
    height: 3rem;
`;

const LogoLight = styled(Logo)`
    width: 70%;
    @media print {
        display: none;
    }
`;

const logoStyle = {
    margin: "auto 1rem auto 0",
};

export default function NavBar({ location, pageTitle }) {
    const [isDesktop, setIsDesktop] = useState(false);

    const detectDesktop = () => {
        window.outerWidth >= size.sm ? setIsDesktop(true) : setIsDesktop(false);
    };

    useLayoutEffect(() => {
        detectDesktop();
        window.addEventListener("resize", () => {
            detectDesktop();
        });
    }, []);

    return (
        <NavWrapper location={location}>
            <Container>
                <NavRow>
                    <Link to="/" style={logoStyle}>
                        <LogoLight />
                    </Link>
                    {location === "public" ? (
                        <>
                            {isDesktop ? (
                                <DesktopMenu nav={PublicNav} currentPage={pageTitle} />
                            ) : (
                                <BurgerMenu nav={PublicNav} />
                            )}
                        </>
                    ) : (
                        <AdminMenu nav={AdminNav} currentPage={pageTitle} />
                    )}
                </NavRow>
            </Container>
        </NavWrapper>
    );
}
