export const size = {
    xs: 320,
    sm: 768,
    md: 992,
    lg: 1200,
};

export const device = {
    xs: `(max-width: ${size.xs}px)`,
    sm: `(max-width: ${size.sm}px)`,
    md: `(max-width: ${size.md}px)`,
    lg: `(max-width: ${size.lg}px)`,
};
