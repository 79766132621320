import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import axios from "axios";
import Modal from "react-modal";

// Styles
import { SectionWrap, SectionTitle, FieldTitle, Summary, Input, Span, SubmitWrap } from "../styles/Form";
import { Grid } from "../styles/Layout";
import { Button } from "../styles/Button";

// Rich Text Editor
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs"; //I'll need this in edit page
import { Context } from "../../context/Context";

const PostForm = styled.form``;

const ModalMessage = styled.h2`
    text-align: center;
`;

const ModalContentsWrap = styled.div`
    padding-bottom: 2rem;
`;

// Modal
const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
        border: "2px solid #a2def9",
        padding: "2rem",
    },
};

const editorOptions = {
    options: ["inline", "list", "link", "blockType"],
    inline: {
        options: ["bold", "italic", "underline", "strikethrough"],
        className: "editor-toolbar",
    },
    list: { options: ["unordered", "ordered"] },
    link: {
        inDropdown: false,
        options: ["link", "unlink"],
        className: "editor-toolbar",
    },
    blockType: {
        inDropdown: false,
        options: ["Normal", "H2", "H3", "H4", "H5", "H6"],
        className: "editor-toolbar font-style",
    },
};

export default function CreatePostForm({ postToEdit }) {
    // Get current user
    const { user } = useContext(Context);

    const [title, setTitle] = useState("");
    const [excerpt, setExcerpt] = useState("");
    const [content, setContent] = useState("");
    const [published, setPublished] = useState(true);

    // Rich text editor state
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    // Detect edit mode
    const [isEditMode, setIsEditMode] = useState(false);

    // Modal states
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalError, setModalError] = useState(false);

    // Direct user to a link from modal
    const history = useHistory();

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // Edit mode handling
    useEffect(() => {
        if (postToEdit) {
            setIsEditMode(true);
            setTitle(postToEdit.title);
            setExcerpt(postToEdit.excerpt);

            // Convert html prefill draft-js content
            const blocksFromHtml = htmlToDraft(postToEdit.content);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    }, []);

    // Rich text editor handling
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const Post = {
            username: user.username,
            title,
            excerpt,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            published,
        };

        if (!isEditMode) {
            try {
                await axiosInstance.post("/posts", Post);
                setModalError(false);
                setModalIsOpen(true);
                published
                    ? setModalMessage("Post was successfully published")
                    : setModalMessage("Post draft was created");
            } catch (error) {
                setModalError(false);
                setModalIsOpen(true);
                setModalMessage("There was an error publishing this post.");
                setModalError(true);
                console.log(error);
            }
        } else {
            // Update existing post
            try {
                await axiosInstance.put(`/posts/${postToEdit._id}`, Post);
                setModalError(false);
                setModalIsOpen(true);
                setModalMessage("Post was successfully updated");
            } catch (error) {
                setModalIsOpen(true);
                setModalMessage("There was an error updating this post.");
                setModalError(true);
                console.log(error);
            }
        }
    };

    // Modal handling
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleModalButton = () => {
        modalError ? closeModal() : history.push("/dashboard");
    };

    Modal.setAppElement("#root"); // Need to set this for screen readers to avoid errors in development

    return (
        <PostForm onSubmit={handleSubmit}>
            <SectionWrap>
                <SectionTitle>Article Content</SectionTitle>
                <FieldTitle>
                    Title<Span>Must be unique</Span>
                </FieldTitle>
                <Input big value={title} onChange={(e) => setTitle(e.target.value)} />
                <FieldTitle>
                    Excerpt<Span>Seen in list view only</Span>
                </FieldTitle>
                <Summary value={excerpt} onChange={(e) => setExcerpt(e.target.value)} />
                <FieldTitle>Main Content</FieldTitle>
                <div>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="rich-text-wrapper"
                        editorClassName="rich-text-editor"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={editorOptions}
                    />
                </div>
            </SectionWrap>
            <SubmitWrap>
                <Grid lg={3} gap="1rem">
                    <Button bright type="submit" onClick={(e) => setPublished(true)}>
                        {isEditMode ? "Update and Publish" : "Publish"}
                    </Button>
                    <Button primary type="submit" onClick={(e) => setPublished(false)}>
                        {isEditMode ? "Update and Save Draft" : "Save Draft"}
                    </Button>
                    <Button discard type="button" onClick={(e) => history.push("/dashboard")}>
                        {isEditMode ? "Cancel editing" : "Discard"}
                    </Button>
                </Grid>
            </SubmitWrap>
            {/* Modal after form submission or discard */}
            <Modal isOpen={modalIsOpen} style={modalStyles} closeTimeoutMS={300}>
                <ModalContentsWrap>
                    <ModalMessage>{modalMessage}</ModalMessage>
                    <Button primary onClick={handleModalButton} style={{ margin: "0 auto" }}>
                        {modalError ? "Close" : "Back to dashboard"}
                    </Button>
                </ModalContentsWrap>
            </Modal>
        </PostForm>
    );
}
