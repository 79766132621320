// Dependencies
import { useContext, useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Modal from "react-modal";

// Styles
import { Grid, Container, layoutWidth } from "../styles/Layout";
import { Button } from "../styles/Button";
import { mapStyles } from "../../assets/other/mapStyles";
import "../styles/ReactModal.css";
import { size } from "../styles/Breakpoints";
import { LoaderWrapper } from "../styles/Loader";

import logoDark from "../../assets/img/pp-logo-dark.svg";
// import logoBg from "../../assets/img/pp-logo-darkblue-bg.svg";
import { ReactComponent as LogoBg } from "../../assets/img/pp-logo-darkblue-bg.svg";

// Icons
import { ReactComponent as NationalRail } from "../../assets/img/transport-rail.svg";
import { ReactComponent as Underground } from "../../assets/img/transport-underground.svg";
import { ReactComponent as Overground } from "../../assets/img/transport-overground.svg";
import { ReactComponent as Tramlink } from "../../assets/img/transport-tramlink.svg";
import { ReactComponent as DLR } from "../../assets/img/transport-dlr.svg";
import { ReactComponent as Bus } from "../../assets/img/transport-bus.svg";
import { ReactComponent as River } from "../../assets/img/transport-river.svg";
import {
    HighlightOffRounded,
    OndemandVideo,
    ArrowForwardIos,
    EditRounded,
    FiberManualRecord,
} from "@material-ui/icons";
import { Context } from "../../context/Context";

import PhotoGallery from "./PhotoGallery";
import FloorplanModal from "./FloorplanModal";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    max-width: 1370px;
    margin: 0 auto;
    padding: 0 2rem;
    @media screen and (max-width: ${layoutWidth + 40}px) {
        padding: 0 0 0 2rem;
    }
    @media screen and (max-width: ${size.sm}px) {
        display: block;
        padding: 0 1rem;
    }
`;

const Title = styled.h3`
    margin-top: 0;
    margin-bottom: 0.4rem;
    letter-spacing: 0;
    color: ${(props) => props.theme.black};
`;

const Subtitle = styled.h4`
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
    color: ${(props) => props.theme.black};
`;

const TitleBar = styled.div`
    background: ${(props) => props.theme.lightblue};
    color: ${(props) => props.theme.darkblue};
    padding: 0.25rem 1rem;
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
`;

const FeatureWrapper = styled.ul`
    list-style: none;
    margin: 1rem 0 0 1rem;
    padding: 0;
    @media screen and (max-width: ${size.sm}px) {
        padding: 0 0 3rem 0;
    }
`;
const Feature = styled.li`
    line-height: 2rem;
    & svg {
        width: 1rem;
        margin-right: 0.5rem;
    }
    @media print {
        line-height: 1.5rem;
        font-weight: 300;
    }
`;

const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
    grid-column-gap: 1rem;
    position: absolute;
    bottom: 0;
    width: 90%;
    @media screen and (max-width: ${size.md}px) {
        & button {
            font-size: 0.8rem !important;
        }
    }
    @media print {
        display: none;
    }
`;

const DetailsWrapper = styled.div`
    background: #f3fbff;
    padding: 2rem;
    margin-top: 2rem;
`;

const DetailsTitle = styled.h3`
    margin: 0 1rem 0 0;
    letter-spacing: 0;
    color: ${(props) => props.theme.darkblue};
    font-weight: 400;
    cursor: ${(props) => (props.tab ? "pointer" : "auto")};
    text-decoration: ${(props) => props.selected && "underline"};
    transition: transform 0.3s ease;
    &:hover {
        transform: ${(props) => (props.tab ? "translate(0, -3px)" : "translate(0, 0)")};
        transition: transform 0.3s ease;
    }
`;

const FloorPlanImgWrap = styled.div`
    display: block;
    margin-top: 1rem;
    min-height: 20rem;
    @media screen and (max-width: ${size.md}px) {
        min-height: 20rem;
    }
`;

const FloorplanImg = styled.img`
    min-width: 40rem;
    max-width: 100%;
    @media screen and (max-width: ${size.md}px) {
        min-width: 100%;
    }
`;

const Description = styled.div`
    & p {
        font-size: 0.9rem;
    }
    & span {
        background-color: rgb(0 0 0 / 0%) !important;
    }
    @media print {
        & p {
            font-size: 0.75rem;
            line-height: 1rem;
            width: 60%;
        }
    }
`;

const DetailsContainer = styled(Container)`
    margin-bottom: 5rem;
    padding: 0;
    @media print {
        display: none;
    }
`;

const LeftPanel = styled.div`
    padding: 2rem 2rem 0 0;
    position: relative;
    @media print {
        display: none;
    }
`;

const RightPanel = styled.div`
    display: inherit;
    max-height: 27.25rem;
    @media screen and (max-width: ${size.sm}px) {
        height: 12rem;
        margin: 2rem 0;
    }
    @media print {
        display: none;
    }
`;

const MapsWrapper = styled.div`
    position: relative;
    height: 30rem;
`;

const LogoDark = styled.img`
    display: none;
    margin: 0 0 2rem 0;
    width: 70%;
`;

const ModalContent = styled.div`
    width: 25rem;
`;
const ModalTitle = styled.h3`
    margin-top: 0;
`;
const ModalInfo = styled.div``;
const ModalName = styled.h4`
    margin: 0;
`;
const ModalType = styled.div`
    margin: 0.5rem 0;
`;
const ModalPrice = styled.div`
    font-weight: 400;
    color: ${(props) => props.theme.darkblue};
`;
const ModalImgWrap = styled.div`
    height: 8rem;
`;
const ModalImg = styled.img`
    width: 100%;
`;
const ModalContact = styled.form``;
// const InputLabel = styled.label`
//     font-size: 0.8rem;
//     font-weight: 400;
// `;
const Input = styled.input`
    display: block;
    width: 100%;
    border-radius: 0;
    border: 1px solid #e7e7e7;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 0.5rem;
`;
const CheckboxLabel = styled.label`
    font-size: 0.7rem;
    font-weight: 400;
`;
const Checkbox = styled.input`
    margin-bottom: 1rem;
    transform: scale(1.2);
    margin-right: 0.5rem;
`;

const CloseBtn = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
    cursor: pointer;
    & svg {
        fill: ${(props) => props.theme.lightblue};
    }
`;

const VirtualTour = styled.div`
    & a {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.9rem;
        color: ${(props) => props.theme.darkblue};
        transition: 0.3s;
    }
    & a:hover {
        color: ${(props) => props.theme.green};
        transition: 0.3s;
    }
`;

const VirtualTourIcon = styled(OndemandVideo)`
    margin: 0 0.5rem -0.4rem 0;
`;

const PrintWrapper = styled.div`
    display: none;
    width: 100%;
    margin: 0 auto;
    @media print {
        display: block;
        height: 100%;
    }
`;

const PrintLogoWrapper = styled.div`
    margin-bottom: 0.5rem;
`;

const PrintPropertyDetailsWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
`;

const PrintPropertyLocation = styled.div`
    font-size: 1.25rem;
`;

const PrintPropertyPrice = styled.div`
    font-size: 1.25rem;
`;

const PrintPropertyTitle = styled.div`
    font-weight: 500;
    border-top: 1px solid ${(props) => props.theme.lightblue};
    padding: 0.5rem 0;
    font-size: 1.25rem;
`;

const PrintHeroImage = styled.img`
    /* width: 100%; */
    max-height: 28rem;
    margin-top: 0.5rem;
`;

const PrintRowImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
`;

const PrintRowImageWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    margin-top: 0.5rem;
    /* height: 15.5rem;
    overflow: hidden; */
`;

const PrintFeatures = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: 1rem 0 0 1rem;
    padding: 0;
    font-weight: 400;
    page-break-after: always;
`;

const PrintFeature = styled(Feature)`
    white-space: nowrap;
`;

const PrintFeatureArrow = styled(ArrowForwardIos)`
    margin-bottom: -0.33rem;
    color: ${(props) => props.theme.lightblue};
`;

const PrintFeaturesTitle = styled.div`
    margin-top: 1rem;
    color: ${(props) => props.theme.darkblue};
    border-bottom: 1px solid ${(props) => props.theme.lightblue};
    padding-bottom: 0.5rem;
    font-weight: 400;
`;

const PrintPropertyDetails = styled.div`
    /* page-break-after: always; */
    line-height: 1.4rem;
`;

const EditIcon = styled(EditRounded)`
    width: 1.25rem;
    margin-bottom: -0.25rem;
    margin-left: 0.5rem;
    color: #eeeeee;
    &:hover {
        color: ${(props) => props.theme.darkblue};
    }
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center;
`;

const mapContainerStyle = {
    width: "100%",
    height: "100%",
};

const SendLoader = styled(Loader)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SendButton = styled(Button)`
    pointer-events: ${(props) => (props.sending ? "none" : "auto")};
    background: ${(props) => props.sent && props.theme.grey};
`;

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
        border: "2px solid #a2def9",
        padding: "2rem",
    },
};

export default function SingleProperty() {
    const [property, setProperty] = useState({});
    const [propertyPrice, setPropertyPrice] = useState("");
    const [propertyPhotos, setPropertyPhotos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [happyToContact, setHappyToContact] = useState(false);
    const [buttonText, setButtonText] = useState("Send");
    const [isSending, setIsSending] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    const [propertyTab, setPropertyTab] = useState("Property Details");

    const location = useLocation();
    const path = location.pathname.split("/")[2];

    const { user } = useContext(Context);

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    useEffect(() => {
        const getProperty = async () => {
            const res = await axiosInstance.get(`/properties/${path}`);
            res ? setIsLoading(false) : setIsLoading(true);

            setProperty(res.data);

            // Check if there is a floorplan image and delete it from the array before setting the property photos.
            let filteredPhotos = res.data.photos;
            if (res.data.floorplanImage) {
                const index = filteredPhotos.indexOf(res.data.floorplanImage);
                if (index > -1) {
                    filteredPhotos.splice(index, 1);
                }
            }

            setPropertyPhotos(filteredPhotos);

            // Add commas to displayed price
            setPropertyPrice(res.data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        };
        getProperty();
    }, [path]);

    // MODAL & Contact form options & functions
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    Modal.setAppElement("#root"); // Need to set this for screen readers to avoid errors in development

    // MAP Options & functions
    const options = {
        styles: mapStyles,
        disableDefaultUI: true,
        zoomControl: true,
        scrollwheel: false,
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSending(true);

        let emailToMe = {
            fromEmail: "info@property-people.co.uk",
            toEmail: "info@property-people.co.uk",
            // fromEmail: "info@swcarpetcleaning.co.uk",
            // toEmail: "test@newwindowmarketing.com",
            subject: `${name} submitted a contact form at property-people.co.uk`,
            body: `<div style="font-size:18px; line-height:1.5;">
                <p>${name} submitted a property viewing request form at property-people.co.uk</p>
                Email: ${email} <br/>
                Phone: ${phone} <br/>
                Requested property: <a href="https://property-people.co.uk/properties/${
                    property._id
                }">${property.address}</a> <br/>
                <p> Happy to contact via phone or email: ${happyToContact ? "Yes" : "No"} </p>
                </div>
                <div style="font-size:14px; padding-top:12px">
                  <i>This form was sent using submitted a request viewing form at property-people.co.uk contact form.</i>
                </div>`,
        };

        axios.post(process.env.REACT_APP_EMAIL_SEND_API, emailToMe).then((res) => {
            setIsSending(false);
            setMessageSent(true);
            setButtonText("Request Sent");
        });
    };

    return (
        <>
            {isLoading ? (
                <LoaderWrapper>
                    <Loader type="Puff" color="#A2DEF9" height={60} width={60} />
                </LoaderWrapper>
            ) : (
                <>
                    {/* Viewable only for printers */}
                    <PrintWrapper>
                        <PrintLogoWrapper>
                            <LogoBg />
                        </PrintLogoWrapper>
                        <PrintPropertyDetailsWrap>
                            <PrintPropertyLocation>
                                {property.displayAddress}, {property.location}
                            </PrintPropertyLocation>
                            <PrintPropertyPrice>
                                £{propertyPrice}
                                {property.priceType}
                            </PrintPropertyPrice>
                        </PrintPropertyDetailsWrap>
                        <PrintPropertyTitle>
                            {property.bedrooms} Bedroom {property.type}
                        </PrintPropertyTitle>
                        <PrintHeroImage src={propertyPhotos[0]} alt="Property Image" />
                        <PrintRowImageWrap>
                            {propertyPhotos.slice(1, 4).map((image, index) => (
                                <PrintRowImage
                                    key={index}
                                    src={image}
                                    alt="Property Image"
                                    style={{ maxHeight: "9rem" }}
                                />
                            ))}
                        </PrintRowImageWrap>
                        <PrintFeaturesTitle>Key Features</PrintFeaturesTitle>
                        {property.features && (
                            <PrintFeatures>
                                {property.features.map((feature, i) => (
                                    <PrintFeature key={i}>
                                        {feature.icon === "Overground" && <Overground />}
                                        {feature.icon === "Underground" && <Underground />}
                                        {feature.icon === "NationalRail" && <NationalRail />}
                                        {feature.icon === "Bus" && <Bus />}
                                        {feature.icon === "Tramlink" && <Tramlink />}
                                        {feature.icon === "River" && <River />}
                                        {feature.icon === "DLR" && <DLR />}
                                        {feature.text !== "" && !feature.icon && (
                                            <PrintFeatureArrow />
                                        )}
                                        {feature.text}
                                    </PrintFeature>
                                ))}
                            </PrintFeatures>
                        )}
                        <PrintLogoWrapper>
                            <LogoBg />
                        </PrintLogoWrapper>
                        <PrintFeaturesTitle>Property Description</PrintFeaturesTitle>
                        <PrintPropertyDetails
                            dangerouslySetInnerHTML={{
                                __html: property.description,
                            }}
                        ></PrintPropertyDetails>
                        {propertyPhotos.slice(4).map((image, index) => (
                            <PrintRowImage key={index} src={image} alt="Property Image" />
                        ))}
                    </PrintWrapper>

                    <Wrapper>
                        <LeftPanel>
                            <LogoDark src={logoDark} />
                            <Title>
                                {property.displayAddress}, {property.location}
                                {user && (
                                    <Link to={`/dashboard/edit-property/${property._id}`}>
                                        <EditIcon />
                                    </Link>
                                )}
                            </Title>
                            <Subtitle>
                                {property.bedrooms} Bedroom {property.type}・£
                                {propertyPrice}
                                {property.priceType}
                            </Subtitle>
                            <TitleBar>Key Features</TitleBar>
                            {property.features && (
                                <FeatureWrapper>
                                    <>
                                        {property.features.map((feature, i) => (
                                            <Feature key={i}>
                                                {feature.icon === "Overground" && <Overground />}
                                                {feature.icon === "Underground" && <Underground />}
                                                {feature.icon === "NationalRail" && (
                                                    <NationalRail />
                                                )}
                                                {feature.icon === "Bus" && <Bus />}
                                                {feature.icon === "Tramlink" && <Tramlink />}
                                                {feature.icon === "River" && <River />}
                                                {feature.icon === "DLR" && <DLR />}
                                                {/* {feature.text !== "" && !feature.icon && <FeatureCheckIcon />} */}
                                                {feature.text}
                                            </Feature>
                                        ))}
                                    </>
                                </FeatureWrapper>
                            )}
                            <ButtonWrapper>
                                {(property.status === "Sold" || property.status === "Let") && (
                                    <Button disabled>This property is {property.status}</Button>
                                )}
                                {(property.status === "Available" ||
                                    property.status === "Under Offer") && (
                                    <Button bright onClick={openModal}>
                                        Request Viewing
                                    </Button>
                                )}
                                <Button primary onClick={() => window.print()}>
                                    Print Details
                                </Button>
                            </ButtonWrapper>
                        </LeftPanel>
                        <RightPanel>
                            <PhotoGallery photos={propertyPhotos} />
                        </RightPanel>
                    </Wrapper>
                    <DetailsContainer>
                        <DetailsWrapper>
                            <TitleRow>
                                <DetailsTitle>Property Details</DetailsTitle>
                                {property.floorplanImage && (
                                    <FloorplanModal img={property.floorplanImage}>
                                        Floorplan
                                    </FloorplanModal>
                                )}
                            </TitleRow>
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: property.description,
                                }}
                            ></Description>
                            {property.virtualTourLink && (
                                <VirtualTour>
                                    <a href={property.virtualTourLink} target="_blank">
                                        <VirtualTourIcon />
                                        Take a virtual tour of the property
                                    </a>
                                </VirtualTour>
                            )}
                        </DetailsWrapper>
                        {property.coordinates && (
                            <MapsWrapper>
                                <GoogleMap
                                    center={{
                                        lat: property.coordinates.lat,
                                        lng: property.coordinates.lng,
                                    }}
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={12}
                                    options={options}
                                >
                                    <Marker
                                        position={{
                                            lat: property.coordinates.lat,
                                            lng: property.coordinates.lng,
                                        }}
                                        icon={{
                                            url: "/images/pp-pin-letting.svg",
                                            scaledSize: new window.google.maps.Size(40, 40),
                                        }}
                                        key={property.displayAddress}
                                    ></Marker>
                                </GoogleMap>
                            </MapsWrapper>
                        )}
                    </DetailsContainer>
                </>
            )}

            {/* Request Viewing Modal Window */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={modalStyles}
                closeTimeoutMS={300}
            >
                <ModalContent>
                    <CloseBtn onClick={closeModal}>
                        <HighlightOffRounded />
                    </CloseBtn>
                    <ModalTitle>Request Viewing</ModalTitle>
                    <Grid custom="2fr 1fr" gap="1rem">
                        <ModalInfo>
                            <ModalName>
                                {property.displayAddress}, {property.location}
                            </ModalName>
                            <ModalType>
                                {property.bedrooms} Bedroom {property.type}
                            </ModalType>
                            <ModalPrice>
                                £{propertyPrice}
                                {property.priceType}
                            </ModalPrice>
                        </ModalInfo>
                        <ModalImgWrap>
                            <ModalImg src={propertyPhotos[0]} />
                        </ModalImgWrap>
                    </Grid>
                    <ModalContact onSubmit={handleSubmit}>
                        <Input
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        ></Input>
                        <Input
                            type="email"
                            placeholder="Email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        ></Input>
                        <Input
                            type="phone"
                            placeholder="Contact number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        ></Input>
                        <Checkbox
                            type="checkbox"
                            id="happy_contact"
                            value={happyToContact}
                            onChange={(e) => setHappyToContact(e.target.value)}
                        ></Checkbox>
                        <CheckboxLabel htmlFor="happy_contact">
                            Please confirm that you are happy for us to contact you via phone or
                            email.
                        </CheckboxLabel>
                        <SendButton primary sending={isSending || messageSent} sent={messageSent}>
                            {isSending ? (
                                <SendLoader type="Puff" color="white" height={19} width={19} />
                            ) : (
                                <>{buttonText}</>
                            )}
                        </SendButton>
                    </ModalContact>
                </ModalContent>
            </Modal>
        </>
    );
}
