// Currently available for backend test use only.
// Allows to create, edit, and delete users for admin.

import axios from "axios";
import { useState } from "react";
import styled from "styled-components";

const RegisterForm = styled.form``;
const Input = styled.input``;

export default function UserManagement() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [infotext, setInfotext] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:8001/api/auth/register", { username, email, password });
      setInfotext("User added");
    } catch (error) {
      setInfotext("User could not be added. Check console for error.");
      console.log(error);
    }
  };

  return (
    <>
      <h1>User management page</h1>
      Add a new user
      <RegisterForm onSubmit={handleSubmit}>
        <Input placeholder="username" onChange={(e) => setUsername(e.target.value)}></Input>
        <Input placeholder="email" onChange={(e) => setEmail(e.target.value)}></Input>
        <Input placeholder="password again" type="password" onChange={(e) => setPassword(e.target.value)}></Input>
        <button type="submit">Add</button>
        <div>{infotext}</div>
      </RegisterForm>
    </>
  );
}
