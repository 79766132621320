import { useLayoutEffect, useState } from "react";
import styled from "styled-components";

import NavBar from "../../components/fragments/NavBar";
import PropertyImageUpload from "../../components/fragments/PropertyImageUpload";
import CreatePropertyForm from "../../components/fragments/CreatePropertyForm";

import { Container } from "../../components/styles/Layout";

const PanelWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 1rem;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
`;

const Title = styled.h1`
    text-align: center;
    margin: 2rem;
`;

export default function CreateProperty() {
    // pass photos from Image upload component to the Form component
    const [photoArray, setPhotoArray] = useState([]);
    const [floorPlanImg, setFloorPlanImg] = useState("");

    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar location="admin" pageTitle="New Property" />
            <Container>
                <Title>New Property</Title>
                <PanelWrapper>
                    <CreatePropertyForm photoArray={photoArray} floorPlanImg={floorPlanImg} />
                    <PropertyImageUpload
                        setPhotoArray={setPhotoArray}
                        setFloorPlanImg={setFloorPlanImg}
                    />
                </PanelWrapper>
            </Container>
        </>
    );
}
