// Dependencies
import { useState, useEffect } from "react";
import styled from "styled-components";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import ProgressiveImage from "react-progressive-image";
import { Dropdown } from "semantic-ui-react";

// Child components
import ListProperty from "./ListProperty";
import PaginationDB from "./PaginationDB";

// Styles
import { Grid, Container, layoutWidth, layoutPadding } from "../styles/Layout";
import { Button } from "../styles/Button";
import { CheckboxWrap, CheckboxLabel, CheckboxInput, CheckboxBox } from "../styles/Checkbox";
import { RadioWrap, RadioLabel, RadioInput, RadioBox } from "../styles/RadioButton";
import { DropdownSelect, DropdownWrap, ArrowDown, Option } from "../styles/Dropdown";
import { mapStyles } from "../../assets/other/mapStyles";
import { Link, useHistory } from "react-router-dom";
import "../styles/semantic.min.css";
import { size } from "../styles/Breakpoints";

// Icons & Images
import { LaunchRounded, FirstPage } from "@material-ui/icons";
import Loader from "react-loader-spinner";

// Data
import { Locations } from "../../data/Locations";
import { priceRangeSales, priceRangeLettings } from "../../data/PriceRange";
import { bedroomRange } from "../../data/BedroomRange";
import axios from "axios";
import { LoaderWrapper } from "../styles/Loader";

const Wrapper = styled.section`
    background: white;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    z-index: -1;
`;

const FilterAndMapWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    background: white;
    box-sizing: inherit;
    @media screen and (max-width: ${size.sm}px) {
        display: block;
    }
`;

// FILTER PANEL
const FilterPanelForm = styled.form`
    background: white;
    height: 100%;
    min-height: 25rem;
    display: inherit;
    align-content: center;
`;

const FilterPanel = styled.div`
    padding: 2rem;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
`;

const Title = styled.h3`
    margin-top: 0;
`;

// Other styles

const Seperator = styled.hr`
    margin-top: 1rem;
    margin-bottom: 0;
`;

const margin = {
    marginTop: "1rem",
};

// Map styles

const MapPanel = styled.div`
    position: relative;
    display: block;
    @media screen and (max-width: ${size.sm}px) {
        height: 60vh;
    }
`;

const mapContainerStyle = {
    width: "100%",
    height: "100%",
};

const MarkerInfoWrap = styled.div`
    width: 10rem;
    max-height: 10rem;
    position: relative;
    overflow: hidden;
`;

const MarkerImg = styled.img`
    width: 100%;
    object-fit: fill;
`;

const MarkerTitle = styled.h3`
    margin-top: 0;
`;
const MarkerPrice = styled.p`
    margin: -0.75rem 0 0 0;
`;

const FilterTitle = styled.h1`
    text-align: center;
    color: ${(props) => props.theme.darkblue};
    margin: 0;
    @media screen and (max-width: ${size.sm}px) {
        margin: 0 0 1rem 0;
    }
`;

const StyledDropdown = styled(Dropdown)`
    margin-top: 1rem;
    padding: 0.75rem 1rem 0.75rem 1rem;
    width: 100%;
    & * {
        font-size: 0.9rem;
    }
    & i {
        display: none;
    }
`;

const NoPropertiesText = styled.p`
    text-align: center;
    margin-bottom: -1rem;
    padding: 4rem 0 7rem 0;
`;

const PropertiesListContainer = styled(Container)`
    padding: 2rem 2rem 4rem;
    box-sizing: content-box;
    @media screen and (max-width: ${size.sm}px) {
        padding: 0 0 4rem;
    }
`;

const SearchLoader = styled(Loader)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FilterTopRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-top: 3rem;
    border-bottom: 1px solid ${(props) => props.theme.lightblue};
    @media screen and (max-width: ${size.sm}px) {
        display: block;
    }
`;

const ReturnBtn = styled.div`
    padding: 0;
    align-self: center;
    font-size: 0.7rem;
    font-weight: 500;
    cursor: pointer;
    color: ${(props) => props.theme.darkblue};
    transition: 0.3s;
    &:hover {
        color: ${(props) => props.theme.lightblue};
        transition: 0.3s;
    }
    & svg {
        margin-bottom: -0.5rem;
        width: 1.25rem;
    }
`;

const markerLinkStyle = {
    margin: "-0.25rem 0 0.25rem 0",
    fontSize: ".7rem",
    fontWeight: "500",
    textDecoration: "underline",
};

// Dropdown search
const areaOptions = Locations.map((area, index) => ({
    key: index,
    text: area,
    value: area,
}));

// MAP Options & functions
const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
    scrollwheel: false,
};

export default function SearchFilter({ properties }) {
    // MAP STATES
    const [propertyType, setPropertyType] = useState(["Sales", "Lettings"]);
    const [lettingsCheck, setLettingsCheck] = useState(false);
    const [salesCheck, setSalesCheck] = useState(false);
    const [filterResultsCheck, setFilterResultsCheck] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [mapProperties, setMapProperties] = useState(properties);

    // Default to SW London
    const [currentMapCenter, setMapCenter] = useState({ lat: 51.4579163, lng: -0.1949644 });

    // FILTER STATES
    const [radioSelection, setRadioSelection] = useState("Lettings");
    const [locationDropdown, setLocationDropdown] = useState("Any Location");
    const [bedroomsDropdown, setBedroomsDropdown] = useState("Any Bedrooms");
    const [priceDropdown, setPriceDropdown] = useState("No Max Price");
    const [searched, setSearched] = useState(false);
    const [filterTitle, setFilterTitle] = useState("Recently Added Properties");
    const [isSearching, setIsSearching] = useState(false);
    const [showPropertyTypeDropdown, setShowPropertyTypeDropdown] = useState(true);

    // FILTER LIST STATES
    const [listedProperties, setListedProperties] = useState(properties);
    const [propertyListLoading, setPropertyListLoading] = useState(true);
    const [propertyTypeDropdown, setPropertyTypeDropdown] = useState("available");

    // DB pagination states for properties
    const [numberOfPropertyPages, setNumberOfPropertyPages] = useState(0);
    const [propertiesPageNumber, setPropertiesPageNumber] = useState(0);
    const [showPropertyPagination, setShowPropertyPagination] = useState(true);

    // Reset Filter
    const [resetFilter, setResetFilter] = useState(1);
    const history = useHistory();

    // Fill pagination array with the number of pages fetched from the DB
    const propertyPages = new Array(numberOfPropertyPages).fill(null).map((v, i) => i);

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // Get properties and paginate them
    const fetchProperties = async (propertyType, pageNumber) => {
        setPropertyTypeDropdown(propertyType);
        setPropertyListLoading(true);
        await axiosInstance.get(`/properties/${propertyType}?page=${pageNumber}`).then((res) => {
            setNumberOfPropertyPages(res.data.totalPages);
            setListedProperties(res.data.properties);
            setPropertyListLoading(false);
        });

        // Set correct title for the list
        switch (propertyType) {
            case "let":
                setFilterTitle("Let properties");
                break;
            case "sold":
                setFilterTitle("Sold properties");
                break;
            default:
                setFilterTitle("Recently Added Properties");
                break;
        }
    };

    useEffect(() => {
        switch (propertyTypeDropdown) {
            case "let":
                fetchProperties("let", propertiesPageNumber);
                break;
            case "sold":
                fetchProperties("sold", propertiesPageNumber);
                break;
            default:
                fetchProperties("available", propertiesPageNumber);
        }
    }, [propertiesPageNumber]);

    const handleLettingsCheckbox = () => {
        setLettingsCheck(!lettingsCheck);
        let newStateArray = propertyType.slice();

        if (lettingsCheck) {
            newStateArray.push("Lettings");

            // Remove Filter Results from array
            newStateArray = newStateArray.filter((val) => val !== "FilterResults");

            setPropertyType(newStateArray);
        } else {
            newStateArray = newStateArray.filter((val) => val !== "Lettings");
            setPropertyType(newStateArray);
        }

        // Remove filter results
        setFilterResultsCheck(false);
    };

    const handleSalesCheckbox = () => {
        setSalesCheck(!salesCheck);
        let newStateArray = propertyType.slice();

        if (salesCheck) {
            newStateArray.push("Sales");

            // Remove Filter Results from array
            newStateArray = newStateArray.filter((val) => val !== "FilterResults");

            setPropertyType(newStateArray);
        } else {
            newStateArray = newStateArray.filter((val) => val !== "Sales");
            setPropertyType(newStateArray);
        }

        // Remove filter results
        setFilterResultsCheck(false);
    };

    const handleFilterResultsCheckbox = () => {
        setFilterResultsCheck(!filterResultsCheck);

        if (!filterResultsCheck) {
            // Disable other filters
            setSalesCheck(true);
            setLettingsCheck(true);
            setPropertyType(["FilterResults"]);
        } else {
            // Enable other filters
            setSalesCheck(false);
            setLettingsCheck(false);
            setPropertyType(["Sales", "Lettings"]);
        }
    };

    // FILTER HANDLERS
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSearching(true);
        setPropertyTypeDropdown("available");

        let results = properties;

        // Check location selection (Any/Selected)
        if (locationDropdown !== "Any Location") {
            results = results.filter((select) => select.location === locationDropdown);
        }
        // else {
        //     setPropertyListLoading(true);
        //     await axiosInstance.get(`/properties/available`).then((res) => {
        //         setNumberOfPropertyPages(res.data.totalPages);
        //         results = res.data.properties; // properties sorted by date at the backend
        //         setPropertyListLoading(false);
        //     });
        // }

        // Check property type selection (rent/buy corresponds to letting/sale)
        results = results.filter((select) => select.category === radioSelection);

        // Check bedrooms selection (Any/Selected)
        if (bedroomsDropdown !== "Any Bedrooms") {
            results = results.filter((select) => select.bedrooms === parseInt(bedroomsDropdown));
        }

        // Check price selection (Any/Selected)
        if (priceDropdown !== "No Max Price") {
            results = results.filter((select) => select.price <= parseInt(priceDropdown));
        }

        // Sort results by date
        results = results.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
        setPropertyType(["FilterResults"]);
        setFilterResultsCheck(true);
        setSalesCheck(true);
        setLettingsCheck(true);
        setListedProperties(results);
        setSearched(true); // Display filter checkbox after search
        setShowPropertyPagination(false);
        setShowPropertyTypeDropdown(false);

        // Set filter title
        const whatType = radioSelection === "Sales" ? "Buy" : "Rent";
        setFilterTitle(`Properties to ${whatType} in ${locationDropdown}`);
        setTimeout(() => setIsSearching(false), 250); // Add a little spinner to the button to simulate search
    };

    const handleRadio = (e) => {
        setRadioSelection(e.target.value);
    };

    // Dropdown search handling
    const handleLocationDropdown = (event, { value }) => {
        setLocationDropdown(value);
    };

    const { value } = locationDropdown;

    const handleBedroomsDropdown = (e) => {
        setBedroomsDropdown(e.target.value);
    };

    const handlePriceDropdown = (e) => {
        setPriceDropdown(e.target.value);
    };

    const handlePageNumberClick = (e) => {
        document.getElementById("root").scrollTo({ top: 600, left: 0 });
        setPropertiesPageNumber(e.target.value);
    };

    const handlePropertyTypeDropdown = async (e) => {
        const selection = e.target.value;
        switch (selection) {
            case "let":
                fetchProperties("let", propertiesPageNumber);
                break;
            case "sold":
                fetchProperties("sold", propertiesPageNumber);
                break;
            default:
                fetchProperties("available", propertiesPageNumber);
        }
        setRadioSelection("Lettings");
        setLocationDropdown("Any Location");
        setBedroomsDropdown("Any Bedrooms");
        setPriceDropdown("No Max Price");
        // setSearched(false);
        setShowPropertyPagination(true);
        setPropertiesPageNumber(0);
    };

    const handleFilterReset = () => {
        history.push("/reset");
        setTimeout(() => {
            history.push("/");
        }, 0);
    };

    return (
        <Wrapper>
            <FilterAndMapWrapper>
                {/* FILTER PANEL */}
                <FilterPanelForm onSubmit={handleSubmit}>
                    <FilterPanel>
                        <Title>Find your dream property today!</Title>
                        <RadioWrap>
                            <RadioInput
                                type="radio"
                                id="lettings"
                                name="property_type"
                                value="Lettings"
                                checked={radioSelection === "Lettings"}
                                onChange={handleRadio}
                                hidden
                            />
                            <RadioLabel rent htmlFor="lettings">
                                <RadioBox />
                                RENT
                            </RadioLabel>
                            <RadioInput
                                type="radio"
                                id="sale"
                                name="property_type"
                                value="Sales"
                                checked={radioSelection === "Sales"}
                                onChange={handleRadio}
                                hidden
                            />
                            <RadioLabel buy htmlFor="sale">
                                <RadioBox />
                                BUY
                            </RadioLabel>
                        </RadioWrap>
                        <DropdownWrap style={{ boxSizing: "inherit" }}>
                            <StyledDropdown
                                onChange={handleLocationDropdown}
                                options={areaOptions}
                                value={value}
                                defaultValue={locationDropdown}
                                search
                                selection
                            />
                        </DropdownWrap>
                        <Grid lg={2} gap={"1rem"} style={margin}>
                            <DropdownWrap>
                                <ArrowDown />
                                <DropdownSelect onChange={handleBedroomsDropdown}>
                                    <Option>Any Bedrooms</Option>
                                    {bedroomRange.map((bed, index) => (
                                        <Option key={index} value={bed}>
                                            {bed === 0 ? "Studio" : `${bed} Bedrooms`}
                                        </Option>
                                    ))}
                                </DropdownSelect>
                            </DropdownWrap>
                            <DropdownWrap>
                                <ArrowDown />
                                <DropdownSelect onChange={handlePriceDropdown}>
                                    <Option>No Max Price</Option>
                                    {radioSelection === "Lettings" ? (
                                        <>
                                            {priceRangeLettings.map((price, index) => (
                                                <Option key={index} value={price}>
                                                    £
                                                    {price
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    pcm
                                                </Option>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {priceRangeSales.map((price, index) => (
                                                <Option key={index} value={price}>
                                                    £
                                                    {price
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </Option>
                                            ))}
                                        </>
                                    )}
                                </DropdownSelect>
                            </DropdownWrap>
                        </Grid>
                        <Button bright style={margin} type="submit">
                            {isSearching ? (
                                <SearchLoader type="Puff" color="white" height={19} width={19} />
                            ) : (
                                "Search"
                            )}
                        </Button>
                        <Seperator />
                        <a
                            href="https://property-people.instantvaluations.co.uk/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button primary style={margin} type="button">
                                Instant Valuation
                            </Button>
                        </a>
                    </FilterPanel>
                </FilterPanelForm>

                {/* MAP PANEL */}
                <MapPanel>
                    <GoogleMap
                        center={currentMapCenter}
                        mapContainerStyle={mapContainerStyle}
                        zoom={12}
                        options={options}
                    >
                        {propertyType.find((name) => name === "Sales") &&
                            mapProperties.map((property, index) => (
                                <div key={index}>
                                    {property.category === "Sales" && (
                                        <Marker
                                            position={{
                                                lat: property.coordinates.lat,
                                                lng: property.coordinates.lng,
                                            }}
                                            icon={{
                                                url: "/images/pp-pin-light.svg",
                                                scaledSize: new window.google.maps.Size(40, 40),

                                                // The below can be deleted if 100% accudate pin pos is desired
                                                origin: new window.google.maps.Point(0, 0),
                                                anchor: new window.google.maps.Point(20, 15),
                                            }}
                                            onClick={() => {
                                                setSelectedMarker(property);
                                            }}
                                        ></Marker>
                                    )}
                                </div>
                            ))}

                        {propertyType.find((name) => name === "Lettings") &&
                            mapProperties.map((property, index) => (
                                <div key={index}>
                                    {property.category === "Lettings" && (
                                        <Marker
                                            position={{
                                                lat: property.coordinates.lat,
                                                lng: property.coordinates.lng,
                                            }}
                                            icon={{
                                                url: "/images/pp-pin-letting.svg",
                                                scaledSize: new window.google.maps.Size(40, 40),

                                                // The below can be deleted if 100% accudate pin pos is desired
                                                origin: new window.google.maps.Point(0, 0),
                                                anchor: new window.google.maps.Point(20, 15),
                                            }}
                                            onClick={() => {
                                                setSelectedMarker(property);
                                            }}
                                        ></Marker>
                                    )}
                                </div>
                            ))}

                        {propertyType.find((name) => name === "FilterResults") &&
                            listedProperties.map((property, index) => (
                                <Marker
                                    position={{
                                        lat: property.coordinates.lat,
                                        lng: property.coordinates.lng,
                                    }}
                                    icon={{
                                        url: "/images/pp-pin-green.svg",
                                        scaledSize: new window.google.maps.Size(40, 40),

                                        // The below can be deleted if 100% accudate pin pos is desired
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: new window.google.maps.Point(20, 15),
                                    }}
                                    key={index}
                                    onClick={() => {
                                        setSelectedMarker(property);
                                    }}
                                ></Marker>
                            ))}

                        {selectedMarker && (
                            <InfoWindow
                                position={selectedMarker.coordinates}
                                onCloseClick={() => {
                                    setSelectedMarker(null);
                                }}
                            >
                                <MarkerInfoWrap>
                                    <MarkerTitle>
                                        {selectedMarker.bedrooms} Bedroom {selectedMarker.type}
                                    </MarkerTitle>
                                    <MarkerPrice>
                                        £
                                        {selectedMarker.price
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        {selectedMarker.priceType}
                                    </MarkerPrice>
                                    <Link
                                        to={`/property/${selectedMarker._id}`}
                                        style={markerLinkStyle}
                                    >
                                        View Property
                                    </Link>
                                    <Link
                                        to={`/property/${selectedMarker._id}`}
                                        target="_blank"
                                        style={markerLinkStyle}
                                    >
                                        <span style={{ marginLeft: ".5rem" }}>
                                            New Tab
                                            <LaunchRounded
                                                style={{
                                                    width: ".8rem",
                                                    position: "absolute",
                                                    margin: "-.3rem 0 0 .2rem",
                                                }}
                                            />
                                        </span>
                                    </Link>
                                    <ProgressiveImage
                                        src={selectedMarker.photos[0]}
                                        placeholder={"/images/marker-img-placeholder.jpg"}
                                    >
                                        {(src, loading) => (
                                            <MarkerImg
                                                src={src}
                                                style={{
                                                    filter: loading ? "blur(2px)" : "blur(0)",
                                                    transition: ".1s",
                                                    marginTop: ".25rem",
                                                }}
                                            />
                                        )}
                                    </ProgressiveImage>
                                </MarkerInfoWrap>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                    <CheckboxWrap>
                        <CheckboxInput
                            type="checkbox"
                            id="letting"
                            checked={!lettingsCheck}
                            onChange={handleLettingsCheckbox}
                            hidden
                        />
                        <CheckboxLabel lettings htmlFor="letting">
                            <CheckboxBox />
                            LETTINGS
                        </CheckboxLabel>

                        <CheckboxInput
                            type="checkbox"
                            id="sales"
                            checked={!salesCheck}
                            onChange={handleSalesCheckbox}
                            hidden
                        />
                        <CheckboxLabel sales htmlFor="sales">
                            <CheckboxBox />
                            SALES
                        </CheckboxLabel>

                        {searched && (
                            <>
                                <CheckboxInput
                                    filterResults
                                    type="checkbox"
                                    id="filterResults"
                                    checked={filterResultsCheck}
                                    onChange={handleFilterResultsCheckbox}
                                    hidden
                                />
                                <CheckboxLabel filterResults htmlFor="filterResults">
                                    <CheckboxBox />
                                    FILTER RESULTS
                                </CheckboxLabel>
                            </>
                        )}
                    </CheckboxWrap>
                </MapPanel>
            </FilterAndMapWrapper>

            {/* FILTER RESULTS PANEL */}
            <Container style={{ boxSizing: "content-box" }}>
                <FilterTopRow>
                    <FilterTitle>{filterTitle}</FilterTitle>
                    {showPropertyTypeDropdown ? (
                        <DropdownWrap narrow>
                            <ArrowDown narrow small />
                            <DropdownSelect small onChange={handlePropertyTypeDropdown}>
                                <Option value="available">Available</Option>
                                <Option value="let">Let</Option>
                                <Option value="sold">Sold</Option>
                            </DropdownSelect>
                        </DropdownWrap>
                    ) : (
                        <ReturnBtn onClick={handleFilterReset}>
                            <FirstPage />
                            Return to default results
                        </ReturnBtn>
                    )}
                </FilterTopRow>
            </Container>
            {propertyListLoading ? (
                <LoaderWrapper style={{ height: "80vh" }}>
                    <Loader type="Puff" color="#A2DEF9" height={60} width={60} />
                </LoaderWrapper>
            ) : (
                <PropertiesListContainer>
                    {listedProperties.map((property, index) => (
                        <div key={index}>
                            <ListProperty published property={property} />
                        </div>
                    ))}
                    {showPropertyPagination ? (
                        <PaginationDB
                            pages={propertyPages}
                            currentPage={propertiesPageNumber}
                            setPageNumber={handlePageNumberClick}
                        />
                    ) : null}
                    {listedProperties.length === 0 && (
                        <NoPropertiesText>
                            There were no properties found with your search criteria. Maybe try
                            expanding your search.
                        </NoPropertiesText>
                    )}
                </PropertiesListContainer>
            )}
        </Wrapper>
    );
}
