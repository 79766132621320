import { useLayoutEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.nav`
    display: block;
    text-align: center;
    margin: 2rem auto;
    padding-bottom: 2rem;
`;
const List = styled.ul`
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
`;
const Page = styled.li`
    margin: 0.25rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid ${(props) => props.theme.lightblue};
    cursor: pointer;
    background: ${(props) => (props.current ? props.theme.lightblue : "none")};
`;

export default function Pagination({ itemsPerPage, totalItems, paginate, currentPage }) {
    useLayoutEffect(() => {
        document.getElementById("root").scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [currentPage]);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    if (pageNumbers.length <= 1) {
        return null;
    }

    return (
        <Wrapper>
            <List>
                {pageNumbers.map((pageNumber) => (
                    <Page
                        key={pageNumber}
                        onClick={() => paginate(pageNumber)}
                        current={pageNumber === currentPage}
                    >
                        {pageNumber}
                    </Page>
                ))}
            </List>
        </Wrapper>
    );
}
