import styled from "styled-components";

export const RadioWrap = styled.div`
    display: flex;
    position: relative;
`;

export const RadioLabel = styled.label`
    display: grid;
    align-content: center;
    text-align: center;
    text-transform: uppercase;
    background: ${(props) => props.theme.lightgrey};
    color: ${(props) => (props.rent ? "white" : props.theme.darkblue)};
    padding: 0.5rem 0.75rem;
    min-width: 4rem;
    font-size: 0.7rem;
    font-weight: 500;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export const RadioInput = styled.input`
    &:checked + ${RadioLabel} {
        background: ${(props) => props.theme.darkblue};
        background: ${(props) => props.available && props.theme.green};
        background: ${(props) => props.let && props.theme.orange};
        background: ${(props) => props.underOffer && props.theme.violet};
        background: ${(props) => props.sold && props.theme.red};

        color: white;
    }
`;
