import { useRef, useState } from "react";
import { BurgerNavWrap, BurgerBtn } from "../styles/Navigation";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/img/pp-pin-light.svg";
import styled from "styled-components";

const Pin = styled(Logo)`
    width: 3rem;
    right: 4rem;
    position: absolute;
    top: 1rem;
`;

const SubLink = styled(Link)`
    font-size: 1rem !important;
`;

const Burger = ({ burgerOpen, setBurgerOpen }) => {
    return (
        <BurgerBtn burgerOpen={burgerOpen} onClick={() => setBurgerOpen(!burgerOpen)}>
            <div />
            <div />
            <div />
        </BurgerBtn>
    );
};

export default function BurgerMenu({ nav }) {
    const [burgerOpen, setBurgerOpen] = useState(false);
    const burgerNode = useRef();

    return (
        <div ref={burgerNode}>
            <Burger burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} />
            <BurgerNavWrap burgerOpen={burgerOpen}>
                <>
                    <Pin />
                    <Link to="/" style={{ marginTop: "1rem" }}>
                        Home
                    </Link>
                    {nav.map((item, index) => (
                        <div key={index}>
                            <Link to={item.link}>{item.title}</Link>
                            {item.submenu &&
                                item.submenu.map((subitem, subindex) => (
                                    <SubLink key={subindex} to={subitem.link}>
                                        {subitem.title}
                                        {subitem.icon}
                                    </SubLink>
                                ))}
                        </div>
                    ))}
                </>
            </BurgerNavWrap>
        </div>
    );
}
