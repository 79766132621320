import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router";
import axios from "axios";
import Loader from "react-loader-spinner";
import { LoaderWrapper } from "../../components/styles/Loader";
import { Button } from "../../components/styles/Button";
import { Container } from "../../components/styles/Layout";

const ArticleWrap = styled.div`
    margin: 3rem 0 1rem 0;
    max-width: 50rem;
    & div {
        line-height: 1.5rem;
    }
`;
const Title = styled.h2``;
const Body = styled.div``;

const BackButton = styled(Button)`
    max-width: 20rem;
    margin-top: 2rem;
`;

export default function SingleBlog() {
    const [post, setPost] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const location = useLocation();
    const path = location.pathname.split("/")[2];

    const history = useHistory();

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    useEffect(() => {
        const getPost = async () => {
            setIsLoading(true);
            const res = await axiosInstance.get(`/posts/${path}`);
            setPost(res.data);
            setIsLoading(false);
        };
        getPost();
    }, [path]);

    return (
        <>
            {isLoading ? (
                <LoaderWrapper>
                    <Loader type="Puff" color="#A2DEF9" height={60} width={60} />
                </LoaderWrapper>
            ) : (
                <Container style={{ marginBottom: "4rem" }}>
                    <ArticleWrap>
                        <Title>{post.title}</Title>
                        <Body>
                            <div dangerouslySetInnerHTML={{ __html: post.content }} />
                        </Body>
                    </ArticleWrap>

                    <BackButton primary onClick={(e) => history.push("/blog")}>
                        Back to Blog List
                    </BackButton>
                </Container>
            )}
        </>
    );
}
