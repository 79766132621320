import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../context/Context";
import { MenuList, MenuItem } from "../styles/Navigation";

const LogoutBtn = styled.a``;

export default function AdminMenu({ nav, currentPage }) {
    const { dispatch } = useContext(Context);
    const history = useHistory();

    const handleLogout = () => {
        dispatch({ type: "LOGOUT" });
        history.push("/");
    };

    return (
        <MenuList>
            {nav.map((item, index) => (
                <MenuItem admin key={index} style={{ background: item.title === currentPage && "#404040" }}>
                    <Link to={item.link}>
                        {item.icon}
                        {item.title}
                    </Link>
                </MenuItem>
            ))}
            <MenuItem admin onClick={handleLogout}>
                <LogoutBtn>Logout</LogoutBtn>
            </MenuItem>
        </MenuList>
    );
}
