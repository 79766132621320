import axios from "axios";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditTeamMember from "./EditTeamMember";

export default function ManageTeamMembers() {
    const [teamMembers, setTeamMembers] = useState([]);

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    useEffect(() => {
        const getTeamMembers = async () => {
            const res = await axiosInstance.get("/team");
            setTeamMembers(res.data);
            console.log(res.data);
        };
        getTeamMembers();
    }, []);

    const handleDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(teamMembers);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setTeamMembers(items);
        console.log(items);

        items.forEach((item, index) => {
            axiosInstance.put(`/team/${item._id}`, {
                order: index,
            });
        });
    };

    return (
        <>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="teamMembers">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {teamMembers.map((teamMember, index) => (
                                <Draggable
                                    key={teamMember._id}
                                    draggableId={teamMember._id}
                                    index={index}
                                >
                                    {(provided) => (
                                        <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <EditTeamMember teamMember={teamMember} />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}
