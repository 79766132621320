import { useLayoutEffect } from "react";
import styled from "styled-components";
import Footer from "../../components/fragments/Footer";
import NavBar from "../../components/fragments/NavBar";
import HeroBanner from "../../components/fragments/HeroBanner";
import { size } from "../../components/styles/Breakpoints";

import { Container } from "../../components/styles/Layout";

const Wrapper = styled.section`
	margin-bottom: 4rem;
`;
const BigTitle = styled.h2``;
const TinyTitle = styled.h4``;
const Text = styled.p`
	max-width: 40rem;
`;
const ListWrap = styled.ul``;
const ListItem = styled.li`
	font-size: 0.9rem;
	line-height: 1.5;
	font-weight: 300;
`;

export default function PrivacyPolicy() {
	useLayoutEffect(() => {
		document.getElementById("root").scrollTo(0, 0);
	}, []);

	return (
		<>
			<NavBar location="public" pageTitle="Privacy Policy" />
			<HeroBanner
				title="Privacy Policy"
				background="/images/pp-outside-dark.jpg"
				showRating={false}
			/>
			<Wrapper>
				<Container>
					<Text>
						We respect your privacy, and want to be clear about how we handle information about you.
					</Text>
					<TinyTitle>What information may we collect about you?</TinyTitle>
					<Text>
						We may collect personal data about you in the course of our work. That may include
						things like:
					</Text>
					<ListWrap>
						<ListItem>Your contact details.</ListItem>
						<ListItem>Your date of birth.</ListItem>
						<ListItem>Where you work.</ListItem>
						<ListItem>Your wishes and criteria in any transaction you are considering.</ListItem>
						<ListItem>
							Financial information, for example about your income and capital resources.
						</ListItem>
					</ListWrap>
					<Text>
						Usually we will have received this directly from you. However, we may also receive
						personal data about you from others, such as from the other party to a transaction you
						are engaged in or from joint agents. Family members also sometimes provide information
						about each other.
					</Text>
					<TinyTitle>How do we use your information?</TinyTitle>
					<Text>Our use of such personal data is subject to data protection law.</Text>
					<Text>
						We mainly use your personal data to provide you with information or services you have
						requested. We may also use it for any other purpose for which you give your consent. For
						example we may send you additional information about the firm or its services, if you
						have consented to us doing so.
					</Text>
					<Text>
						We may also use it for other normal purposes connected with our work. For example we
						will use your information to update our own business records, complete statutory
						returns, and otherwise comply with our regulatory obligations.
					</Text>
					<TinyTitle>Will we share information about you with anyone?</TinyTitle>
					<Text>
						We take your privacy seriously. We will never sell your personal data to anyone, and we
						take precautions to keep it secure.
					</Text>
					<Text>
						It will sometimes be a normal and necessary part of our work to pass on information to
						third parties. For example:
					</Text>
					<ListWrap>
						<ListItem>
							We may pass on information about you to solicitors to help them proceed with a
							transaction in which you are involved.
						</ListItem>
						<ListItem>
							If you are a prospective tenant, we may pass on references about you to the landlord.
						</ListItem>
						<ListItem>
							We may inform utility companies of changes in the occupiers of property.
						</ListItem>
						<ListItem>
							We may pass on information to maintenance and repair contractors, in respect of
							property where we have responsibilities.
						</ListItem>
						<ListItem>
							We may need to pass on information to those who help collect outstanding accounts.
						</ListItem>
					</ListWrap>
					<Text>
						In addition our practice may be audited or checked by third parties such as our
						accountants, which may enable them to see some information about you. Such third parties
						are required to maintain confidentiality in relation to your information.
					</Text>
					<TinyTitle>Your rights</TinyTitle>
					<Text>
						You have a right of access under data protection law to the personal data that we hold
						about you. We seek to keep that personal data correct and up to date. You should let us
						know if you believe the information we hold about you needs to be corrected or updated.
					</Text>
					<Text>
						The person responsible for data protection at this organisation and whom you should
						contact is Asad Jamil – 204 Tooting High Street SW170DG,
						<a href="mailto:asad@property-people.co.uk"> asad@property-people.co.uk</a>
					</Text>
					<Text>Last reviewed August 2023</Text>
				</Container>
			</Wrapper>
			<Footer />
		</>
	);
}
