import Loader from "react-loader-spinner";

import NavBar from "../../components/fragments/NavBar";
import HeroBanner from "../../components/fragments/HeroBanner";
import Footer from "../../components/fragments/Footer";

import background from "../../assets/img/temp-background.jpg";
import video from "../../assets/video/pp-video-bg.mp4";
import { LoaderWrapper } from "../../components/styles/Loader";

export default function Reset() {
    return (
        <>
            <NavBar location="public" />

            <HeroBanner
                title="We are Property People!"
                subtitle="Local independent Property Professionals serving South West London. Whether you are buying or selling, letting or renting, we have the necessary skills and all the tools that are required to find you a home or market your property. Call us for a no obligation appraisal of your home or register your interest in one of our properties."
                video={video}
                background={background}
                showRating={true}
            />

            <LoaderWrapper>
                <Loader type="Puff" color="#A2DEF9" height={60} width={60} />
            </LoaderWrapper>

            <Footer />
        </>
    );
}
