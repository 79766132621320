import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../../components/fragments/Footer";
import NavBar from "../../components/fragments/NavBar";
import HeroBanner from "../../components/fragments/HeroBanner";

import { Container } from "../../components/styles/Layout";
import TeamMemberCard from "../../components/fragments/TeamMemberCard";

import { size } from "../../components/styles/Breakpoints";

const Wrapper = styled.section`
  margin-bottom: 4rem;
`;

const BigTitle = styled.h2``;

const MemberContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 2rem;
  @media screen and (max-width: ${size.sm}px) {
    display: block;
  }
`;

export default function MeetTheTeam() {
  const [teamMembers, setTeamMembers] = useState([]);

  // Configure axios proxy
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    const getTeamMembers = async () => {
      try {
        const res = await axiosInstance.get("/team");
        setTeamMembers(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    getTeamMembers();
  }, []);

  useLayoutEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar location="public" pageTitle="Meet The Team" />
      <HeroBanner
        title="Meet the team"
        subtitle="Welcome to our Estate Agency and Property Management team! We are dedicated professionals with a passion for real estate, committed to providing exceptional service to our clients. Get to know the faces behind the scenes who make your property journey smooth and successful."
        background="/images/pp-outside-dark.jpg"
        showRating={false}
      />
      <Wrapper>
        <Container>
          <BigTitle>Introducing our superstar team</BigTitle>
          <MemberContainer>
            {teamMembers.map((member, index) => (
              <TeamMemberCard
                name={member.name}
                position={member.position}
                image={member.image}
                info={member.info}
                quote={member.quote}
              />
            ))}
          </MemberContainer>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}
