import { useContext, useRef } from "react";
import { useHistory } from "react-router";
import { Context } from "../../context/Context";
import axios from "axios";
import styled from "styled-components";
import { Container } from "../../components/styles/Layout";
import { Button } from "../../components/styles/Button";

import { ReactComponent as LogoDark } from "../../assets/img/pp-logo-dark.svg";
import { Link } from "react-router-dom";

const Logo = styled(LogoDark)`
    width: 100%;
    margin: 2rem auto;
`;

const LoginForm = styled.form``;
const Input = styled.input`
    display: block;
    padding: 0.25rem;
    margin-bottom: 0.75rem;
    font-size: 1rem;
    width: 100%;
`;
const Wrapper = styled.div`
    max-width: 15rem;
    margin: 0 auto;
`;

export default function Login() {
    const { isFetching, dispatch } = useContext(Context);

    const userRef = useRef();
    const passwordRef = useRef();
    const history = useHistory();

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch({ type: "LOGIN_START" });
        try {
            const res = await axiosInstance.post("/auth/login", {
                username: userRef.current.value,
                password: passwordRef.current.value,
            });
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
            history.push("/dashboard");
        } catch (error) {
            dispatch({ type: "LOGIN_FAILED" });
        }
    };

    return (
        <Container>
            <Wrapper>
                <Link to="/">
                    <Logo />
                </Link>
                <LoginForm onSubmit={handleSubmit}>
                    <Input placeholder="Username" ref={userRef}></Input>
                    <Input placeholder="Password" type="password" ref={passwordRef}></Input>
                    <Button primary type="submit">
                        {isFetching ? "Logging you in..." : "Login"}
                    </Button>
                </LoginForm>
            </Wrapper>
        </Container>
    );
}
