import styled from "styled-components";
import { Container } from "../styles/Layout";
import { Star } from "@material-ui/icons";
import { size } from "../styles/Breakpoints";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";

const Section = styled.section`
	background: url("${(props) => props.background}");
`;

const Video = styled.video`
	position: fixed;
	z-index: -1;
	left: 50%;
	top: 35%;
	transform: translate(-50%, -50%);
	filter: brightness(0.3);
	@media (min-aspect-ratio: 16/9) {
		width: 100%;
		height: auto;
	}
	@media screen and (max-width: ${size.sm}px) {
		top: 25%;
	}
`;

const InfoWrap = styled.div`
	position: relative;
	padding: 3rem 0;
	z-index: 0;
`;

const Paragraph = styled.p`
	color: white;
	width: 70%;
	@media screen and (max-width: ${size.sm}px) {
		width: 100%;
	}
`;

const Title = styled.h1`
	color: white;
	margin-top: 0;
`;

const Reviews = styled.div`
	color: white;
	display: flex;
	align-items: center;
	font-weight: 500;
	@media screen and (max-width: ${size.sm}px) {
		font-size: 0.75rem;
	}
`;

const StarWrap = styled.span`
	margin: 0 0.5rem;
	color: ${(props) => props.theme.star};
	padding-top: 0.15rem;
	& svg {
		font-size: 1.3rem;
		padding: 0.1rem;
	}
	@media screen and (max-width: ${size.sm}px) {
		& svg {
			font-size: 0.8rem;
			padding: 0;
		}
	}
`;

const NoMob = styled.span`
	padding: 0 0.3rem;
	@media screen and (max-width: ${size.sm}px) {
		display: none;
	}
`;

const ReviewLoader = styled(Loader)`
	margin-left: 0.4rem;
`;

export default function HeroBanner({ title, subtitle, video, background, showRating }) {
	const [reviews, setReviews] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		// Fetch reviews data
		axios.get(process.env.REACT_APP_PROXY_API).then((res) => {
			setReviews(res.data.result);
			setIsLoading(false);
		});
	}, []);

	return (
		<Section background={video ? "none" : background}>
			<Container>
				<InfoWrap>
					<Title>{title}</Title>
					<Paragraph>{subtitle}</Paragraph>
					{showRating && (
						<Reviews>
							Excellent
							<StarWrap>
								<Star />
								<Star />
								<Star />
								<Star />
								<Star />
							</StarWrap>
							rating based on 34
							{/* {isLoading ? (
                                <ReviewLoader type="Puff" color="white" height={15} width={15} />
                            ) : (
                                ` ${reviews.user_ratings_total}`
                            )} */}
							<NoMob> local </NoMob> reviews
						</Reviews>
					)}
				</InfoWrap>
			</Container>
			{video && (
				<Video poster={background} autoPlay muted loop>
					<source src={video} type="video/mp4" />
				</Video>
			)}
		</Section>
	);
}
