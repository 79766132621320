import styled from "styled-components";
import { size } from "./Breakpoints";

export const RadioWrap = styled.div`
    display: flex;
    position: relative;
`;

export const RadioLabel = styled.label`
    background: ${(props) => (props.rent ? props.theme.darkblue : props.theme.lightblue)};
    color: ${(props) => (props.rent ? "white" : props.theme.darkblue)};
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    min-width: ${(props) => (props.small ? "4rem" : "6rem")};
    display: inline-flex;
    flex: 1;
    font-size: 0.9rem;
    font-weight: 400;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media screen and (max-width: ${size.sm}px) {
        min-width: 3rem;
    }
`;

export const RadioInput = styled.input`
    &:checked + ${RadioLabel}:before {
        content: "\x5c\x32\x35\x43\x46";
        position: absolute;
        margin: -0.64rem 0 0 -1.46rem;
        font-size: 1.75rem;
        color: transparent;
        text-shadow: 0 0 0 ${(props) => props.theme.darkblue};
        z-index: 1;
    }
`;

export const RadioBox = styled.div`
    position: absolute;
    background: white;
    width: 1.1rem;
    height: 1.1rem;
    margin: -1px 0 0 -1.5rem;
    z-index: 0;
    border-radius: 50%;
`;
