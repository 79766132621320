import styled from "styled-components";

export const DropdownSelect = styled.select`
    border: 1px solid #dbdbdb;
    width: 100%;
    min-width: 6rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    font-size: ${(props) => (props.small ? "0.8rem" : "0.9rem")};
    /* padding: 0.75rem 1rem 0.75rem 2.5rem; */
    padding: 0.56rem 0.5rem;
    border-radius: 5px;
    padding: ${(props) => (props.small ? ".56rem .5rem .56rem 1.75rem;" : "0.75rem 1rem 0.75rem 2.5rem")};
    font-family: "Roboto";
    font-weight: ${(props) => (props.small ? "400" : "300")};
    &:hover {
        border-color: #b5b5b5;
    }
    &:focus {
        border-color: ${(props) => props.theme.lightblue};
        outline: ${(props) => props.theme.lightblue};
    }
`;

export const DropdownWrap = styled.div`
    position: relative;
    width: ${(props) => (props.narrow ? "auto" : "100%")};
`;

export const ArrowDown = styled.div`
    position: absolute;
    width: ${(props) => (props.small ? "0.5rem" : "0.7rem")};
    height: ${(props) => (props.small ? "0.5rem" : "0.7rem")};
    background: ${(props) => props.theme.darkblue};
    clip-path: polygon(50% 64%, 0 0, 100% 0);
    margin: 1rem 0 0 1rem;
    margin: ${(props) => props.small && ".9rem 0 0 .6rem"};
    margin: ${(props) => props.narrow && ".9rem 0 0 .85rem"};
`;

export const Option = styled.option``;
