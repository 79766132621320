import { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import Loader from "react-loader-spinner";
import { LoaderWrapper } from "../../components/styles/Loader";
import Footer from "../../components/fragments/Footer";
import NavBar from "../../components/fragments/NavBar";
import { Container, Grid } from "../../components/styles/Layout";
import ListPost from "../../components/fragments/ListPost";
import Pagination from "../../components/fragments/Pagination";
import { size } from "../../components/styles/Breakpoints";

const PanelWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    margin-top: 3rem;
    box-sizing: inherit;
    @media screen and (max-width: ${size.sm}px) {
        display: block;
    }
`;

const LeftPanel = styled.ul`
    @media screen and (max-width: ${size.sm}px) {
        display: none;
    }
`;
const RightPanel = styled.div``;

const ListTitle = styled.h4`
    color: ${(props) => props.theme.darkblue};
`;

const List = styled.li`
    list-style: none;
    font-size: 0.8rem;
    line-height: 1.25rem;
    padding-bottom: 1rem;
    transition: 0.3s;
    &:hover {
        color: ${(props) => props.theme.green};
        transition: 0.3s;
    }
`;

export default function Blog() {
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    useEffect(() => {
        const fetchPosts = async () => {
            await axiosInstance.get("/posts?published=true").then((res) => {
                setIsLoading(true);
                const results = res.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
                setPosts(results);
                setIsLoading(false);
            });
        };
        fetchPosts();
    }, []);

    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, [currentPage]);

    // Pagination control
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const postsToDisplay = posts.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <NavBar location="public" pageTitle="Blog" />
            {isLoading ? (
                <LoaderWrapper>
                    <Loader type="Puff" color="#A2DEF9" height={60} width={60} />
                </LoaderWrapper>
            ) : (
                <Container>
                    <PanelWrap>
                        <LeftPanel>
                            <ListTitle>Latest Articles</ListTitle>
                            {posts.map((post, index) => (
                                <List key={index}>
                                    <Link to={`/blog/${post._id}`}>{post.title}</Link>
                                </List>
                            ))}
                        </LeftPanel>
                        <RightPanel>
                            {postsToDisplay.map((post) => (
                                <ListPost published post={post} key={post._id} />
                            ))}
                            <Pagination
                                itemsPerPage={postsPerPage}
                                totalItems={posts.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        </RightPanel>
                    </PanelWrap>
                </Container>
            )}

            <Footer />
        </>
    );
}
