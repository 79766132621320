import { useLayoutEffect } from "react";
import Footer from "../../components/fragments/Footer";
import NavBar from "../../components/fragments/NavBar";
import SingleBlog from "../../components/fragments/SingleBlog";

export default function BlogPost() {
    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar location="public" pageTitle="Blog" />
            <SingleBlog />
            <Footer />
        </>
    );
}
