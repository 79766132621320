// Lamadev's Single.jsx file
import { useLayoutEffect } from "react";

import SingleProperty from "../../components/fragments/SingleProperty";
import NavBar from "../../components/fragments/NavBar";
import Footer from "../../components/fragments/Footer";

export default function Property() {
    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar location="public" />
            <SingleProperty />
            <Footer />
        </>
    );
}
