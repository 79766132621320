import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { FooterNav } from "../../data/Nav";

import { Container } from "../styles/Layout";
import { size } from "../styles/Breakpoints";

import GoogleReviews from "./GoogleReviews";

import { ReactComponent as LogoLight } from "../../assets/img/pp-logo-light.svg";
import { LaunchRounded, PictureAsPdf, Facebook, LinkedIn, Twitter } from "@material-ui/icons";

const Wrapper = styled.footer`
	background: white;
	margin-top: -0.5rem;
	border-top: 2px solid ${(props) => props.theme.darkblue};
	@media print {
		display: none;
	}
`;
const LowerSectionWrap = styled.div`
	background: ${(props) => props.theme.darkgradient};
`;
const LowerSection = styled.div`
	display: grid;
	grid-template-columns: 3.25fr 1fr 2fr 1fr;
	min-height: 13rem;
	color: white;
	@media screen and (max-width: ${size.sm}px) {
		display: block;
		height: 100%;
		padding: 1rem;
	}
`;
const AddressCol = styled.div`
	margin-top: 2rem;
`;
const Address = styled.div`
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1.5rem;
`;
const Copyright = styled.div`
	font-size: 0.8rem;
	margin-bottom: 4rem;
	color: ${(props) => props.theme.lightblue};
`;
const MenuCol = styled.ul`
	list-style: none;
	padding: 0;
	margin-top: 2rem;
	@media screen and (max-width: ${size.sm}px) {
		display: inline-block;
		margin-right: 2rem;
	}
`;
const MenuTitle = styled.li`
	line-height: 1.5rem;
	font-size: 0.8rem;
	font-weight: 500;
	&:hover {
		color: ${(props) => props.theme.lightblue};
		transition: 0.3s;
	}
`;
const MenuItem = styled.li`
	line-height: 1.5rem;
	font-size: 0.8rem;
	& svg {
		width: 0.8rem;
		margin-bottom: -0.5rem;
		margin-left: 0.5rem;
		opacity: 0.5;
	}
	&:hover {
		color: ${(props) => props.theme.lightblue};
		transition: 0.3s;
	}
`;
const SocialIconsCol = styled.div`
	margin-top: 2rem;
	text-align: right;
	& svg {
		margin-right: 0.5rem;
		width: 2rem;
	}
	& svg:hover {
		color: ${(props) => props.theme.lightblue};
		transition: 0.3s;
	}
	@media screen and (max-width: ${size.sm}px) {
		text-align: left;
	}
`;
const Logo = styled(LogoLight)`
	width: 70%;
	margin-bottom: 1rem;
`;

const FooterLogosWrap = styled.div`
	display: flex;
	column-gap: 1rem;
	width: 90%;
	margin-top: 2rem;

	& a {
		align-self: flex-end;
	}
`;
const FooterLogo = styled.img`
	width: 100%;
`;

export default function Footer() {
	return (
		<Wrapper>
			{/* <GoogleReviews /> */}
			<LowerSectionWrap>
				<Container>
					<LowerSection>
						<AddressCol>
							<Logo />
							<Address>T: 020 8946 7171 Fax: 020 7183 9946</Address>
							<Address>E: info@property-people.co.uk</Address>
							<Address>204 Tooting High Street, London, SW17 0SG</Address>
						</AddressCol>
						{FooterNav.map((item, index) => (
							<MenuCol key={index}>
								<MenuTitle>
									<Link to={item.link}>{item.title}</Link>
								</MenuTitle>
								{item.submenu &&
									item.submenu.map((subitem, subindex) => (
										<MenuItem key={subindex}>
											{/* Check if it's an external or internal link first */}
											{/^https?:\/\//.test(subitem.link) ? (
												<a href={subitem.link} target={subitem.target}>
													{subitem.title}
													{subitem.title === "Lettings" && <PictureAsPdf />}
													{subitem.title === "Sales" && <PictureAsPdf />}
													{subitem.title === "Offers" && <PictureAsPdf />}
													{subitem.title === "Refurbishment" && <LaunchRounded />}
													{subitem.title === "Cleaning" && <LaunchRounded />}
												</a>
											) : (
												<Link to={subitem.link} target={subitem.target}>
													{subitem.title}
													{subitem.title === "Lettings" && <PictureAsPdf />}
													{subitem.title === "Sales" && <PictureAsPdf />}
													{subitem.title === "Offers" && <PictureAsPdf />}
													{subitem.title === "Refurbishment" && <LaunchRounded />}
													{subitem.title === "Cleaning" && <LaunchRounded />}
												</Link>
											)}
										</MenuItem>
									))}
							</MenuCol>
						))}
						<SocialIconsCol>
							<a href="https://twitter.com/Property_People" target="_blank" rel="noreferrer">
								<Twitter />
							</a>
							<a
								href="https://www.facebook.com/Property.People.London"
								target="_blank"
								rel="noreferrer"
							>
								<Facebook />
							</a>
							<a
								href="https://www.linkedin.com/company/property-people-london"
								target="_blank"
								rel="noreferrer"
							>
								<LinkedIn />
							</a>
						</SocialIconsCol>
						<Copyright>
							© {new Date().getFullYear()} Property People London Ltd.
							<br /> Property People is a trading name of Property People London Ltd (Reg No:
							07355433)
							<FooterLogosWrap>
								<a
									href="/legal/Ombudsman_Certificate D5699_7232.pdf"
									target="_blank"
									rel="noreferrer"
								>
									<FooterLogo src="/images/ombudsman-logo.png" />
								</a>
								<a href="https://www.tradingstandards.uk/" target="_blank" rel="noreferrer">
									<FooterLogo src="/images/trading-standards-logo.png" />
								</a>
								<a href="https://www.propertymark.co.uk/" target="_blank" rel="noreferrer">
									<FooterLogo src="/images/naea-logo.png" />
								</a>
								<a href="https://www.propertymark.co.uk/" target="_blank" rel="noreferrer">
									<FooterLogo src="/images/arla-logo.png" />
								</a>
							</FooterLogosWrap>
						</Copyright>
					</LowerSection>
				</Container>
			</LowerSectionWrap>
		</Wrapper>
	);
}
