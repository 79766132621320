// Dependencies
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useContext } from "react";
import { Context } from "./context/Context";

// Public Pages
import Home from "./pages/public/Home";
import Property from "./pages/public/Property";
import Login from "./pages/public/Login";
import AboutUs from "./pages/public/AboutUs";
import Pricelist from "./pages/public/Pricelist";
import Blog from "./pages/public/Blog";
import BlogPost from "./pages/public/BlogPost";
import Contact from "./pages/public/Contact";
import Testimonials from "./pages/public/Testimonials";
import MeetTheTeam from "./pages/public/MeetTheTeam";
import Reset from "./pages/public/Reset";
import PrivacyPolicy from "./pages/public/PrivacyPolicy";

// Admin Pages
import Dashboard from "./pages/admin/Dashboard";
import CreateProperty from "./pages/admin/CreateProperty";
import EditProperty from "./pages/admin/EditProperty";
import CreatePost from "./pages/admin/CreatePost";
import EditPost from "./pages/admin/EditPost";
import CreateTeamMember from "./pages/admin/CreateTeamMember";
import UserManagement from "./pages/admin/UserManagement";

function App() {
    const { user } = useContext(Context);

    return (
        <Router>
            <Switch>
                {/* Public routes */}
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/privacy-policy">
                    <PrivacyPolicy />
                </Route>
                <Route exact path="/reset">
                    <Reset />
                </Route>
                <Route exact path="/pricelist">
                    <Pricelist />
                </Route>
                <Route exact path="/about-us">
                    <AboutUs />
                </Route>
                <Route exact path="/blog">
                    <Blog />
                </Route>
                <Route exact path="/blog/:id">
                    <BlogPost />
                </Route>
                <Route exact path="/contact">
                    <Contact />
                </Route>
                <Route exact path="/testimonials">
                    <Testimonials />
                </Route>
                <Route exact path="/meet-the-team">
                    <MeetTheTeam />
                </Route>
                <Route exact path="/property/:id">
                    <Property />
                </Route>
                <Route path="/login">
                    <Login />
                </Route>

                {/* Admin routes */}

                <Route exact path="/dashboard">
                    {user ? <Dashboard /> : <Login />}
                </Route>
                <Route path="/dashboard/create-property">
                    {user ? <CreateProperty /> : <Login />}
                </Route>
                <Route path="/dashboard/edit-property/:id">
                    {user ? <EditProperty /> : <Login />}
                </Route>
                <Route path="/dashboard/create-post">{user ? <CreatePost /> : <Login />}</Route>
                <Route path="/dashboard/edit-post/:id">{user ? <EditPost /> : <Login />}</Route>
                <Route path="/dashboard/user-management">
                    {user ? <UserManagement /> : <Login />}
                </Route>
                <Route path="/dashboard/create-team-member">
                    {user ? <CreateTeamMember /> : <Login />}
                </Route>

                {/* Old link redirects */}

                <Route exact path="/properties/613">
                    <Redirect to="/property/6112553e23c0e33f609f63b9" />
                </Route>
                <Route exact path="/properties/612">
                    <Redirect to="/property/6113d7ec8cba988e175312ae" />
                </Route>
                <Route exact path="/properties/614">
                    <Redirect to="/property/611254ec23c0e33f609f63b5" />
                </Route>
                <Route exact path="/properties/615">
                    <Redirect to="/property/6112548123c0e33f609f63b1" />
                </Route>
                <Route exact path="/properties/616">
                    <Redirect to="/property/6112542623c0e33f609f63ad" />
                </Route>
                <Route exact path="/properties/617">
                    <Redirect to="/property/611253cd23c0e33f609f63a9" />
                </Route>
                <Route exact path="/properties/618">
                    <Redirect to="/property/6112535823c0e33f609f63a5" />
                </Route>
                <Route exact path="/properties/619">
                    <Redirect to="/property/6112530823c0e33f609f63a1" />
                </Route>
                <Route exact path="/properties/620">
                    <Redirect to="/property/611252ad23c0e33f609f639d" />
                </Route>
                <Route exact path="/properties/621">
                    <Redirect to="/property/6112524b23c0e33f609f6399" />
                </Route>
                <Route exact path="/properties/622">
                    <Redirect to="/property/611251f223c0e33f609f6395" />
                </Route>
                <Route exact path="/properties/623">
                    <Redirect to="/property/6112518923c0e33f609f6391" />
                </Route>
                <Route exact path="/properties/624">
                    <Redirect to="/property/6112512b23c0e33f609f638d" />
                </Route>
                <Route exact path="/properties/625">
                    <Redirect to="/property/611250ba23c0e33f609f6389" />
                </Route>
                <Route exact path="/properties/626">
                    <Redirect to="/property/6112504d23c0e33f609f6385" />
                </Route>
                <Route exact path="/properties/627">
                    <Redirect to="/property/61124fed23c0e33f609f6381" />
                </Route>
                <Route exact path="/properties/628">
                    <Redirect to="/property/61124f7123c0e33f609f637d" />
                </Route>
                <Route exact path="/properties/629">
                    <Redirect to="/property/61124f0e23c0e33f609f6379" />
                </Route>
                <Route exact path="/properties/630">
                    <Redirect to="/property/61124a6123c0e33f609f6375" />
                </Route>
                <Route exact path="/properties/631">
                    <Redirect to="/property/611249e823c0e33f609f6371" />
                </Route>
                <Route exact path="/properties/632">
                    <Redirect to="/property/6112497a23c0e33f609f636d" />
                </Route>
                <Route exact path="/properties/633">
                    <Redirect to="/property/6112490b23c0e33f609f6369" />
                </Route>
                <Route exact path="/properties/634">
                    <Redirect to="/property/6112489623c0e33f609f6365" />
                </Route>
                <Route exact path="/properties/635">
                    <Redirect to="/property/6112482823c0e33f609f6361" />
                </Route>
                <Route exact path="/properties/636">
                    <Redirect to="/property/6112461a23c0e33f609f6358" />
                </Route>
                <Route exact path="/properties/637">
                    <Redirect to="/property/611245c423c0e33f609f6354" />
                </Route>
                <Route exact path="/properties/638">
                    <Redirect to="/property/6112455823c0e33f609f634b" />
                </Route>
                <Route exact path="/properties/639">
                    <Redirect to="/property/611244cd23c0e33f609f6347" />
                </Route>
                <Route exact path="/properties/640">
                    <Redirect to="/property/611243fd23c0e33f609f6343" />
                </Route>
                <Route exact path="/properties/641">
                    <Redirect to="/property/6112436223c0e33f609f633f" />
                </Route>
                <Route exact path="/properties/642">
                    <Redirect to="/property/6111e621fbdf5d571e02c4fa" />
                </Route>
                <Route exact path="/properties/643">
                    <Redirect to="/property/6111e5acfbdf5d571e02c4f6" />
                </Route>
                <Route exact path="/properties/644">
                    <Redirect to="/property/6111e53dfbdf5d571e02c4f2" />
                </Route>
                <Route exact path="/properties/645">
                    <Redirect to="/property/6111e4ddfbdf5d571e02c4ee" />
                </Route>
                <Route exact path="/properties/646">
                    <Redirect to="/property/6111e30dfbdf5d571e02c4ea" />
                </Route>
                <Route exact path="/properties/647">
                    <Redirect to="/property/6111e2aafbdf5d571e02c4e6" />
                </Route>
                <Route exact path="/properties/648">
                    <Redirect to="/property/6111dc90fbdf5d571e02c4dd" />
                </Route>
                <Route exact path="/properties/649">
                    <Redirect to="/property/6111dc27fbdf5d571e02c4d9" />
                </Route>
                <Route exact path="/properties/650">
                    <Redirect to="/property/6111dbc2fbdf5d571e02c4d5" />
                </Route>
                <Route exact path="/properties/651">
                    <Redirect to="/property/6111db59fbdf5d571e02c4d1" />
                </Route>
                <Route exact path="/properties/652">
                    <Redirect to="/property/6111db03fbdf5d571e02c4cd" />
                </Route>
                <Route exact path="/properties/653">
                    <Redirect to="/property/6111da2bfbdf5d571e02c4c7" />
                </Route>
                <Route exact path="/properties/654">
                    <Redirect to="/property/6111d992fbdf5d571e02c4b7" />
                </Route>
                <Route exact path="/properties/655">
                    <Redirect to="/property/6111d91bfbdf5d571e02c4b3" />
                </Route>
                <Route exact path="/properties/656">
                    <Redirect to="/property/6111d8b2fbdf5d571e02c4af" />
                </Route>
                <Route exact path="/properties/657">
                    <Redirect to="/property/6111d84cfbdf5d571e02c4ab" />
                </Route>
                <Route exact path="/properties/658">
                    <Redirect to="/property/6111d7dcfbdf5d571e02c4a7" />
                </Route>
                <Route exact path="/properties/659">
                    <Redirect to="/property/610353a74bd3f37d08a18671" />
                </Route>
                <Route exact path="/properties/660">
                    <Redirect to="/property/6110adf3fbdf5d571e02c48c" />
                </Route>
                <Route exact path="/properties/661">
                    <Redirect to="/property/6110ad92fbdf5d571e02c488" />
                </Route>
                <Route exact path="/properties/662">
                    <Redirect to="/property/6110ad31fbdf5d571e02c484" />
                </Route>
                <Route exact path="/properties/663">
                    <Redirect to="/property/6110ac82fbdf5d571e02c479" />
                </Route>
                <Route exact path="/properties/664">
                    <Redirect to="/property/6110abf4fbdf5d571e02c475" />
                </Route>
                <Route exact path="/properties/665">
                    <Redirect to="/property/6110ab9dfbdf5d571e02c471" />
                </Route>
                <Route exact path="/properties/666">
                    <Redirect to="/property/6110a829fbdf5d571e02c461" />
                </Route>
                <Route exact path="/properties/667">
                    <Redirect to="/property/6110a7abfbdf5d571e02c45d" />
                </Route>
                <Route exact path="/properties/668">
                    <Redirect to="/property/6110a740fbdf5d571e02c459" />
                </Route>
                <Route exact path="/properties/669">
                    <Redirect to="/property/6110a6cdfbdf5d571e02c455" />
                </Route>
                <Route exact path="/properties/670">
                    <Redirect to="/property/6110a665fbdf5d571e02c451" />
                </Route>
                <Route exact path="/properties/671">
                    <Redirect to="/property/6110a5defbdf5d571e02c44d" />
                </Route>
                <Route exact path="/properties/672">
                    <Redirect to="/property/6110a342fbdf5d571e02c424" />
                </Route>
                <Route exact path="/properties/673">
                    <Redirect to="/property/6110a2e5fbdf5d571e02c420" />
                </Route>
                <Route exact path="/properties/674">
                    <Redirect to="/property/6110a272fbdf5d571e02c41c" />
                </Route>
                <Route exact path="/properties/675">
                    <Redirect to="/property/6110a1fdfbdf5d571e02c418" />
                </Route>
                <Route exact path="/properties/676">
                    <Redirect to="/property/6110a196fbdf5d571e02c414" />
                </Route>
                <Route exact path="/properties/677">
                    <Redirect to="/property/6110a11bfbdf5d571e02c410" />
                </Route>
                <Route exact path="/properties/678">
                    <Redirect to="/property/6110a09ffbdf5d571e02c40c" />
                </Route>
                <Route exact path="/properties/679">
                    <Redirect to="/property/6110a03afbdf5d571e02c408" />
                </Route>
                <Route exact path="/properties/680">
                    <Redirect to="/property/61109fbffbdf5d571e02c404" />
                </Route>
                <Route exact path="/properties/681">
                    <Redirect to="/property/61109f3bfbdf5d571e02c400" />
                </Route>
                <Route exact path="/properties/682">
                    <Redirect to="/property/61109ecdfbdf5d571e02c3fc" />
                </Route>
                <Route exact path="/properties/683">
                    <Redirect to="/property/61109e63fbdf5d571e02c3f8" />
                </Route>
                <Route exact path="/properties/684">
                    <Redirect to="/property/61109a51fbdf5d571e02c3c6" />
                </Route>
                <Route exact path="/properties/685">
                    <Redirect to="/property/611099defbdf5d571e02c3c2" />
                </Route>
                <Route exact path="/properties/686">
                    <Redirect to="/property/61109940fbdf5d571e02c3be" />
                </Route>
                <Route exact path="/properties/687">
                    <Redirect to="/property/611098d5fbdf5d571e02c3ba" />
                </Route>
                <Route exact path="/properties/688">
                    <Redirect to="/property/61109865fbdf5d571e02c3b6" />
                </Route>
                <Route exact path="/properties/689">
                    <Redirect to="/property/61109802fbdf5d571e02c3b2" />
                </Route>
                <Route exact path="/properties/690">
                    <Redirect to="/property/61108c27fbdf5d571e02c3a9" />
                </Route>
                <Route exact path="/properties/691">
                    <Redirect to="/property/61108bb4fbdf5d571e02c3a5" />
                </Route>
                <Route exact path="/properties/692">
                    <Redirect to="/property/61108b33fbdf5d571e02c3a1" />
                </Route>
                <Route exact path="/properties/693">
                    <Redirect to="/property/61108a8ffbdf5d571e02c39d" />
                </Route>
                <Route exact path="/properties/694">
                    <Redirect to="/property/61108a30fbdf5d571e02c399" />
                </Route>
                <Route exact path="/properties/695">
                    <Redirect to="/property/611089bbfbdf5d571e02c395" />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
