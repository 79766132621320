import axios from "axios";
import { useState, useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import ListProperty from "../../components/fragments/ListProperty";
import ListPost from "../../components/fragments/ListPost";
import NavBar from "../../components/fragments/NavBar";
import { Container } from "../../components/styles/Layout";
import Loader from "react-loader-spinner";
import { LoaderWrapper } from "../../components/styles/Loader";
import Pagination from "../../components/fragments/Pagination";
import { Button } from "../../components/styles/Button";
import PaginationDB from "../../components/fragments/PaginationDB";

import { Search } from "@material-ui/icons";
import ManageTeamMembers from "../../components/fragments/ManageTeamMembers";

const Nav = styled.nav`
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem;
`;

const Tab = styled.div`
    font-size: 2rem;
    /* color: ${(props) => props.theme.darkblue}; */
    font-weight: ${(props) => (props.selected ? "400" : "100")};
    text-decoration: ${(props) => (props.selected ? "underline" : "none")};
    cursor: pointer;
    min-width: 12rem;
`;
const Seperator = styled.div`
    &::before {
        content: "|";
        font-size: 2rem;
        padding: 2rem;
        font-weight: 100;
    }
`;

const SearchForm = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 3rem 0;
`;
const SearchBox = styled.input`
    height: 2rem;
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid grey;
    min-width: 17rem;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    margin-left: 1.5rem;
    &:active {
        outline: none;
    }
    &:focus {
        outline: none;
    }
`;

const SearchButton = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        transform: scale(1.1);
        transition: 0.3s;
    }
`;

const NoPropertiesText = styled.p`
    text-align: center;
    margin-bottom: -1rem;
    padding: 4rem 0 7rem 0;
`;

export default function Dashboard() {
    const [properties, setProperties] = useState([]);
    const [posts, setPosts] = useState([]);
    const [isPosts, setIsPostsLoading] = useState(false);
    const [propertiesLoading, setPropertiesLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState("Properties");
    const [tabClickCount, setTabClickCount] = useState(0); // Fetch blog articles only after the first click

    const [searchText, setSearchText] = useState("");

    // DB pagination states for properties
    const [numberOfPropertyPages, setNumberOfPropertyPages] = useState(0);
    const [propertiesPageNumber, setPropertiesPageNumber] = useState(0);
    const [showPropertyPagination, setShowPropertyPagination] = useState(true);

    const propertyPages = new Array(numberOfPropertyPages).fill(null).map((v, i) => i);

    // Front end pagination for blog articles
    const [currentPostPage, setCurrentPostPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);

    // Configure axios proxy
    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    const fetchProperties = async () => {
        setPropertiesLoading(true);
        await axiosInstance.get(`/properties?page=${propertiesPageNumber}`).then((res) => {
            setNumberOfPropertyPages(res.data.totalPages);
            setProperties(res.data.properties); // properties sorted by date at the backend
            setPropertiesLoading(false);
        });
    };

    useEffect(() => {
        fetchProperties();
    }, [propertiesPageNumber]);

    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, [currentPostPage]);

    // Pagination control for blog posts (frontend only)
    const indexOfLastPost = currentPostPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const postsToDisplay = posts.slice(indexOfFirstPost, indexOfLastPost);
    const paginatePosts = (pageNumber) => setCurrentPostPage(pageNumber);

    const fetchPosts = async () => {
        setIsPostsLoading(true);
        await axiosInstance.get("/posts").then((res) => {
            const results = res.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
            setPosts(results);
            setIsPostsLoading(false);
        });
    };

    const handleTabClick = (e) => {
        setSelectedTab(e.target.innerText);

        // Load articles only if requested
        setTabClickCount(tabClickCount + 1);
        if (tabClickCount < 1) {
            fetchPosts();
        }
    };

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        // If something is searched, return the results. Else, re-fetch all posts
        if (searchText.length > 0) {
            setPropertiesLoading(true);
            await axiosInstance.get(`/properties?address=${searchText}`).then((res) => {
                setProperties(res.data); // properties sorted by date at the backend
                setShowPropertyPagination(false);
                setPropertiesLoading(false);
            });
            setPropertiesPageNumber(0); // When empty field will be searched, reset the page number in advance to avoid showing previously selected page for a brief moment
        } else {
            fetchProperties();
            setShowPropertyPagination(true);
        }
    };

    return (
        <>
            <NavBar location="admin" pageTitle="Dashboard" />
            <Container style={{ marginBottom: "5rem" }}>
                <Nav>
                    <Tab
                        onClick={handleTabClick}
                        selected={selectedTab === "Properties"}
                        // style={{ textAlign: "right" }}
                    >
                        Properties
                    </Tab>
                    <Seperator />
                    <Tab
                        onClick={handleTabClick}
                        selected={selectedTab === "Blog Articles"}
                        // style={{ textAlign: "left" }}
                    >
                        Blog Articles
                    </Tab>
                    <Seperator />
                    <Tab
                        onClick={handleTabClick}
                        selected={selectedTab === "Team Members"}
                        // style={{ textAlign: "left" }}
                    >
                        Team Members
                    </Tab>
                </Nav>
                {isPosts ? (
                    <LoaderWrapper>
                        <Loader type="Puff" color="#A2DEF9" height={60} width={60} />
                    </LoaderWrapper>
                ) : (
                    <>
                        {selectedTab === "Properties" && (
                            <>
                                <SearchForm onSubmit={handleSearchSubmit}>
                                    <SearchBox
                                        type="text"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                    <SearchButton type="submit">
                                        <Search />
                                    </SearchButton>
                                </SearchForm>
                                {propertiesLoading ? (
                                    <LoaderWrapper>
                                        <Loader
                                            type="Puff"
                                            color="#A2DEF9"
                                            height={60}
                                            width={60}
                                        />
                                    </LoaderWrapper>
                                ) : (
                                    <>
                                        {properties.map((property) => (
                                            <ListProperty
                                                admin
                                                property={property}
                                                key={property._id}
                                            />
                                        ))}
                                        {showPropertyPagination ? ( // Not shown for search results
                                            <PaginationDB
                                                pages={propertyPages}
                                                currentPage={propertiesPageNumber}
                                                setPageNumber={(e) =>
                                                    setPropertiesPageNumber(e.target.value)
                                                }
                                            />
                                        ) : (
                                            <NoPropertiesText>
                                                End of search results
                                            </NoPropertiesText>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {selectedTab === "Blog Articles" && (
                            <>
                                {postsToDisplay.map((post) => (
                                    <ListPost admin post={post} key={post._id} admin={true} />
                                ))}
                                <Pagination
                                    itemsPerPage={postsPerPage}
                                    totalItems={posts.length}
                                    paginate={paginatePosts}
                                    currentPage={currentPostPage}
                                />
                            </>
                        )}
                        {selectedTab === "Team Members" && <ManageTeamMembers />}
                    </>
                )}
            </Container>
        </>
    );
}
