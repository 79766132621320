import { useLayoutEffect, useRef, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import styled from "styled-components";
import Footer from "../../components/fragments/Footer";
import NavBar from "../../components/fragments/NavBar";

import { size } from "../../components/styles/Breakpoints";
import { mapStyles } from "../../assets/other/mapStyles";
import { Container, Grid } from "../../components/styles/Layout";
import { Button } from "../../components/styles/Button";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import axios from "axios";
import Loader from "react-loader-spinner";

const PanelWrap = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 3rem;
    box-sizing: inherit;
    @media screen and (max-width: ${size.sm}px) {
        display: block;
    }
`;

const BigTitle = styled.h2`
    font-weight: 300;
    color: #14315b;
`;
const Text = styled.p`
    max-width: 40rem;
`;
const AddressPanel = styled.div`
    height: 100%;
`;
const MapPanel = styled.div`
    padding-top: 2rem;
    padding-left: 2rem;
    margin-bottom: 2rem;
`;
const ContactForm = styled.form`
    margin: 2rem 0;
`;
const InputWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
`;
const Input = styled.input`
    display: block;
    width: 100%;
    border-radius: 0;
    border: 1px solid #e7e7e7;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 0.5rem;
`;
const Textarea = styled.textarea`
    display: block;
    width: 100%;
    border-radius: 0;
    border: 1px solid #e7e7e7;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 0.5rem;
    min-height: 10rem;
    resize: vertical;
`;

const SendLoader = styled(Loader)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SendButton = styled(Button)`
    pointer-events: ${(props) => (props.sending ? "none" : "auto")};
    background: ${(props) => props.sent && props.theme.grey};
`;

// MAP Options & functions
const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
    scrollwheel: false,
};

const mapContainerStyle = {
    width: "100%",
    height: "30rem",
};

export default function Contact() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [buttonText, setButtonText] = useState("Send");
    const [isSending, setIsSending] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSending(true);
        let emailToMe = {
            fromEmail: "info@propert-ypeople.co.uk",
            toEmail: "info@property-people.co.uk",
            // fromEmail: "info@swcarpetcleaning.co.uk",
            // toEmail: "test@newwindowmarketing.com",
            subject: `${name} submitted a contact form at property-people.co.uk`,
            body: `<div style="font-size:18px; line-height:1.5;">
                <p>${name} submitted a contact form at property-people.co.uk</p>
                Name: ${name} <br/>
                Email: ${email} <br/>
                <p> ${message} </p>
                </div>
                <div style="font-size:14px; padding-top:12px">
                  <i>This form was sent using submitted a contact form at property-people.co.uk contact form.</i>
                </div>`,
        };

        axios.post(process.env.REACT_APP_EMAIL_SEND_API, emailToMe).then((res) => {
            setIsSending(false);
            setMessageSent(true);
            setButtonText("Message Sent");
        });
    };

    return (
        <>
            <NavBar location="public" pageTitle="Contact" />
            <Container>
                <PanelWrap>
                    <AddressPanel>
                        <BigTitle>Property People Ltd</BigTitle>
                        <Text>T: 020 8946 7171・Fax: 020 7183 9946</Text>
                        <Text>E: info@property-people.co.uk</Text>
                        <Text>204 Tooting High Street, London, SW17 0SG</Text>
                        <ContactForm onSubmit={handleSubmit}>
                            <InputWrap>
                                <Input
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <Input
                                    type="email"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </InputWrap>
                            <Textarea
                                placeholder="Message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            ></Textarea>
                            <SendButton
                                primary
                                sending={isSending || messageSent}
                                sent={messageSent}
                            >
                                {isSending ? (
                                    <SendLoader type="Puff" color="white" height={19} width={19} />
                                ) : (
                                    <>{buttonText}</>
                                )}
                            </SendButton>
                        </ContactForm>
                        <GoogleMap
                            center={{ lat: 51.4235821, lng: -0.1751521 }}
                            mapContainerStyle={mapContainerStyle}
                            zoom={12}
                            options={options}
                        >
                            <Marker
                                position={{
                                    lat: 51.4235821,
                                    lng: -0.1751521,
                                }}
                                icon={{
                                    url: "/images/pp-pin-letting.svg",
                                    scaledSize: new window.google.maps.Size(40, 40),
                                }}
                            ></Marker>
                        </GoogleMap>
                    </AddressPanel>
                    <MapPanel>
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="Property_People"
                            options={{ height: 1100 }}
                        />
                    </MapPanel>
                </PanelWrap>
            </Container>
            <Footer />
        </>
    );
}
