import { useLayoutEffect } from "react";
import styled from "styled-components";
import Footer from "../../components/fragments/Footer";
import NavBar from "../../components/fragments/NavBar";
import HeroBanner from "../../components/fragments/HeroBanner";

import { Container } from "../../components/styles/Layout";

const Wrapper = styled.section`
    margin-bottom: 4rem;
`;
const BigTitle = styled.h2``;
const TinyTitle = styled.h4``;
const Text = styled.p`
    max-width: 40rem;
`;

export default function AboutUs() {
    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar location="public" pageTitle="About Us" />
            <HeroBanner
                title="Local independent agents with local knowledge"
                subtitle="At Property People, our experienced team of agents have worked with us for years because we reward loyalty, hard work & commitment. These agents live in your local area and have extensive local knowledge. We are an independent estate agency and will put your interest first. We will work tirelessly to let or sell your property."
                background="/images/pp-outside-dark.jpg"
                showRating={false}
            />
            <Wrapper>
                <Container>
                    <BigTitle>Why Us?</BigTitle>
                    <TinyTitle>Fundamentals</TinyTitle>
                    <Text>
                        Local estate agent with local knowledge to ensure that you get the best local service
                        available.
                    </Text>
                    <Text>
                        Experienced team with all the skills necessary to ensure a smooth efficient service
                        whether letting or selling your property.
                    </Text>
                    <Text>
                        Value is always something we pride ourselves on. We always aim to give the best the
                        best value for money within the industry – extremely competitive rates and no hidden
                        charges!
                    </Text>
                    <Text>
                        Committed to you and your property from day one – our team is committed to
                        professional, friendly and helpful advice from start to finish.
                    </Text>
                    <TinyTitle>We React To Changes</TinyTitle>
                    <Text>
                        We at property People embrace change. We combine the generational and cultural changes
                        with the old fashioned know how and use them to your advantage. We don’t think years
                        of experience alone is enough, so we continuously retrain ourselves to keep up to date
                        with changes in the market.
                    </Text>
                    <TinyTitle>We Are Trustworthy</TinyTitle>
                    <Text>
                        Property People London Ltd are part of the Property Ombudsman Scheme in association
                        with the Office of Fair Trading and operate to higher standard than required by law.
                    </Text>
                    <Text>
                        We ALWAYS protect all deposits with the insured and government authorised body the
                        DPS.
                    </Text>
                    <TinyTitle>Competitive Transparent Fees</TinyTitle>
                    <Text>
                        Our fee structure is just as transparent as our service promise. With our offer of
                        competitive pricing and a committed team, you need not look elsewhere.
                    </Text>
                    <TinyTitle>Marketing Your Property</TinyTitle>
                    <Text>
                        We go far and wide. We spare no expense to ensure that your property is seen on all
                        the major publications online and on paper, the potential buyers or tenants are likely
                        to visit.
                    </Text>
                    <Text>
                        With all these marketing tools at our disposal, there is no chance your potential
                        buyer or tenant has missed your property.
                    </Text>
                    <Text>
                        We regularly review the list of publications we use and add new ones frequently.
                    </Text>
                </Container>
            </Wrapper>
            <Footer />
        </>
    );
}
