import styled from "styled-components";
import { size } from "./Breakpoints";

export const CheckboxWrap = styled.div`
    position: absolute;
    margin: -2rem 0 0 0.5rem;
    box-shadow: -2px 3px 4px 1px #00000017;

    @media screen and (max-width: ${size.sm}px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        margin: 0;
        box-shadow: none;
        background: #f3f3f3;
    }
`;

export const CheckboxLabel = styled.label`
    background: ${(props) => props.lettings && props.theme.darkblue};
    background: ${(props) => props.sales && props.theme.lightblue};
    background: ${(props) => props.filterResults && props.theme.green};

    color: ${(props) => (props.sales ? props.theme.darkblue : "white")};

    padding: 0.25rem 2rem 0.25rem 2rem;
    width: 6rem;
    display: inline-flex;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media screen and (max-width: ${size.sm}px) {
        padding: 0.3rem 1rem 0.3rem 2rem;
        width: unset;
        font-size: 0.7rem;
    }
`;

export const CheckboxInput = styled.input`
    &:checked + ${CheckboxLabel}:before {
        content: "\x5c\x32\x35\x46\x45";
        position: absolute;
        margin: -0.3rem 0 0 -1.5rem;
        font-size: 1rem;
        color: transparent;
        text-shadow: 0 0 0 ${(props) => (props.filterResults ? props.theme.green : props.theme.darkblue)};
        z-index: 1;
        @media screen and (max-width: ${size.sm}px) {
            margin: -0.35rem 0 0 -1.45rem;
        }
    }
`;

export const CheckboxBox = styled.div`
    position: absolute;
    background: white;
    width: 1rem;
    height: 1rem;
    margin: -1px 0 0 -1.5rem;
    z-index: 0;
    @media screen and (max-width: ${size.sm}px) {
        margin: -1px 0 0 -1.45rem;
    }
`;
