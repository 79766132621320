import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import styled from "styled-components";
import { Context } from "../../context/Context";
import Controls from "./Controls";

const PostWrapper = styled.div`
    padding-bottom: 2rem;
    width: 85%;
    min-height: 6rem;
    border-bottom: 1px solid #e6e6e6;
    width: ${(props) => props.admin && "90%"};
`;
const Title = styled.h2`
    color: ${(props) => props.theme.darkblue};
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 0.6rem;
    transition: 0.3s;
    &:hover {
        color: ${(props) => props.theme.green};
        transition: 0.3s;
    }
`;
const Excerpt = styled.p`
    margin-top: 0;
`;

const Continue = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: ${(props) => props.theme.green};
`;

// Configure axios proxy
const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export default function ListPost({ post, published, admin }) {
    const { user } = useContext(Context);
    const [isPublished, setIsPublished] = useState(post.published);

    const handlePostPublish = async () => {
        try {
            await axiosInstance.put(`/posts/${post._id}`, {
                username: user.username,
                published: !isPublished,
            });
            setIsPublished(!isPublished);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {published && (
                <PostWrapper>
                    <Link to={`/blog/${post._id}`}>
                        <Title>{post.title}</Title>
                    </Link>
                    <Excerpt>{post.excerpt}</Excerpt>
                    <Link to={`/blog/${post._id}`}>
                        <Continue>Continue Reading...</Continue>
                    </Link>
                </PostWrapper>
            )}
            {admin && (
                <>
                    <Controls
                        handleVisibility={handlePostPublish}
                        isPublished={isPublished}
                        editLink={`/dashboard/edit-post/${post._id}`}
                        viewLink={`/blog/${post._id}`}
                    />
                    <PostWrapper admin>
                        <Title>{post.title}</Title>
                        <Excerpt>{post.excerpt}</Excerpt>
                    </PostWrapper>
                </>
            )}
        </>
    );
}
