import axios from "axios";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "./SwiperStyle.css";
import Loader from "react-loader-spinner";

// import Swiper core and required modules
import SwiperCore, { Autoplay } from "swiper/core";
import { Container } from "../styles/Layout";

// Icons and logos
import { ReactComponent as GoogleLogoSVG } from "../../assets/img/google-logo.svg";
import { ReactComponent as GoogleFiveStars } from "../../assets/img/google-5-stars.svg";
import { ReactComponent as GoogleFourStars } from "../../assets/img/google-4-stars.svg";
import { useEffect, useState } from "react";

SwiperCore.use([Autoplay]);

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 9fr;
    margin-bottom: 2rem;
    padding-top: 2rem;
    @media print {
        display: none;
    }
`;

const LeftPanel = styled.div`
    text-align: center;
`;

const ReviewSummary = styled.div`
    font-size: 1.5rem;
    color: ${(props) => props.theme.darkblue};
    font-weight: 500;
`;

const FiveStars = styled(GoogleFiveStars)`
    width: 8rem;
    padding-right: 2rem;
    width: 100%;
`;

const FourStars = styled(GoogleFourStars)`
    width: 6.7rem;
    padding-right: 2rem;
    width: 79%;
`;

const ReviewCount = styled.div`
    font-size: 0.825rem;
    color: ${(props) => props.theme.darkblue};
    padding-bottom: 0.5rem;
`;

const GoogleLogo = styled(GoogleLogoSVG)`
    width: 40%;
    height: auto;
`;

// Importatnt to set max width otherwise the slides will go all over the place
const RightPanel = styled.div`
    max-width: 980px;
`;

const ReviewWrap = styled.div`
    padding: 1rem;
    text-align: left;
    padding-top: 2rem;
`;

const StarsDateWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
    padding-bottom: 0.5rem;
    margin-top: -2rem;
`;

const Date = styled.div`
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    padding-top: 0.5rem;
    text-align: right;
`;

const Review = styled.p`
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0;
    color: ${(props) => props.theme.darkblue};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

const Author = styled.div`
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    padding-top: 0.5rem;
`;

export default function GoogleReviews() {
    const [reviews, setReviews] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get(process.env.REACT_APP_PROXY_API).then((res) => {
            setReviews(res.data.result);
            setIsLoading(false);
        });
    }, []);

    return isLoading ? (
        <Loader type="Puff" color="#A2DEF9" height={30} width={30} />
    ) : (
        <Container>
            <Wrapper>
                <LeftPanel>
                    <a href={reviews.url} target="_blank" rel="noreferrer">
                        <ReviewSummary>Excellent</ReviewSummary>
                        <FiveStars style={{ width: "80%", padding: "0.5rem" }} />
                        <ReviewCount>
                            {reviews.rating} based on {reviews.user_ratings_total} reviews
                        </ReviewCount>
                        <GoogleLogo />
                    </a>
                </LeftPanel>
                <RightPanel>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={15}
                        loop={true}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                    >
                        {reviews.reviews.map((review, index) => (
                            <div key={index + "A1"}>
                                {review.rating > 4 && (
                                    <SwiperSlide key={index + "A2"}>
                                        <ReviewWrap>
                                            <StarsDateWrap>
                                                {review.rating === 5 ? (
                                                    <FiveStars />
                                                ) : (
                                                    <FourStars />
                                                )}
                                                <Date>{review.relative_time_description}</Date>
                                            </StarsDateWrap>
                                            <Review>{review.text}</Review>
                                            <Author>{review.author_name}</Author>
                                        </ReviewWrap>
                                    </SwiperSlide>
                                )}
                            </div>
                        ))}

                        {/* Sometimes there are too many negative reviews, so we need to duplicate the positive ones one more time to fill the space. */}

                        {reviews.reviews.map((review, index) => (
                            <div key={index + "B"}>
                                {review.rating > 4 && (
                                    <SwiperSlide key={index + "B1"}>
                                        <ReviewWrap>
                                            <StarsDateWrap>
                                                {review.rating === 5 ? (
                                                    <FiveStars />
                                                ) : (
                                                    <FourStars />
                                                )}
                                                <Date>{review.relative_time_description}</Date>
                                            </StarsDateWrap>
                                            <Review>{review.text}</Review>
                                            <Author>{review.author_name}</Author>
                                        </ReviewWrap>
                                    </SwiperSlide>
                                )}
                            </div>
                        ))}
                    </Swiper>
                </RightPanel>
            </Wrapper>
        </Container>
    );
}
