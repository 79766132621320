import { useLayoutEffect } from "react";
import styled from "styled-components";

import NavBar from "../../components/fragments/NavBar";
import { Container } from "../../components/styles/Layout";
import CreatePostForm from "../../components/fragments/CreatePostForm";

const Title = styled.h1`
    text-align: center;
    margin: 2rem;
`;

export default function CreatePost() {
    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar location="admin" pageTitle="New Blog Article" />
            <Container>
                <Title>New Blog Article</Title>
                <CreatePostForm />
            </Container>
        </>
    );
}
