import styled from "styled-components";
import { size } from "./Breakpoints";

export const layoutWidth = 1370;
export const layoutPadding = 2;

export const Container = styled.div`
    max-width: ${layoutWidth}px;
    margin: 0 auto;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    padding: 0 ${layoutPadding}rem;
    @media screen and (max-width: ${size.sm}px) {
        padding: 0 ${layoutPadding - 1}rem;
    }
`;

export const Grid = styled.div`
    display: grid;
    ${(props) =>
        props.lg ? `grid-template-columns: repeat(${props.lg}, ${props.auto ? "auto" : "1fr"})` : null};
    ${(props) => (props.custom ? `grid-template-columns:${props.custom}` : null)};
    grid-column-gap: ${(props) => (props.gap ? props.gap : null)};
    grid-row-gap: ${(props) => (props.gap ? props.gap : null)};
    align-content: ${(props) => (props.center ? "center" : null)};
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
`;
