import { useLayoutEffect } from "react";
import styled from "styled-components";
import Footer from "../../components/fragments/Footer";
import NavBar from "../../components/fragments/NavBar";
import HeroBanner from "../../components/fragments/HeroBanner";
import Testimonial from "../../components/fragments/Testimonial";
import { size } from "../../components/styles/Breakpoints";

import { Container } from "../../components/styles/Layout";

const Wrapper = styled.section`
    margin-bottom: 4rem;
`;

const PanelWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    -webkit-box-sizing: inherit;
    @media screen and (max-width: ${size.sm}px) {
        display: block;
    }
`;

const LeftSide = styled.div``;
const RightSide = styled.div``;

export default function Testimonials() {
    useLayoutEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar location="public" pageTitle="Testimonials" />
            <HeroBanner title="Testimonials" background="/images/pp-outside-dark.jpg" showRating={false} />
            <Wrapper>
                <Container>
                    <PanelWrapper>
                        <LeftSide>
                            <Testimonial
                                author="Stephen Rawlings Southfields, SW18"
                                body="Property People are incredibly efficient and kept us updated every step of the way from the search for tenants, promotion and viewings through to administration, help with deposits and handover. Head and shoulders above any other local letting agents and better in my opinion than other companies that I've dealt with anywhere across the UK."
                            />
                            <Testimonial
                                author="Amy Foy Southfields, SW18"
                                body="I can highly recommend Property People. They provided me with an excellent service from start to finish and were efficient and professional at all times. Their approach is very personable and reliable, I would have no hesitation in using them again in the future."
                            />
                            <Testimonial
                                author="Marie Nuckchady Southfields, SW18"
                                body="I have had the pleasure of coming across Property People via a leaflet drop,and I am glad to say that I did not bin it like I do with so many leaflets that come through the door. I used the services of this well run company and am very satisfied with the service. The owner as well as the staff go beyond the duty of estate agents, nothing is too much trouble. It is an efficient business, so much so that I have for the first time agreed to an agent 'managing' my property, which I did not feel confident to in the past. I would highly recommend Property People to anyone considering using an agent for their letting business."
                            />
                            <Testimonial
                                author="Nick Jackson Putney, SW15"
                                body="I am a private landlord with two properties in Putney that I have rented through Property People since [2008]. I can highly recommend them. Their service is excellent. They have consistently found me quality tenants at good rentals, they are extremely honest, proactive and helpful across a wide range of services."
                            />
                            <Testimonial
                                author="Henrietta Mackenzie Putney, SW15"
                                body="Property People are my preferred estate agents in London. The team is extremely responsive and professional and I can always rely on them to find me very high quality tenants.

                                "
                            />
                            <Testimonial
                                author="Steve Fowowe Wimbledon Park, SW19"
                                body="This is to testify that Property People provides an A1 service when it comes to the management of property. I am saying this because of my experience with them. I have had property people manage my property for a while without me regretting handing the property to property people. A responsible tenant that I am pleased with was found and let the property to."
                            />
                            <Testimonial
                                author="Sarah Geer Clapham Junction, SW11"
                                body="We decided to market our flat with Property People as they seemed to be much better value than the other bigger agents, but they would still give us coverage of the property websites that people search when looking for a rental property. We were very happy with our decision. Asad at Property People was professional and dedicated, the advert and photos looked good, and most importantly, Property People found us good tenants within a week of starting viewings at the asking price! We would use them again without hesitation."
                            />
                            <Testimonial
                                author="Iain Stirling Southfields, SW18"
                                body="We found Property People London Ltd and especially Natalie very efficient, friendly and extremely professional. Tenants were found for our property within 3 days of it being advertised which was fantastic. I would not hesitate to use Natalie and the team at Property People again for future tenants. Highly recommended!"
                            />
                            <Testimonial
                                author="Harry Brown Earlsfield, SW18"
                                body="I have used Property People first time after trying to let my property with another well advertised agent who failed to live up to their claims. I confirm that I have found Property People staff very professional, helpful and caring. Although a new firm of letting agents they have provided astonishingly surprising letting service. I recommend the above firm to anyone who is looking for letting agents who know what they are doing very well. I would definitely use them again if and when needed."
                            />
                            <Testimonial
                                author="Toby Jenkins Putney, SW15"
                                body="It was a relief to find property people after experiencing the shocking customer service of all other estate agents. they always return calls on time and get things done. I feel confident to leave my property in the hands of Property people."
                            />
                            <Testimonial
                                author="Murray Beckett. Southfields, SW19"
                                body="
                                I've been delighted with the service provided by Natalie and the team at Property People. They do all the things that you'd expect from a top letting agent but at half the cost AND with better levels of communication. The best value by far."
                            />
                            <Testimonial
                                author="Mr & Mrs Cockerill Southfields, SW19"
                                body="Entering into the rental market can be a daunting task but the Property People certainly put your mind at rest and make the process as easy as possible. No ask is too big or small for the team. Combined with its local market knowledge and competitive rates we would certainly recommend the team."
                            />
                            <Testimonial
                                author="Jong-uk Yoon Southfields, SW19"
                                body="Kind  People, Swift Reply, Excellent Service, thats all we can think of Property People London."
                            />
                            <Testimonial
                                author="Laurence Chant"
                                body="I rent out a number of properties in London and have used Property People on a number of occasions and have found them flexible professional and friendly. I will be using the again with confidence in the future."
                            />
                        </LeftSide>
                        <RightSide>
                            <Testimonial
                                author="Esther Emmanuel Wandsworth, SW18 16 September 2013"
                                body="I am pleased to recommend Property People as very good estate agents as they (particularly Asad Jamil) have always been courteous, available and carried out all my instructions for the sale of my property. They were also very patient as the sale took a long time due to delays on both mine and the buyer's parts, yet they persevered to get the sale to completion. I would definitely recommend them so much so that I have asked them to value another 2 of my properties with view to sale."
                            />
                            <Testimonial
                                author="G.Brown Wandsworth Common, SW12"
                                body="I only use Property People for all my properties. They consistently offer a swift, effective service. Providing well screened, suitable tenants. Dedicated and guaranteed positive results. Flexible and competively priced, I only use Property People, there is no need to be ripped off, trampled on and dissapointed by the Big alpha male agents. Use Property People for honesty, transparency and professional integrity."
                            />
                            <Testimonial
                                author="Mr & Mrs L, Cheshire Southfields, SW18"
                                body="Problem-free letting with The Property People! We have just signed up with them for a third year. Have had continuous lets, happy tenants, no hassle and all at a very competitive rate."
                            />
                            <Testimonial
                                author="A. Gillard Penwith Road, SW18"
                                body="Natalie has done a superb job managing my flat since I moved abroad. Provides quick, efficient communication, and has a number of reliable tradesmen who seem able to fix most problems at very short notice, and at a reasonable price."
                            />
                            <Testimonial
                                author="Rebecca Wallace"
                                body="We have received a consistently excellent service from Natalie at Property People. Despite us living in Australia and some previously difficult tenants in our flat, she always dealt with both us and the tenants with the utmost professionalism and patience. We have been working with Natalie for over 18 months now and I very much like her personable approach and the all round support provided by her team. I would highly recommend Property People as property management agents based on the service we receive."
                            />
                            <Testimonial
                                author="Samuel Ntekim Fulham, SW6"
                                body="My daughter Maniza was your first client and she sang your praises so much that I began to use your services at Property People. Over the few years I have used your services, I have noticed your professionalism, reliability and honesty. I now sing your praises louder than Maniza. Thank you for your excellent services and I intend to use your company always."
                            />
                            <Testimonial
                                author="Steve Rowe-Davies Southfields, SW18"
                                body="I Live in South Africa and got in touch with Property People over the phone instructing them to let my property in Southfields. They took the instruction and found tenants within a week. It’s great to be able to rely on a local agent that can provide great service for good value for money without hidden fees. Thank-you to Asad and the rest of his team for all their help…..they made what can be a stressful situation into a pleasure!!"
                            />
                            <Testimonial
                                author="Mr G Whall Wimbledon Park, SW19"
                                body="I am a local landlord in SW19 and have used the services of The Property People for a few years now and have been very happy with their fast efficient service. I would recommend them to other landlords and prospective tenants alike."
                            />
                            <Testimonial
                                author="Sarah Hirigoyen. Parsons Green, SW6"
                                body="Property People have been a pleasure to deal with. I have found them to be extremely polite, helpful and nice. I have as a result become one of their most faithful clients, having been with them well over 3 years. I would unreservedly recommend them to anyone wishing to have a good relationship with professional estate agents who manage to have integrity and good values."
                            />
                            <Testimonial
                                author="E & G. MacDonald Putney. SW15"
                                body="We are very happy with the service you and Property people have handled letting our house and the support with tradesmen you have provided. We have already recommended you to friends who are looking for a managing agent. Thank you."
                            />
                            <Testimonial
                                author="Ralph Lewars Roehampton Vale, SW15"
                                body="I was very impressed at how quickly Property People found a tenant for me so soon after I had commissioned them. Even more impressive was their willingness, understanding and ability to offer me the kind of bespoke service I actually required as opposed to being rigid like many of the bigger agents. Their rates are reasonable and competitive and they aren't going to sneakily charge a renewal fee next year! The funny thing is that my tenant moved in today and yet I still haven't heard back from some of the other bigger agents who at the time talked the talk, signed me up and took keys before I even engaged Property People! I will definitely use Property People again and recommend them to my friends and colleagues."
                            />
                            <Testimonial
                                author="Anne Jullienne Earls Court, SW10"
                                body="I have been using Asad of Property People since I have left the UK to rent my flat in SW10 and I have not had one complaint to make. Asad has always been helpful and professional and prompt in reacting to queries. I believe the fees charged are good value for the standard of services provided."
                            />
                            <Testimonial
                                author="George Kew Southfields, SW18"
                                body="Property People have provided me with excellent service from start to finish over the last 2 years and all at a very good rate. They are extremely efficient and professional, from collecting my property details right through to moving new tenants in. Having used a number of agents prior to Property People I particularly liked their personal approach and their genuine knowledge of the surrounding areas. I would highly recommend Property People whether you’re a landlord looking for tenants or tenants looking for a property!"
                            />
                        </RightSide>
                    </PanelWrapper>
                </Container>
            </Wrapper>
            <Footer />
        </>
    );
}
