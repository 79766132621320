import styled from "styled-components";
import ProgressiveImage from "react-progressive-image";

import { HighlightOffRounded, Search } from "@material-ui/icons";
import Modal from "react-modal";
import { useState } from "react";
import { MiniButton } from "../styles/Button";

const Img = styled.img`
  height: 100%;
`;

const ImgWrap = styled.div`
  height: 100%;
  text-align: center;
`;

const ModalContent = styled.div`
  width: 90vw;
  height: 80vh;
`;

const CloseBtn = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    z-index: 10;
    background: white;
    border-radius: 50%;
    text-align: center;
    transition: 0.3s;
    border: 1px solid white};
    & svg {
        fill: ${(props) => props.theme.lightblue};
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    &:hover {
        border: 1px solid ${(props) => props.theme.lightblue};
        transition: 0.3s;
    }
`;

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "10",
    border: "2px solid #a2def9",
    padding: "2rem",
  },
};

export default function FloorplanModal({ img }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // MODAL & Contact form options & functions
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  Modal.setAppElement("#root"); // Need to set this for screen readers to avoid errors in development

  return (
    <>
      <MiniButton onClick={openModal}>
        <Search />
        View Floorplan
      </MiniButton>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        closeTimeoutMS={300}
      >
        <ModalContent>
          <CloseBtn onClick={closeModal}>
            <HighlightOffRounded />
          </CloseBtn>
          <ImgWrap>
            <ProgressiveImage src={img} placeholder={"/images/loading-img-placeholder.jpg"}>
              {(src, loading) => (
                <Img
                  src={src}
                  style={{
                    filter: loading ? "blur(4px)" : "blur(0)",
                    transition: ".3s",
                  }}
                />
              )}
            </ProgressiveImage>
          </ImgWrap>
        </ModalContent>
      </Modal>
    </>
  );
}
