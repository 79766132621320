import styled from "styled-components";
import { size } from "../styles/Breakpoints";

const CardWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.lightblue};
  @media screen and (max-width: ${size.sm}px) {
    margin-bottom: 2rem;
  }
`;

const TopSection = styled.div`
  display: flex;
  height: 11.25rem;
`;

const Left = styled.div`
  display: flex;
  flex: 1.2;
`;

const Right = styled.div`
  flex: 2;
`;

const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;
const Name = styled.div`
  padding: 0.5rem 1rem 0 1rem;
  font-weight: 400;
`;
const Position = styled.div`
  padding: 0.5rem 1rem;
`;
const Quote = styled.div`
  background: #e8f8ff;
  padding: 1rem;
  font-size: 0.8rem;
  font-style: italic;
`;

const QuoteDesc = styled.div`
  font-size: 0.6rem;
  color: ${(props) => props.theme.darkblue};
  font-style: normal;
`;

const BottomSection = styled.div``;
const Info = styled.p`
  font-size: 0.8rem;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
`;

export default function TeamMemberCard({ name, position, image, info, quote }) {
  return (
    <CardWrapper>
      <TopSection>
        <Left>
          <Img src={image} alt={name} />
        </Left>
        <Right>
          <Name>{name}</Name>
          <Position>{position}</Position>
          <Quote>
            <QuoteDesc>Favourite quote</QuoteDesc>"{quote}"
          </Quote>
        </Right>
      </TopSection>
      <BottomSection>
        <Info>{info}</Info>
      </BottomSection>
    </CardWrapper>
  );
}
